import { GET_HEADER_INFO, SET_AGENTLIST, SET_JOIN_AGENT_LIST, SET_POINT_TRANSACTION_MOVE, SET_POINT_TRANSHISTORY, SET_SEARCHAGENTLIST } from "../action/types";
  
  const initialState = {
    agentList: [],
    agentManageList: [],
    pointTransList:[],
    joinRequestList:[],
    headerInfoList:[],
    getMovePointTran: {}
  };
  
  export default function agent(state = initialState, action) {
    switch (action.type) {
        case SET_AGENTLIST: {
            return {
                ...state,
                agentList: action.payload
              };
        }
        case SET_SEARCHAGENTLIST: {
          return {
              ...state,
              agentList: action.payload
            };
      }
      case SET_POINT_TRANSHISTORY: {
        return {
            ...state,
            pointTransList: action.payload
          };
      }
      case SET_JOIN_AGENT_LIST: {
        return {
            ...state,
            joinRequestList: action.payload
          };
      }
      case GET_HEADER_INFO: {
        return {
            ...state,
            headerInfoList: action.payload
          };
      }
      case SET_POINT_TRANSACTION_MOVE: {
        return {
            ...state,
            getMovePointTran: action.payload
          };
      }      
        default:
            return state;
    }
  }
  