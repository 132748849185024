import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getAgentLevel, getAgentLevelColClass, getStatus, getStatusClassName, toastr, checkLoginId } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import { changeAgentAction, checkEnterpriseAction, CreateDownAction, deleteAgentAction, getAgentListAction } from "../../../redux/action/agentAction";
import Spinner from "../loadingBar/loadingBar";

const header = [
    {
        name: "아이디",
        className: "el-table_25_column_174",
        property: "loginid"
    },
    {
        name: "상위업체",
        className: "el-table_25_column_175",
        property: "id"
    },
    {
        name: "닉네임",
        className: "el-table_25_column_176",
        property: "username"
    },
    {
        name: "업체등급",
        className: "el-table_25_column_177",
        property: "agent_type"
    },
    {
        name: "포인트",
        className: "el-table_25_column_180",
        property: "money"
    },
    {
        name: "계정상태",
        className: "el-table_25_column_178",
        property: "status"
    },
    {
        name: "API주소",
        className: "el-table_25_column_179",
        property: "api_endpoint"
    },
    {
        name: "가입일자",
        className: "el-table_25_column_182",
        property: "created_at"
    }
]
const AgentListDashboard = () => {   
    const list = useSelector((state) => state.user.userData);
    const agentData = useSelector((state) => state.agent.agentList);
    const dispatch = useDispatch();
    const { loginid } = list;
    const agentList = !isEmpty(agentData)? (!isEmpty(agentData.data)? agentData.data : []) : []
    const agentCount = !isEmpty(agentData)? agentData.totalCount : 0

    const [deleteLoginId, setDeleteLoginId] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [ sortType, setSortType ] = useState(false);
    const [ colorChange, setColorChange] = useState(false);
    const onColorSet = () => {
        setColorChange(!colorChange)
    }
    const onSortType = () =>{
        setSortType(!sortType)
    }
    const onCreateModal = () => {
        format();
        setCreateModal(!createModal)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getAgentList(1, pageInfo.perPage)
        }
      }

    const defaultSort = {
        id: 0,
        username : 0,
        loginid: 0,
        agent_type: 0,
        money: 0,
        status: 0,
        api_endpoint: 0,
        created_at: 0
    }
    const [sortClass, setSortClass] = useState({
        id: 0,
        username : 0,
        loginid: 0,
        agent_type: 0,
        money: 0,
        status: 0,
        api_endpoint: 0,
        created_at: 0
    })
    
    const [userData, setUserData] = useState({
        userId: "",
        parent_name: "",
        api_address:"",
        status:"",
        agent_type:"",
        loginid: "",
        username: ""
    })    
    
    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    
    const onClickDelete = (loginId) => {
        setDeleteLoginId(loginId);
        onDeleteModal();
    }
    const onDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }


    const onChangeModal = (agent) => {
        format();
        setUserData({
            userId: agent.loginid,
            parent_name: agent.parent_loginid,
            api_address: agent.api_endpoint,
            status: agent.status,
            agent_type: agent.agent_type,
            loginid: agent.loginid,
            username: agent.username
        })
        setChangeModal(!changeModal)
    }
    
    const [Input, setInput] = useState({
        search_name: "",
        search_id: "",
        loginpwd:"",
        loginpwdconfirm: "",
        Username:"",
        agent_type:"",
        login_Id: "",
        changePwd:"",
        changePwdConfirm:"",
        apiAddress: ""        
    })

    const format = () => {
        setInput({
            search_name: "",
            search_id: "",
            loginpwd:"",
            loginpwdconfirm: "",
            Username:"",
            agent_type:"",
            login_Id: "",
            changePwd:"",
            changePwdConfirm:""    
        })
      }
    
    const { search_name, search_id, agent_type, Username, loginpwd, loginpwdconfirm, login_Id, changePwd, changePwdConfirm, apiAddress } = Input
    
    const [curPage, setCurPage] = useState(1);
    const getAgentList = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType();
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            loginid: loginid,
            page: page,
            perPage: perPage,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                name: search_name,
                loginid: search_id
            }
        }
        dispatch(getAgentListAction(params, setPageInfo, setCurPage));
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }
    const onUserDataChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const   onDeleteAgent = (deleteType) => {
        
        const params = {
            loginid: deleteLoginId,
            deleteType: deleteType

        }
        deleteAgentAction(params, onDeleteModal)
        .then(() => {
            getAgentList(pageInfo.curPage, pageInfo.perPage);
        });
    }

    const onChangeAgent = (e) => { 
        e.preventDefault();  
        if (!(changePwd === changePwdConfirm)) {
            return toastr.warning("비번확인을 확인해주세요.")
          }     
        const params = {
            loginid: userData.loginid,
            api_address: userData.api_address,
            status: userData.status,
            agent_type: userData.agent_type,
            changePwd: changePwd,
            username: userData.username
        }
        changeAgentAction(params, setChangeModal)
        .then(() => {
            getAgentList(pageInfo.curPage, pageInfo.perPage);
        });
    }

    const onCreateDown = (e) => {
        e.preventDefault();
        if (isEmpty(login_Id)) {
          return toastr.warning("아이디를 입력해주세요.")
        }
        if ((login_Id.length)<4) {
            return toastr.warning("아이디를 확인해주세요.")
          }
        if(checkLoginId(login_Id)){
          return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
        }
        if (isEmpty(Username)) {
          return toastr.warning("이름을 입력해주세요.")
        }
        if (checkLoginId(Username)) {
            return toastr.warning("이름에 특수문자를 포함할수 없습니다.");
          }
        if (isEmpty(loginpwd)) {
          return toastr.warning("비번을 입력해주세요.")
        }
        if ((loginpwd.length)<4) {
            return toastr.warning("비번을 확인해주세요.")
          }
        if (!(loginpwd === loginpwdconfirm)) {
          return toastr.warning("비번확인을 확인해주세요.")
        }
        if (isEmpty(apiAddress)) {
            return toastr.warning("API주소를 입력해주세요.")
          }
        if (isEmpty(agent_type)) {
          return toastr.warning("등급을 선택해주세요.")
        }
        const params = {
          agent_data: {
            loginid: login_Id,
            username: Username,
            loginpwd: loginpwd,
            agent_type: agent_type,
            apiAddress: apiAddress
          },
          loginid: loginid
        }
        CreateDownAction(params)
        .then(()=>{
            getAgentList(1, pageInfo.perPage)
            onCreateModal()
        })
        
      }

      const onCheckEnterprise = (e) => {
        e.preventDefault();
        if(checkLoginId(login_Id)){
          return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
        }
        const params = {
          loginid: login_Id
        }
        dispatch(checkEnterpriseAction(params));
      }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getAgentList(1, pageInfo.perPage);
        }
    }, [loginid, pageInfo.perPage])

    return (        
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-07abdf0e="" data-v-e072c2c8="">
                <div data-v-07abdf0e="" id="content" className="app-content">
                    <div data-v-07abdf0e="" className = "main_app_content">
                        <ol data-v-07abdf0e="" className="breadcrumb float-xl-end">
                            
                            <li data-v-07abdf0e="" className="breadcrumb-item">업체 관리</li>
                            <li data-v-07abdf0e="" className="breadcrumb-item active">업체 목록</li>
                        </ol>
                        <h1 data-v-07abdf0e="" className="page-header mb-3">업체 목록</h1>
                        <div data-v-07abdf0e="" className="row">
                            <div data-v-07abdf0e="" className="col-12">
                                <div data-v-07abdf0e="" className="panel panel-inverse">
                                    <div data-v-07abdf0e="" className="panel-heading">
                                        <h4 data-v-07abdf0e="" className="panel-title">검색</h4>
                                    </div>
                                    <div data-v-07abdf0e="" className="panel-body">
                                        <form data-v-07abdf0e="" className="form-horizontal form-bordered">
                                            <div data-v-07abdf0e="" className="form-group row"><label data-v-07abdf0e=""
                                                className="col-md-4 col-form-label">이름</label>
                                                <div data-v-07abdf0e="" className="col-md-8">
                                                    <div data-v-07abdf0e="" className="form-floating mb-3 mb-md-0">
                                                        <input data-v-07abdf0e="" type="text" value={search_name} name="search_name" 
                                                                placeholder="이름" className="form-control fs-13px h-45px" 
                                                                onChange={onChange} onKeyDown={onEnter} />
                                                        <label data-v-07abdf0e="" htmlFor="nickname" className="d-flex align-items-center fs-13px">이름</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-07abdf0e="" className="form-group row"><label data-v-07abdf0e="" className="col-md-4 col-form-label">로그인 아이디</label>
                                                <div data-v-07abdf0e="" className="col-md-8">
                                                    <div data-v-07abdf0e="" className="form-floating mb-3 mb-md-0">
                                                        <input data-v-07abdf0e="" type="text" value={search_id} name="search_id" 
                                                                placeholder="로그인 아이디" className="form-control fs-13px h-45px" 
                                                                onChange={onChange} onKeyDown={onEnter}/>
                                                        <label data-v-07abdf0e="" htmlFor="loginid" className="d-flex align-items-center fs-13px">로그인 아이디</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-07abdf0e="" className="row">
                                            <label data-v-07abdf0e="" className="col-md-4 col-form-label"></label>
                                                <div data-v-07abdf0e="" className="col-md-12" style = {{display:"flex", justifyContent:"end"}}>
                                                    <div data-v-07abdf0e="" className="btn-group " style = {{width: "150px", marginRight:"15px", marginTop:"-10px"}}> 
                                                        <button data-v-07abdf0e="" type="button" 
                                                                className="btn btn-success btn-lg" 
                                                                onClick={() => getAgentList(1, pageInfo.perPage)}> 검색 </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-07abdf0e="" className="row">
                            <div data-v-07abdf0e="" className="col-12">
                                <div data-v-07abdf0e="" className="panel panel-inverse">
                                    <div data-v-07abdf0e="" className="panel-heading">
                                        <h4 data-v-07abdf0e="" className="panel-title">업체 목록 ( 총 {agentCount} 개 )</h4>
                                        <button type="button" className="btn btn-primary btn-lg" onClick={onCreateModal}> 
                                            <i data-v-12fb228d="" className="fa fa-user"></i> 하 부 생 성
                                        </button>
                                    </div>
                                    <div data-v-07abdf0e="" className="panel-body">
                                        <div data-v-07abdf0e="" className="vgt-wrap ">
                                            <div data-v-07abdf0e="" className="vgt-inner-wrap">
                                                <div data-v-07abdf0e="" className="vgt-responsive agentList">
                                                    <div data-v-07abdf0e=""
                                                        className="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
                                                        style={{ width: "100%" }}>
                                                        <div className="el-table__body-wrapper is-scrolling-none" style={{ overflow: "auto" }}>
                                                            <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{ minWidth: "1384px" }}>
                                                                <thead className="has-gutter">
                                                                    <tr className="">
                                                                        <th colSpan="1" rowSpan="1" className="el-table_25_column_173  is-center   is-leaf">
                                                                            <div className="cell">번호</div>
                                                                        </th>
                                                                        {
                                                                            header.map((item,index)=>{
                                                                                return(
                                                                                    <th colSpan="1" rowSpan="1" className={`${item.className}  is-center is-leaf is-sortabl ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                        <div className="cell" onClick={()=>getAgentList(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                            <span className="caret-wrapper">
                                                                                                <i className="sort-caret ascending"></i>
                                                                                                <i className="sort-caret descending"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                )
                                                                            })
                                                                        }                                                                        
                                                                        <th colSpan="1" rowSpan="1" className="el-table_25_column_183 is-center is-leaf">
                                                                            <div className="cell">설정</div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        agentList.map((agent, index) => {
                                                                            return (
                                                                                <tr className="el-table__row" key={index}>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_173 is-center ">
                                                                                        <div className="cell"><span data-v-07abdf0e="">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_174 is-center ">
                                                                                        <div className="cell">
                                                                                            <span data-v-07abdf0e="">{agent.loginid}</span>
                                                                                            {/* <span  data-v-07abdf0e="" className="text-muted">@mdsv3moon</span> */}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_175 is-center ">
                                                                                        <div className="cell">
                                                                                            <span data-v-07abdf0e="">{agent.parentName}</span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_176 is-center ">
                                                                                        <div className="cell">
                                                                                            <span data-v-07abdf0e="">{agent.username}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_176 is-center ">
                                                                                        <div className={`cell ${getAgentLevelColClass(agent.agent_type)} `}>
                                                                                            <span data-v-07abdf0e="">{getAgentLevel(agent.agent_type)}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_180 is-center ">
                                                                                        <div className="cell" style={{textAlign:"end", marginRight:"-10px"}}>
                                                                                            <span data-v-07abdf0e="" >{Number(agent.money).toLocaleString()}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_177 is-center ">
                                                                                        <div className="cell">
                                                                                            <span data-v-07abdf0e="" className={`badge ${getStatusClassName(agent.status)} status`}> {getStatus(agent.status)} </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_177 is-center ">
                                                                                        <div className="cell">
                                                                                            <span data-v-07abdf0e="" style={{ overflow: "hidden" }}>{agent.api_endpoint}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/* <td rowSpan="1" colSpan="1" className="el-table_25_column_181 is-center ">
                                                                                            <div className = "cell">
                                                                                                <span data-v-07abdf0e="" style={{ overflow: "hidden" }}>{agent.api_key}</span>
                                                                                            </div>
                                                                                        </td> */}
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_182 is-center descending">
                                                                                        <div className="cell"><span data-v-07abdf0e="">{dateFormation(agent.created_at, 3)}<br/>{dateFormation(agent.created_at, 10)}</span></div>
                                                                                    </td>
                                                                                    <td rowSpan="1" colSpan="1" className="el-table_25_column_183 is-center ">
                                                                                        <div className="cell" style={{width:"100%"}}>
                                                                                            <button data-v-07abdf0e="" data-v-5e3e41f0="" type="button" className="el-button el-button--primary el-button--mini" onClick={()=>onChangeModal(agent)}>
                                                                                                <span data-v-07abdf0e="">수정</span>
                                                                                            </button>
                                                                                            <button data-v-07abdf0e="" data-v-5e3e41f0="" type="button" className="el-button el-button--danger el-button--mini" onClick={() => onClickDelete(agent.loginid)}>
                                                                                                <span data-v-07abdf0e="">삭제</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                            <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                                <span className="el-table__empty-text" style={{display: isEmpty(agentList)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                            </div>

                                                        </div>
                                                        <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                        <div className="el-loading-mask" style={{ display: "none" }}>
                                                            <div className="el-loading-spinner"><svg viewBox="25 25 50 50"
                                                                className="circular">
                                                                <circle cx="50" cy="50" r="20" fill="none" className="path">
                                                                </circle>
                                                            </svg>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Pagenation
                                                    getList={getAgentList}
                                                    pageInfo={pageInfo}
                                                    setPageInfo={setPageInfo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div data-v-07abdf0e="" data-v-631daf08="" className="ins-progress"
                        style={{ opacity: "0", display: "none", position: "fixed", height: "3px" }}></div>
                    <div data-v-07abdf0e="" id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All
                    Rights Reserved. </div>
                </div>
            </div>
            {/* ----------------삭제확인----------------- */}
            <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: deleteModal ? "block" : "none", zIndex: "11111" }}>
            <div className = "modal-backgroud" onClick={onDeleteModal}></div>
                <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: deleteModal ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
                    <div className="el-dialog__header">
                        <span className="el-dialog__title">머니를 회수하시겠습니까?</span>
                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                            <i className="fa fa-close" style={{ color: "white" }} onClick={onDeleteModal}></i>
                        </button>
                    </div>
                    <div className="el-dialog__footer">
                        <div data-v-12fb228d="" className="dialog-footer">
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={()=>onDeleteAgent(true)}>
                                <span> 예 </span>
                            </button>
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={()=>onDeleteAgent(false)}>
                                <span> 아니 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------수정확인모달----------------- */}
            <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: changeModal ? "block" : "none", zIndex: "11111" }}> 
            <div className = "modal-backgroud" onClick={onChangeModal}></div>               
                <div role="dialog" aria-modal="true" aria-label="수정사항" className="el-dialog" style={{ display: changeModal ? "block" : "none", zIndex: "11111", maxWidth:"800px", marginTop:"15vh" }}>
                    
                    <div className="el-dialog__header" style={{justifyContent:"start"}}>
                        <span className="el-dialog__title" style={{marginLeft:"15px"}}>업체정보</span>
                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                            <i className="fa fa-close" style={{ color: "white" }} onClick={onChangeModal}></i>
                        </button>
                    </div>
                    <div>
                        <div className="pass-modal-form" >
                            <div>
                                <div>
                                    <div className="create-modal-input" >
                                        <div className="create-modal-text" >
                                            아이디
                                        </div>
                                        <div className="check-input" >
                                            <div className="modal-input-style">
                                                <input type="text" name="" placeholder="아이디" value = {userData.userId}
                                                        style={{fontSize:"revert"}} 
                                                        className="create-input" onChange={onUserDataChange} disabled/>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="create-modal-input" >
                                    <div className="create-modal-text">상위업체</div>
                                    <div className="modal-input-style"><input type="text" autoComplete="off" name="" style={{fontSize:"revert"}}  value = {userData.parent_name} className="create-input" onChange={onUserDataChange} disabled/></div>
                                </div>
                                <div>
                                    <div className="create-modal-input" >
                                        <div className="create-modal-text" >
                                            이름
                                        </div>
                                        <div className="check-input" >
                                            <div className="modal-input-style">
                                                <input type="text" name="username" placeholder="이름" value = {userData.username}
                                                        style={{fontSize:"revert"}} 
                                                        className="create-input" onChange={onUserDataChange} />
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", justifyContent:"center"}}>
                                        <div className="create-modal-input" style={{width:"47%"}}>
                                            <div className="create-modal-text">새 비밀번호</div>
                                            <div className="modal-input-style">
                                                <input type="password" autoComplete="off" name="changePwd" value={changePwd} onChange={onChange} style={{fontSize:"revert"}}   className="create-input" />
                                            </div>
                                        </div>
                                        <div className="create-modal-input" style={{width:"47%"}}>
                                            <div className="create-modal-text">새 비밀번호확인</div>
                                            <div className="modal-input-style">
                                                <input type="password" autoComplete="off" name="changePwdConfirm" value={changePwdConfirm} onChange={onChange} style={{fontSize:"revert"}}   className="create-input" />
                                            </div>
                                        </div>
                                </div>
                                
                                <div className="create-modal-input" >
                                    <div className="create-modal-text">API주소</div>
                                    <div className="modal-input-style"><input type="text" autoComplete="off" style={{fontSize:"revert"}} value={userData.api_address} name="api_address"  placeholder="API주소" className="create-input" onChange={onUserDataChange} /></div>
                                </div>
                                <div className="create-modal-input" >
                                    <div className="create-modal-text">계정상태</div>                                    
                                    <div className="modal-input-style">                                        
                                        <div className="vue-treeselect__input-container" style={{ height: "50px", width: "90%" }}>
                                            <select placeholder="선택하세요." value={userData.status} className="vue-treeselect__input" style={{ backgroundColor: "rgb(76 81 87)", width: "100%" }} onChange={onUserDataChange} name="status" >
                                                <option value="" className="d-none">선택하세요</option>
                                                <option value="1"> 정상 </option>
                                                <option value="0"> 대기 </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-modal-input" >
                                    <div className="create-modal-text">
                                        업체등급
                                    </div>
                                    <div className="modal-input-style">
                                        {/* <input type="text" name="agent_type" value={agent_type} placeholder="등급" className="create-input" onChange={onChange} /> */}
                                        <div className="vue-treeselect__input-container" style={{ height: "50px", width: "90%" }}>
                                            <select placeholder="선택하세요." value={userData.agent_type} className="vue-treeselect__input" style={{ backgroundColor: "rgb(76 81 87)", width: "100%" }} onChange={onUserDataChange} name="agent_type" >
                                                <option value="" className="d-none">선택하세요</option>
                                                <option value="2"> 오퍼레이터 </option>
                                                <option value="1"> 에이젠트 </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="el-dialog__footer">
                        <div data-v-12fb228d="" className="dialog-footer">
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick = {onChangeAgent} style={{padding: "15px 50px"}}>
                                <span> 확인 </span>
                            </button>
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick = {onChangeModal} style={{padding: "15px 50px"}}>
                                <span> 취소 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* -------------- 하부 생성 ------------------ */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: createModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onCreateModal}></div>
        <div role="dialog" aria-modal="true" aria-label="새로 생성" className="el-dialog" style={{ display: createModal ? "block" : "none", zIndex: "11111", maxWidth:"800px", marginTop:"15vh" }}>
          <div className="el-dialog__header" style={{justifyContent:"start"}}>
            <span className="el-dialog__title" style={{marginLeft:"15px"}}>새로 생성</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onCreateModal}></i>
            </button>
          </div>
          <div>
            <div className="pass-modal-form" >
              <div>
                <div>
                  <div className="create-modal-input" >
                    <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>아이디 ( 4 ~ 20자 영문/숫자 )</div>
                    <div className="check-input" style={{ position: "relative" }}>
                      <div className="modal-input-style">
                          <input type="text" name="login_Id" value={login_Id} placeholder="아이디" className="create-input" onChange={onChange} /></div>
                      <div style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "15px"
                      }}>
                        <button data-v-12fb228d="" type="button" className="el-button btn btn-primary el-button--primary" style={{ position: "absolute", top: "0px", padding: "9.5px 25px", right:"5%" }} onClick={onCheckEnterprise}>
                          <span> 중복확인 </span>
                        </button>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>이름 ( 1 ~ 50자 )</div>
                  <div className="modal-input-style"><input type="text" autoComplete="off" name="Username" value={Username} placeholder="이름" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>비밀번호 ( 4 ~ 20자 영문/숫자 )</div>
                  <div className="modal-input-style"><input type="password" autoComplete="off" name="loginpwd" value={loginpwd} placeholder="비밀번호" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>비밀번호확인 ( 4 ~ 20자 영문/숫자 )</div>
                  <div className="modal-input-style"><input type="password" name="loginpwdconfirm" value={loginpwdconfirm} placeholder="비밀번호확인" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                    <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>API주소</div>
                    <div className="modal-input-style"><input type="text" autoComplete="off" style={{fontSize:"revert"}} value={apiAddress} name="apiAddress"  placeholder="API주소" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red",marginRight:"5px" }}></i>등급</div>
                  <div className="modal-input-style">
                    {/* <input type="text" name="agent_type" value={agent_type} placeholder="등급" className="create-input" onChange={onChange} /> */}
                    <div className="vue-treeselect__input-container" style={{ height: "50px", width: "90%" }}>
                      <select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(76 81 87)", width: "100%" }} onChange={onChange} name="agent_type" value={agent_type}>
                        <option value="" className="d-none">선택하세요</option>
                        <option value="2"> 오퍼레이터 </option>
                        <option value="1"> 에이젠트 </option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onCreateDown} style={{padding: "15px 40px"}}>
                <span> 확인 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onCreateModal} style={{padding: "15px 40px"}}>
                <span> 취소 </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------- */}
        </section>
    );
};

export default AgentListDashboard;
