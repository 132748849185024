
import { SET_SMART_SOCKET_DATA, SET_SOCKET_DATA } from '../../redux/action/types';
import { api_url } from '../../utils/keys';
import setAuthToken from '../../utils/setAuthToken';
import store from "../../redux/store";
import { history } from '../../utils/history';
import { DoLogout } from '../../redux/action/userAction';
const io = window.io(api_url, { origins: "*", "transports": ["websocket"] });
export const socket = io;

export const initSocket = () => {
  // const dispatch = useDispatch();

  socket.on('connect', (err) => {  
    console.log("socket connection")
  })
  socket.on('currentInfo', (data) => { 
    
    store.dispatch({
      type: SET_SOCKET_DATA,
      payload: data   
    });    
  })

  socket.on('sendData', (data) => { 
    store.dispatch({
      type: SET_SMART_SOCKET_DATA,
      payload: data.data.data   
    }); 

  })
  
  socket.on('disconnect', (err) => {
    
    setTimeout(() => {
      localStorage.clear()
      history.navigate("/");
  }, 2000)
    console.log("err=========>:", err)

  })
  socket.on('error', (err) => {  

  })

}