import { api_url } from "../../utils/keys";
import setAuthToken from "../../utils/setAuthToken";
import axios from "axios";
import { history } from "../../utils/history";
import { SET_AUTH, SET_CURRENT_USER, SET_GET_AGENT, SET_LOADING } from "./types";
import { toastr } from "../../utils";



export const DoLogin = userData => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true
  })
  
    axios.post(api_url + "/api/auth/login", userData)
      .then(res => {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING,
            payload: false
          })
        },400)
        
        if (res.data.success) {
          localStorage.setItem("jwtToken", res.data.userData.token);
          setAuthToken(res.data.userData.token);          
          dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.userData 
          })
          toastr.success(res.data.message)
          history.navigate("/dashboard")
        } else {
          toastr.warning(res.data.message);
        }
      })
      .catch(err => {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING,
            payload: false
          })
        },400)
        setAuthToken(false);
        localStorage.removeItem("jwtToken");
        toastr.warning("잠시후 다시 시도해주세요.");
        console.log(err);
      });
};



export const GetUserData = () => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true
  })
    axios.post(api_url + "/api/auth/userdata")
      .then(res => {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING,
            payload: false
          })
        },300)
        if(res.data.success){
          dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.userData
          })
        }
        else{
          toastr.warning(res.data.warning)
        }
        // store.dispatch({
        //   type: SET_AUTH,
        //   payload: true
        // })
        // resolve(res.data);
      })
      .catch(err => {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING,
            payload: false
          })
        },300)
        setAuthToken(false);
        localStorage.removeItem("jwtToken");
        history.navigate("/")
      });
};


export const DoChangePassword = userData => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true
  })
  axios.put(api_url + "/api/auth/updatepassword", userData)
      .then(res => {
        dispatch({
          type: SET_LOADING,
          payload: false
        })
        // resolve(res.data);
        if(res.data.success){
          toastr.success(res.data.message);
        }
        else{
          toastr.warning(res.data.message);
        }
      })
      .catch(err => {
        dispatch({
          type: SET_LOADING,
          payload: false
        })
        console.log(err);
      });
};


//-------------------상위업체 얻는 함수---------------------//
export const getAgentAction = () => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true
  })
  axios.post(api_url + "/api/enterprise/treelist/agentlist")
      .then(res => {
        dispatch({
          type: SET_LOADING,
          payload: false
        })
          if (res.data.success) {
              dispatch({
                  type: SET_GET_AGENT,
                  payload: res.data.data
              });
          }
              
          else {
              toastr.warning(res.data.message)
          }
      }).catch((err) => {
        dispatch({
          type: SET_LOADING,
          payload: false
        })
          console.log("err", err)
      });
}

//----------------로그아웃 함수-------------//
export const DoLogout = (param) => dispatch => {
  return new Promise(resolve => {
    axios
      .post(api_url + "/api/auth/logout", param)
      .then(res => {
        localStorage.removeItem("jwtToken");
        setAuthToken("");
        dispatch({
          type: SET_AUTH,
          payload: false
        })
        resolve(res.data);
      })
      .catch(err => {
        setAuthToken(false);
        localStorage.removeItem("jwtToken");
        resolve(err);
        console.log(err);
      });
  });
};
