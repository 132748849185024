import React, { Fragment } from 'react';

function ApiGuideDashboard() {
    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="" id="content" className="app-content">
                        <iframe src="./ApiGuide.html" scrolling="" style={{ width: "100%", minHeight: "calc(100vh - 100px)" }}></iframe>
                    <div>
                    </div>
                </div>
            </section>            
        </Fragment>
    );
}

export default ApiGuideDashboard;