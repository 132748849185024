import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getPayStatus, getPayStatusClassName } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import { getPointTransAction, get_AgentListAction } from "../../../redux/action/agentAction";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';

const header = [
    {
        name: "트랜잭션아이디",
        className: "el-table_27_column_194",
        property: "id"
    },
    {
        name: "타입",
        className: "el-table_27_column_195",
        property: "type"
    },
    {
        name: "처리금액",
        className: "el-table_27_column_196",
        property: "amount"
    },
    {
        name: "이전금액",
        className: "el-table_27_column_197",
        property: "prev_money"
    },
    {
        name: "현재금액",
        className: "el-table_27_column_198",
        property: "money"
    },
    {
        name: "처리대상",
        className: "el-table_27_column_199",
        property: "p_id"
    },
    {
        name: "처리날짜",
        className: "el-table_27_column_200",
        property: "created_at"
    }
]


const searchType = [
    {
        name: "소속업체에 머니지급",
        value: 1
    },
    {
        name: "소속업체의 머니차감",
        value: 2
    },
    {
        name: "상위업체가 머니지급",
        value: 3
    },
    {
        name: "상위업체가 머니회수",
        value: 4
    },
    {
        name: "유저베팅",
        value: 5
    },
    {
        name: "유저당첨",
        value: 6
    },
    {
        name: "유저베팅취소",
        value: 7
    },
    {
        name: "업체삭제",
        value: 10
    },
    {
        name: "알생성",
        value: 11
    },
    {
        name: "알소멸",
        value: 12
    }
]
const PointTranHistoryDashboard = () => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const pointTransData = useSelector((state) => state.agent.pointTransList);
    const agentList = useSelector((state) => state.userManage.getAgentList);
    const getParamByHeader = useSelector((state)=> state.agent.getMovePointTran)
    const { loginid } = list;
    const pointTransList = !isEmpty(pointTransData)? pointTransData.data : []
    const pointCount = !isEmpty(pointTransData)? pointTransData.totalCount : 0

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getPointTrans(1, pageInfo.perPage)
        }
      }

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);
    const [findType, setFindType] = useState([]);
    
    const defaultSort = {
        id: 0,
        type : 0,
        amount: 0,
        prev_money: 0,
        money: 0,
        created_at: 0,
        p_id: 0
    }
    const [sortClass, setSortClass] = useState({
        id: 0,
        type : 0,
        amount: 0,
        prev_money: 0,
        money: 0,
        created_at: 0,
        p_id: 0
    })
    const onCheckType = (e) => {
        
        const { checked, value } = e.target;
        if (checked) {            
            setFindType([...findType, value]);
        }
        else {
            setFindType(findType.filter(id => id !== value));
        }
    }

    const [Input, setInput] = useState({
        startDate: "",
        endDate: "",
        agentId: ""        
    })

    const { startDate, endDate, agentId } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }

    const getPointTrans = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
         onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }
        if(!isEmpty(getParamByHeader)) {
            const params = {
                page: page,
                perPage: perPage,
                isSearch: true,
                searchKey:getParamByHeader
              }            
              dispatch(getPointTransAction(params, setPageInfo, setCurPage));
        } else {

            const params = {
                page: page,
                perPage: perPage,
                isSearch: true,
                sortKey: sortKey,
                sortType: sortType,
                searchKey: {
                    startDate: startDate,
                    endDate: endDate,
                    agentId: agentId,
                    checkType: findType
                }
            }
            dispatch(getPointTransAction(params, setPageInfo, setCurPage));
        }
    }

    const get_AgentList = () => {
        dispatch(get_AgentListAction());
    }
    useEffect(() => {
        if (!isEmpty(loginid)&& !isEmpty(getParamByHeader)) {
            const params = {
                page: pageInfo.totalPage,
                perPage: 10,
                isSearch: true,
                searchKey:getParamByHeader
              }
            dispatch(getPointTransAction(params, setPageInfo, setCurPage));            
            get_AgentList();
            
        } else if (!isEmpty(loginid)) {
            getPointTrans(1, pageInfo.perPage);
            get_AgentList()
        }
    }, [loginid, pageInfo.perPage])

    return (
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="" id="content" className="app-content">
                <div className = "main_app_content">
                    <ol className="breadcrumb float-xl-end">
                        
                        <li className="breadcrumb-item"><a>업체 관리</a></li>
                        <li className="breadcrumb-item active">포인트 트랜잭션 내역</li>
                    </ol>
                    <h1 className="page-header">포인트 트랜잭션 내역</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">검색</h4>
                                </div>
                                <div className="panel-body">
                                    <form className="form-horizontal form-bordered">
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">해당 업체</label>
                                            <div className="col-md-8">
                                                <div className="
                                                    vtreeselect-dark
                                                    vue-treeselect
                                                    vue-treeselect--single
                                                    vue-treeselect--searchable
                                                    vue-treeselect--has-value
                                                    vue-treeselect--open-below">
                                                    <div className="vue-treeselect__control">
                                                        <div className="vue-treeselect__input-container">
                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                name="agentId" value={agentId} onChange={onChange} 
                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                <option value="" className="d-none">선택하세요</option>
                                                                {
                                                                    agentList.map((item, index) => {
                                                                        return (
                                                                            <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row period">
                                            <label className="col-md-4 col-form-label">기간(시작 ~ 종료)</label>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="startDate" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" onChange={onChange} />
                                                        {
                                                            !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0 postInfo-container-item">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="endDate" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" onChange={onChange} />
                                                        {
                                                            !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)}  showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">타입</label>
                                            <div className="col-md-8">
                                                <div className="mb-3 mb-md-0">
                                                    {
                                                        searchType.map(( item, index )=>{
                                                            return(
                                                                <div className="form-check form-check-inline" key={index}>
                                                                    <input type="checkbox" value={item.value}  onChange={onCheckType} onKeyDown={onEnter} className="form-check-input" />
                                                                    <label  value={item.value}  onChange={onCheckType} className="form-check-label">{item.name}</label>
                                                                </div>
                                                            ) 
                                                                                                            
                                                        })
                                                    }                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ display: "flex", justifyContent: "end" }}>
                                                <div className="btn-group" style={{ width: "150px", marginRight:"15px" }}>
                                                    <button type="button" className="btn btn-success btn-lg" onClick={() => getPointTrans(1, pageInfo.perPage)}> 검색 </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">포인트 트랜잭션 내역 ( 총 {pointCount} 개 ) </h4>
                                </div>
                                <div className="panel-body">
                                    <div className="vgt-wrap">
                                        <div className="vgt-inner-wrap">
                                            <div className="vgt-fixed-header"></div>
                                            <div className="vgt-responsive agentList">
                                                <div className="el-table el-table--fit el-table--enable-row-hover" style={{ width: "100%" }}>
                                                    
                                                    <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                        <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "1307px" }}>

                                                            {/* <tr name="el-table_27_column_193" width="5%" />
                                                            <tr name="el-table_27_column_194" width="8%" />
                                                            <tr name="el-table_27_column_195" width="15%" />
                                                            <tr name="el-table_27_column_196" width="9%" />
                                                            <tr name="el-table_27_column_197" width="9%" />
                                                            <tr name="el-table_27_column_198" width="9%" />
                                                            <tr name="el-table_27_column_201" width="15%" />
                                                            <tr name="el-table_27_column_199" width="15%" />
                                                            <tr name="el-table_27_column_200" width="15%" /> */}
                                                            
                                                            <thead className="has-gutter">
                                                                <tr className="">
                                                                    <th colSpan="1" rowSpan="1" className="el-table_27_column_193  is-center   is-leaf" style={{width:"5%"}}>
                                                                        <div className="cell">번호</div>
                                                                    </th>
                                                                    {
                                                                        header.map((item, index) => {
                                                                            return (
                                                                                <th colSpan="1" rowSpan="1" className={`${item.className}  is-center   is-leaf is-sortabl ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                    <div className="cell" onClick={() => getPointTrans(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                        <span className="caret-wrapper">
                                                                                            <i className="sort-caret ascending"></i>
                                                                                            <i className="sort-caret descending"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            )
                                                                        })
                                                                    }
                                                                    <th colSpan="1" rowSpan="1" className="el-table_27_column_201  is-center   is-leaf">
                                                                        <div className="cell">메모</div>
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                pointTransList.map((item, index) => {
                                                                    return (
                                                                        <tr className="" key={index}>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_193  is-center   is-leaf">
                                                                                <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_194  is-center   is-leaf is-sortable">
                                                                                <div className="cell">{item.id}
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_195  is-center   is-leaf is-sortable">
                                                                                <div className={`cell ${getPayStatusClassName(item.type)}`} style={{ width: "90%", borderRadius: "5px" }}>{getPayStatus(item.type)}</div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_196  is-center   is-leaf is-sortable">
                                                                                <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.amount).toLocaleString()}
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_197  is-center   is-leaf is-sortable">
                                                                                <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.prev_money).toLocaleString()}
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_198  is-center   is-leaf is-sortable">
                                                                                <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.cur_money).toLocaleString()}
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_199  is-center   is-leaf is-sortable">
                                                                                <div className="cell">{item.p_name}( @ {item.p_id} )
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_200  is-center   is-leaf">
                                                                                <div className="cell"> {dateFormation(item.created_at, 3)} <br /> {dateFormation(item.created_at, 10)}</div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_27_column_201  is-center   is-leaf">
                                                                                <div className="cell" style = {{textAlign:"center"}}>{item.memo}</div>
                                                                            </td>

                                                                        </tr>
                                                                    )
                                                                }

                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                        <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                            <span className="el-table__empty-text" style={{display: isEmpty(pointTransList)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                        </div>
                                                    </div>

                                                    <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                </div>
                                            </div>
                                            <Pagenation
                                                getList={getPointTrans}
                                                pageInfo={pageInfo}
                                                setPageInfo={setPageInfo} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-v-1df2cd06="" id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </div>

        </section>
    );
};

export default PointTranHistoryDashboard;