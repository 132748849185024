import AgentTreeView from "../containers/agentManage/AgentTreeView";
import AgentList from "../containers/agentManage/AgentList";
import JoinConsentRequest from "../containers/agentManage/JoinConsentRequest";
import Dashboard from "../containers/dashboard/Dashboard";
import Setting from "../containers/setting/Setting";
import BettingLimit from "../containers/gameManage/BettingLimit";
import BonusCallHistory from "../containers/userManage/BonusCallHistory";
import DailyFigure from "../containers/figure/DailyFigure";
import DoingGame from "../containers/userManage/DoingGame";
import GamecomList from "../containers/gameManage/GamecomList";
import MonthlyFigure from "../containers/figure/MonthlyFigure";
import GameHistory from "../containers/userManage/GameHistory";
import GameList from "../containers/gameManage/GameList";
import MainApi from "../containers/apiGuide/MainApi";
import OddGame from "../containers/gameManage/OddGame";
import OddGamecompany from "../containers/gameManage/OddGamecompany";
import PointTranHistory from "../containers/agentManage/PointTranHistory";
import TransactionHistory from "../containers/userManage/TransactionHistory";
import UserList from "../containers/userManage/UserList";
import UserFigure from "../containers/figure/UsersFigure";
import ApiGuide from "../containers/apiGuide/MainApi";
import LoginHistory from "../containers/userManage/LoginHistory";

const routes = [    
    {
      value: '/dashboard',
      name: Dashboard,
    },
    {
      value: '/agentList',
      name: AgentList,
    },    
    {
      value: '/agentTreeView',
      name: AgentTreeView,
    },
    {
      value: '/joinConsentRequest',
      name: JoinConsentRequest,
    },
    {
      value: '/PointTranHistory',
      name: PointTranHistory,

    },
    {
      value: '/BonusCallHistory',
      name: BonusCallHistory,

    },
    {
      value: '/DoingGame',
      name: DoingGame,

    },
    {
      value: '/GameHistory',
      name: GameHistory,

    },
    {
      value: '/LoginHistory',
      name: LoginHistory,

    },
    {
      value: '/TransactionHistory',
      name: TransactionHistory
    },
    {
      value: '/UserList',
      name: UserList,

    },
    // {
    //   value: '/ApiError',
    //   name: ApiError,

    // },
    // {
    //   value: '/CallbackApi',
    //   name: CallbackApi
    // },
    {
      value: '/apiguide',
      name: ApiGuide
    },
    
    {
      value: '/DailyFigure',
      name: DailyFigure
    },
    {
      value: '/MonthlyFigure',
      name: MonthlyFigure
    },
    
    {
      value: '/UserFigure',
      name: UserFigure
    },

    {
      value: '/BettingLimit',
      name: BettingLimit
    },

    {
      value: '/GamecomList',
      name: GamecomList
    },

    {
      value: '/GameList',
      name: GameList
    },

    {
      value: '/OddGame',
      name: OddGame
    },
    {
        value: '/OddGamecompany',
        name: OddGamecompany,
    },
    {
        value: '/Setting',
        name: Setting,
    },
    // {
    //   value: '/CallbackApi1',
    //   name: CallbackApi1
    // },
    // {
    //   value: '/CallbackApi2',
    //   name: CallbackApi2
    // }

    
]

export default routes;