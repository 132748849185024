import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { checkLoginId, dateFormation, getAgentLevel, getAgentLevelClass, getAgentLevelColClass, getLocalStringNumber, getStatus, getStatusClassName, no_comma } from "../../../utils";
import isEmpty from "../../../validation/is-empty";
import { toastr } from "../../../utils";
import { CreateDownAction, changePswAction, checkEnterpriseAction, substractMoneyAction, payMoneyAction, deleteAgentAction, getHeaderInfoAction } from "../../../redux/action/agentAction";

const AgentTable = (props) => {

  const dispatch = useDispatch()
  const { data, loginId } = props;

  const [Input, setInput] = useState({
    loginid: "",
    Username: "",
    loginpwd: "",
    loginpwdconfirm: "",
    agent_type: "",
    newPassword: "",
    passwordConfirm: "",
    payMoney: "",
    apiAddress: ""
  })

  const format = () => {
    setInput({
      loginid: "",
      Username: "",
      loginpwd: "",
      loginpwdconfirm: "",
      agent_type: "",
      newPassword: "",
      passwordConfirm: "",
      payMoney: "",
      apiAddress: ""
    })
  }

  const [createModal, setCreateModal] = useState(false)
  const onCreateModal = () => {
    format();
    setCreateModal(!createModal)
  }

  const [changePswModal, setChangePswModal] = useState(false)
  const onChangePswModal = () => {
    format();
    setChangePswModal(!changePswModal)
  }

  const [pointModal, setPointModal] = useState(false)
  const onPointModal = () => {
    format();
    setPointModal(!pointModal)
  }

  const [alPointModal, setAlPointModal] = useState(false)
  const onAlPointModal = () => {
    format();
    setAlPointModal(!alPointModal)
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const onDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const [alPayModal, setAlApyModal] = useState(false)
  const onAlPayModal = () => {
    setAlApyModal(!alPayModal)
  }

  const [alOutModal, setAlOutModal] = useState(false)
  const onAlOutModal = () => {
    setAlOutModal(!alOutModal)
  }

  const { loginid, Username, loginpwd, loginpwdconfirm, agent_type, newPassword, passwordConfirm, payMoney, apiAddress } = Input
  

  const onChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...Input,
      [name]: no_comma(value) 
    })
  }

  const onCreateDown = (e) => {
    e.preventDefault();
    if (isEmpty(loginid)) {
          return toastr.warning("아이디를 입력해주세요.")
        }
        if ((loginid.length)<4) {
            return toastr.warning("아이디를 확인해주세요.")
          }
        if(checkLoginId(loginid)){
          return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
        }
        if (isEmpty(Username)) {
          return toastr.warning("이름을 입력해주세요.")
        }
        if (checkLoginId(Username)) {
            return toastr.warning("이름에 특수문자를 포함할수 없습니다.");
          }
        if (isEmpty(loginpwd)) {
          return toastr.warning("비번을 입력해주세요.")
        }
        if ((loginpwd.length)<4) {
            return toastr.warning("비번을 확인해주세요.")
          }
        if (!(loginpwd === loginpwdconfirm)) {
          return toastr.warning("비번확인을 확인해주세요.")
        }
        if (isEmpty(apiAddress)) {
            return toastr.warning("API주소를 입력해주세요.")
          }
        if (isEmpty(agent_type)) {
          return toastr.warning("등급을 선택해주세요.")
        }
    const params = {
      agent_data: {
        loginid: loginid,
        username: Username,
        loginpwd: loginpwd,
        agent_type: agent_type,
        apiAddress: apiAddress
      },
      loginid: data.loginid
    }
    CreateDownAction(params)
    .then(()=>{      
      onCreateModal()
    })
  }

  const onCheckEnterprise = (e) => {
    e.preventDefault();
    if (checkLoginId(loginid)) {
      return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
    }
    const params = {
      loginid: loginid
    }
    dispatch(checkEnterpriseAction(params));
  }

  const onChangePsw = (e) => {
    e.preventDefault();
    if (isEmpty(newPassword)) {
      return toastr.warning("새 비번을 입력해주세요.")
    }
    const params = {
      loginpwd: newPassword,
      loginid: data.loginid
    }
    dispatch(changePswAction(params, onChangePswModal));

  }

  const onPayMoney = (e) => {
    e.preventDefault();
    if( isEmpty(payMoney) || Number(payMoney) === 0 || isNaN(payMoney) ) {
      return toastr.warning("금액을 입력해주세요.")
    }
   
    const params = {
      loginid: data.loginid,
      amount: payMoney,
      mode: 1
    }
    dispatch(payMoneyAction(params, headerInfo));
    onPointModal()
  }

  const onSubstractMoney = (e) => {
    e.preventDefault();
    if( isEmpty(payMoney) || Number(payMoney) === 0 || isNaN(payMoney) ) {
      return toastr.warning("금액을 입력해주세요.")
    }
    
    const params = {
      loginid: data.loginid,
      amount: payMoney,
      mode: 2
    }
    dispatch(substractMoneyAction(params, headerInfo));
    onPointModal()
    
  }

  const onPayAlMoney = (e) => {
    e.preventDefault();
    if( isEmpty(payMoney) || Number(payMoney) === 0 || isNaN(payMoney) ) {
      return toastr.warning("금액을 입력해주세요.")
    }
   
    const params = {
      loginid: data.loginid,
      amount: payMoney,
      mode: 11
    }
    dispatch(payMoneyAction(params, headerInfo));
    onAlPointModal()
    onAlPayModal()
  }

  const onOutAlMoney = (e) => {
    e.preventDefault();
    if( isEmpty(payMoney) || Number(payMoney) === 0 || isNaN(payMoney) ) {
      return toastr.warning("금액을 입력해주세요.")
    }
    
    const params = {
      loginid: data.loginid,
      amount: payMoney,
      mode: 12
    }
    dispatch(substractMoneyAction(params, headerInfo));
    onAlPointModal()
    onAlOutModal()
  }
  const startDate = dateFormation(new Date(),11);
  const headerInfo = () => {
    const param = {
      loginid: data.loginid,
      startDate: startDate
    }
    dispatch(getHeaderInfoAction(param))
  }

  const onDeleteAgent = (deleteType) => {
    
    const params = {
      loginid: data.loginid,
      deleteType: deleteType
    }
    deleteAgentAction(params, onDeleteModal).then(() => {
    });
  }

  return (
    <div data-v-12fb228d="" id="agent_info_out" className="col-6">
      <div data-v-12fb228d="" id="agent_info" className="panel panel-inverse">
        <div data-v-12fb228d="" className="panel-heading">
          <h4 data-v-12fb228d="" className="panel-title">업체 상세정보</h4>
        </div>
        <div data-v-12fb228d="" className="panel-body">
          <section data-v-12fb228d="" className="ps-container ps ps--active-y ">
            <div data-v-12fb228d="" className="table-responsive" style={{ overflow: "hidden" }}>
              <table data-v-12fb228d="" className="table table-bordered mb-0 align-middle">
                <tbody data-v-12fb228d="">
                  <tr data-v-12fb228d="">
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>아이디</td>
                    <td data-v-12fb228d="" className="text-center"> {data.loginid} </td>
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>이름</td>
                    <td data-v-12fb228d="" className="text-center"> {data.username} </td>
                  </tr>
                  <tr data-v-12fb228d="">
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>등급</td>
                    <td data-v-12fb228d="" className="text-center">
                      <span data-v-12fb228d="" className={`badge status ${getAgentLevelColClass(data.agent_type)}`}> {getAgentLevel(data.agent_type)} </span>
                    </td>
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>상태</td>
                    <td data-v-12fb228d="" className="text-center">
                      <span data-v-12fb228d="" className={`fs-6 badge rounded-5 ${getStatusClassName(data.status)}`}>{getStatus(data.status)}</span>
                    </td>
                  </tr>
                  <tr data-v-12fb228d="">
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>포인트</td>
                    <td data-v-12fb228d="" className="text-end"> {Number(data.money).toLocaleString()} </td>
                    <td data-v-12fb228d="" style={{ textAlign: "center" }}>등록일</td>
                    <td data-v-12fb228d="" className="text-center"> {dateFormation(data.created_at, 3)}<br />{dateFormation(data.created_at, 10)} </td>
                  </tr>

                </tbody>
              </table>
            </div>
            {
               data.agent_type === 3 ?
                <div data-v-12fb228d="" className="btn-group w-100" style={{ overflow: "auto" }}>
                  <button data-v-12fb228d="" type="button" className="btn btn-primary btn-lg" onClick={onCreateModal}>
                    <i data-v-12fb228d="" className="fa fa-user"></i> 하부생성
                  </button>
                  <button data-v-12fb228d="" type="button" className="btn btn-green btn-lg" onClick={onAlPointModal}>
                      <i data-v-12fb228d="" className="fa fa-p"></i> 알생성 & 알소멸
                  </button>
                  <button data-v-12fb228d="" type="button" className="btn btn-indigo btn-lg" onClick={onChangePswModal}>
                    <i data-v-12fb228d="" className="fa fa-cog"></i> 비밀번호 변경
                  </button>
                </div>
                :data.loginid === loginId ?
                <div data-v-12fb228d="" className="btn-group w-100" style={{ overflow: "auto" }}>
                  <button data-v-12fb228d="" type="button" className="btn btn-primary btn-lg" onClick={onCreateModal}>
                    <i data-v-12fb228d="" className="fa fa-user"></i> 하부생성
                  </button>                  
                  <button data-v-12fb228d="" type="button" className="btn btn-indigo btn-lg" onClick={onChangePswModal}>
                    <i data-v-12fb228d="" className="fa fa-cog"></i> 비밀번호 변경
                  </button>
                </div>
                : data.agent_type === 2 ?
                  <div data-v-12fb228d="" className="btn-group w-100" style={{ overflow: "auto" }}>
                    <button data-v-12fb228d="" type="button" className="btn btn-primary btn-lg" onClick={onCreateModal}>
                      <i data-v-12fb228d="" className="fa fa-user"></i> 하부생성
                  </button>
                    <button data-v-12fb228d="" type="button" className="btn btn-indigo btn-lg" onClick={onChangePswModal}>
                      <i data-v-12fb228d="" className="fa fa-cog"></i> 비밀번호 변경
                  </button>
                  <button data-v-12fb228d="" type="button" className="btn btn-green btn-lg" onClick={onPointModal}>
                      <i data-v-12fb228d="" className="fa fa-p"></i> 포인트 지급 & 차감
                  </button>
                    <button data-v-12fb228d="" type="button" className="btn btn-danger btn-lg" onClick={onDeleteModal}>
                      <i data-v-12fb228d="" className="fa fa-archive"></i> 삭제
                  </button>
                  </div>
                  :
                  <div data-v-12fb228d="" className="btn-group w-100" style={{ overflow: "auto" }}>
                    {/* <button data-v-12fb228d="" type="button" className="btn btn-primary btn-lg" onClick={onCreateModal}>
                    <i data-v-12fb228d="" className="fa fa-user"></i> 하부생성
                  </button> */}
                    <button data-v-12fb228d="" type="button" className="btn btn-indigo btn-lg" onClick={onChangePswModal}>
                      <i data-v-12fb228d="" className="fa fa-cog"></i> 비밀번호 변경
                  </button>
                    <button data-v-12fb228d="" type="button" className="btn btn-green btn-lg" onClick={onPointModal}>
                      <i data-v-12fb228d="" className="fa fa-p"></i> 포인트 지급 & 차감
                  </button>
                    <button data-v-12fb228d="" type="button" className="btn btn-danger btn-lg" onClick={onDeleteModal}>
                      <i data-v-12fb228d="" className="fa fa-archive"></i> 삭제
                  </button>
                  </div>

            }

          </section>
        </div>
      </div>


      {/* -------------- 하부 생성 ------------------ */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: createModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onCreateModal}></div>
        <div role="dialog" aria-modal="true" aria-label="새로 생성" className="el-dialog" style={{display: createModal ? "block" : "none", zIndex: "11111", maxWidth: "800px", marginTop:"15vh" }}>
          <div className="el-dialog__header" style={{justifyContent:"start"}}>
            <span className="el-dialog__title" style={{marginLeft:"15px"}}>새로 생성</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onCreateModal}></i>
            </button>
          </div>
          <div>
            <div className="pass-modal-form" >
              <div>
                <div>
                  <div className="create-modal-input" >
                    <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>아이디 ( 4 ~ 20자 영문/숫자 )</div>
                    <div className="check-input" style={{ position: "relative" }}>
                      <div className="modal-input-style"><input type="text" name="loginid" value={loginid} placeholder="아이디" className="create-input" onChange={onChange} /></div>
                      <div style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "15px"
                      }}>
                        <button data-v-12fb228d="" type="button" className="el-button btn btn-primary el-button--primary" style={{ position: "absolute", top: "0px", right: "5%", padding: "9.5px 25px" }} onClick={onCheckEnterprise}>
                          <span> 중복확인 </span>
                        </button>
                      </div>
                    </div>

                  </div>

                </div>

                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>이름 ( 1 ~ 50자 )</div>
                  <div className="modal-input-style"><input type="text" autoComplete="off" name="Username" value={Username} placeholder="이름" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>비밀번호 ( 4 ~ 20자 영문/숫자 )</div>
                  <div className="modal-input-style"><input type="password" autoComplete="off" name="loginpwd" value={loginpwd} placeholder="비밀번호" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>비밀번호확인 ( 4 ~ 20자 영문/숫자 )</div>
                  <div className="modal-input-style"><input type="password" name="loginpwdconfirm" value={loginpwdconfirm} placeholder="비밀번호확인" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                    <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>API주소</div>
                    <div className="modal-input-style"><input type="text" autoComplete="off" style={{fontSize:"revert"}} value={apiAddress} name="apiAddress"  placeholder="API주소" className="create-input" onChange={onChange} /></div>
                </div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"><i className="fa fa-asterisk" style={{ fontSize: "xx-small", color: "red", marginRight:"5px" }}></i>등급</div>
                  <div className="modal-input-style">
                    {/* <input type="text" name="agent_type" value={agent_type} placeholder="등급" className="create-input" onChange={onChange} /> */}
                    <div className="vue-treeselect__input-container" style={{ height: "50px", width: "90%" }}>
                      <select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(76 81 87)", width: "100%" }} onChange={onChange} name="agent_type" value={agent_type}>
                        <option value="" className="d-none">선택하세요</option>
                        <option value="2"> 오퍼레이터 </option>
                        <option value="1"> 에이젠트 </option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onCreateDown} style={{ padding: "15px 40px" }}>
                <span> 확인 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onCreateModal} style={{ padding: "15px 40px" }}>
                <span> 취소 </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------- */}


      {/* ----------------비밀번호변경----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: changePswModal ? "block" : "none" }} >
      <div className = "modal-backgroud" onClick={onChangePswModal}></div>
        <div role="dialog" aria-modal="true" aria-label="비밀번호변경" className="el-dialog" style={{ display: changePswModal ? "block" : "none", marginTop: "15vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title">비밀번호변경</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onChangePswModal}></i>
            </button>
          </div>
          <div>
            <div className="pass-modal-form" >
              <div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"> 새 비밀번호 </div>
                  <div className="modal-input-style"><input type="password" name="newPassword" value={newPassword} placeholder="비밀번호" className="create-input" onChange={onChange} /></div>
                </div>
              </div>
              <div>
                <div className="create-modal-input" >
                  <div className="create-modal-text"> 새 비밀번호확인 </div>
                  <div className="modal-input-style"><input type="password" name="passwordConfirm" value={passwordConfirm} placeholder="비밀번호확인" className="create-input" onChange={onChange} /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onChangePsw}>
                <span> 확인 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onChangePswModal}>
                <span> 취소 </span>
              </button>
            </div>
          </div>
        </div>
      </div>



      {/* ----------------포인트 지급 및 차감----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: pointModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onPointModal}></div>
        <div role="dialog" aria-modal="true" aria-label="포인트지급 & 차감" className="el-dialog" style={{ display: pointModal ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title">포인트지급 & 차감</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onPointModal}></i>
            </button>
          </div>
          <div className="point-modal">
            <div className="col-point-modal">
              <div className="input-group form-floating mb-3 mb-md-0">
                <input type="texts"
                  id="api_url"
                  placeholder="포인트지급"
                  value={Number(payMoney) === 0|| isNaN(payMoney) ? "" : getLocalStringNumber(payMoney) }
                  name="payMoney"
                  onChange={onChange}
                  className="form-control fs-15px h-45px" style={{ backgroundColor: "#3c414b" }} />
                <label htmlFor="api_url" className="d-flex align-items-center fs-13px">금액을 입력해주세요</label>
              </div>
            </div>
          </div>
          <div className="point_modal_footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--success" style={{ width: "100px" }} onClick={onPayMoney}>
                <span> 지급 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" style={{ width: "100px" }} onClick={onSubstractMoney}>
                <span> 차감 </span>
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ----------------알생성 및 알소멸----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: alPointModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onAlPointModal}></div>
        <div role="dialog" aria-modal="true" aria-label="알생성 & 알소멸" className="el-dialog" style={{ display: alPointModal ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title"> 알생성 & 알소멸</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onAlPointModal}></i>
            </button>
          </div>
          <div className="point-modal">
            <div className="col-point-modal">
              <div className="input-group form-floating mb-3 mb-md-0">
                <input type="texts"
                  id="api_url"
                  placeholder="포인트지급"
                  value={Number(payMoney) === 0|| isNaN(payMoney) ? "" : getLocalStringNumber(payMoney) }
                  name="payMoney"
                  onChange={onChange}
                  className="form-control fs-15px h-45px" style={{ backgroundColor: "#3c414b" }} />
                <label htmlFor="api_url" className="d-flex align-items-center fs-13px">금액을 입력해주세요</label>
              </div>
            </div>
          </div>
          <div className="point_modal_footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--success" style={{ width: "100px" }} onClick={onAlPayModal}>
                <span> 알생성 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" style={{ width: "100px" }} onClick={onAlOutModal}>
                <span> 알소멸 </span>
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ----------------삭제확인----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: deleteModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onDeleteModal}></div>
        <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: deleteModal ? "block" : "none", zIndex: "11111", marginTop: "15vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title">머니를 회수하시겠습니까?</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onDeleteModal}></i>
            </button>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={()=>onDeleteAgent(true)}>
                <span> 예 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={()=>onDeleteAgent(false)}>
                <span> 아니 </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------알생성확인모달----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: alPayModal ? "block" : "none", zIndex: "11112" }}>
      <div className = "modal-backgroud" onClick={onAlPayModal}></div>
        <div role="dialog" aria-modal="true" aria-label="적용" className="el-dialog" style={{ display: alPayModal ? "block" : "none", zIndex: "11112", marginTop: "8vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title">적용하시겠습니까?</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onAlPayModal}></i>
            </button>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onPayAlMoney}>
                <span> 예 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onAlPayModal}>
                <span> 아니 </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------알소멸확인모달----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: alOutModal ? "block" : "none", zIndex: "11112" }}>
      <div className = "modal-backgroud" onClick={onAlOutModal}></div>
        <div role="dialog" aria-modal="true" aria-label="적용" className="el-dialog" style={{ display: alOutModal ? "block" : "none", zIndex: "11112", marginTop: "8vh" }}>
          <div className="el-dialog__header">
            <span className="el-dialog__title">적용하시겠습니까?</span>
            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
              <i className="fa fa-close" style={{ color: "white" }} onClick={onAlOutModal}></i>
            </button>
          </div>
          <div className="el-dialog__footer">
            <div data-v-12fb228d="" className="dialog-footer">
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onOutAlMoney}>
                <span> 예 </span>
              </button>
              <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onAlOutModal}>
                <span> 아니 </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AgentTable;
