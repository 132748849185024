import React, { Fragment } from 'react';

function BettingLimitDashboard() {
    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="" id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol className="breadcrumb float-xl-end">
                            
                            <li className="breadcrumb-item"><a>게임 관리</a></li>
                            <li className="breadcrumb-item active">베팅한도설정</li>
                        </ol>
                        <h1 className="page-header">베팅한도설정</h1>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">설정</h4>
                                    </div>
                                    <div role="alert" aria-live="polite" aria-atomic="true" className="alert rounded-0 alert-black mb-0">
                                        <div className="d-flex"><i className="fa fa-check fa-2x me-1"></i>
                                            <div className="mb-0 ps-2"> 최대베팅/당첨한도는 탑게임에만 적용됩니다. </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <form className="form-horizontal form-bordered">
                                            <div className="form-group row"><label className="col-md-4 col-form-label">최대 당첨한도</label>
                                                <div className="col-md-8">
                                                    <div className="form-floating mb-3 mb-md-0"><input type="text" placeholder="최대당첨한도" id="maxbet" className="form-control fs-13px h-45px" /><label htmlFor="maxbet" className="d-flex align-items-center fs-13px">최대 당첨한도</label></div>
                                                </div>
                                            </div>
                                            {/* <div className="form-group row"><label className="col-md-4 col-form-label">바카라 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                            vtreeselect-dark
                                                            vue-treeselect
                                                            vue-treeselect--single
                                                            vue-treeselect--searchable
                                                            vue-treeselect--has-value
                                                            vue-treeselect--open-below
                                                            ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">식보 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">백보 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">용호 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">룰렛 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">판탄 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">파워볼 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="1">Skin 1 : Medium (Default)  [ 1,000 ~ 5,000,000 ] </option><option value="2">Skin 2 : Extra Small  [ 1,000 ~ 1,000,000 ] </option><option value="3">Skin 3 : Very Small  [ 1,000 ~ 2,000,000 ] </option><option value="4">Skin 4 : Small  [ 1,000 ~ 3,000,000 ] </option><option value="5">Skin 5 : Large  [ 1,000 ~ 10,000,000 ] </option><option value="6">Skin 6 : Extreme Large  [ 1,000 ~ 30,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">블랙잭 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="7">Skin A : Medium (Default)  [ 1,000 ~ 10,000,000 ] </option><option value="8">Skin B : Small  [ 1,000 ~ 5,000,000 ] </option><option value="9">Skin C : Large  [ 1,000 ~ 25,000,000 ] </option><option value="10">Skin D : Extreme 1  [ 1,000 ~ 10,000,000 ] </option><option value="11">Skin E : Extreme 2  [ 1,000 ~ 10,000,000 ] </option><option value="12">Skin F : Extra Small  [ 1,000 ~ 3,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">기타 베팅한도</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container"><select placeholder="선택하세요." className="vue-treeselect__input" style={{ backgroundColor: "rgb(7, 19, 29)" }}><option value="7">Skin A : Medium (Default)  [ 1,000 ~ 10,000,000 ] </option><option value="8">Skin B : Small  [ 1,000 ~ 5,000,000 ] </option><option value="9">Skin C : Large  [ 1,000 ~ 25,000,000 ] </option><option value="10">Skin D : Extreme 1  [ 1,000 ~ 10,000,000 ] </option><option value="11">Skin E : Extreme 2  [ 1,000 ~ 10,000,000 ] </option><option value="12">Skin F : Extra Small  [ 1,000 ~ 3,000,000 ] </option></select></div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="btn-group w-100"><button type="button" className="btn btn-success btn-lg"> 설정 </button></div>
                                                </div>
                                            </div> */}
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </section>
        </Fragment>

    );
}

export default BettingLimitDashboard;