import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Login from "./components/auth/Login";
import store from "./redux/store";
import { history } from "./utils/history";
// import { GetUserData } from "./redux/reducers/user";
import { SET_AUTH } from "./redux/action/types";
import setAuthToken from "./utils/setAuthToken";
import isEmpty from "./validation/is-empty";
import jwt_decode from 'jwt-decode';
import routes from "./route";
import Protected from "./components/common/Protected";
import { GetUserData } from "./redux/action/userAction";
import Spinner from "./components/common/loadingBar/loadingBar";
import { initSocket } from './commons/cores/socket';



initSocket();
const App = () => {

  history.navigate = useNavigate();
  history.location = useLocation();

  if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    const currentTime = Date.now() / 1000;
    const decoded = jwt_decode(localStorage.jwtToken);
    store.dispatch({
      type: SET_AUTH,
      payload: !isEmpty(decoded)
    })
    store.dispatch(GetUserData());
    localStorage.setItem('exp', decoded.exp);
    localStorage.setItem('time', currentTime);

    // if (decoded.exp < currentTime) {
    //   store.dispatch(DoLogout());
    //   // window.location.href = '/';
    // }
  }

  return (
    <Fragment>
      <NotificationContainer />
      <Spinner/>
      <Routes>
        <Route path="/" element={<Login />} />
        {routes.map((item, index) => {
          return (
            <Route path={item.value} element={<Protected> <item.name /></Protected>} key={index} />
          )
        })
        }
      </Routes>

    </Fragment>
  );
};

export default App;
