import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getCheckStatus, getStatus, getStatusClassName } from "../../../utils";
import { getGameListAction } from "../../../redux/action/gameManageAction";
import Pagenation from "../../pagenation/pagenation";
import { get_gameListAction, changeAction } from "../../../redux/action/userManageAction";

const header = [
    {
        name: "게임사",
        className: "el-table_33_column_258",
        property: "vendor_key"
    },
    {
        name: "게임이름",
        className: "el-table_33_column_259",
        property: "game_name"
    },
    {
        name: "심볼",
        className: "el-table_33_column_260",
        property: "rule_type"
    },
    {
        name: "카테고리",
        className: "el-table_33_column_261",
        property: "game_code"
    },
    {
        name: "사용(ON/OFF)",
        className: "el-table_33_column_262",
        property: "status"
    },
    {
        name: "등록일",
        className: "el-table_33_column_264",
        property: "created_at"
    }
]

function GameListDashboard() {
    const gameList = useSelector((state) => state.gameManage.gameList);
    const { recordset, rowsAffected } = gameList   
    const list = useSelector((state) => state.user.userData);
    const getVendorList = useSelector((state) => state.userManage.getGameList);
    const dispatch = useDispatch();
    const { loginid } = list;

    const defaultSort = {
        vendor_key: 0,
        game_name : 0,
        rule_type: 0,
        game_code: 0,
        status: 0,
        created_at: 0
    }
    const [sortClass, setSortClass] = useState({
        vendor_key: 0,
        game_name : 0,
        rule_type: 0,
        game_code: 0,
        status: 0,
        created_at: 0
    })

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const [findType, setFindType] = useState([]);

    const onCheckType = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setFindType([...findType, value]);
        }
        else {
            setFindType(findType.filter(id => id !== value));
        }
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getGameList(1, pageInfo.perPage)
        }
      }

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);
    const [Input, setInput] = useState({
        gameName: "",
        vendor_key: ""
    })
    const { gameName, vendor_key } = Input
    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }
    const getGameList = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }
        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                gameName: gameName,
                vendor_key: vendor_key,
                searchType: findType
            }
        }
        dispatch(getGameListAction(params, setPageInfo, setCurPage));
    }

    const onChangeFunc = (id, status, isAll) => {
        const params = {
            game_id: id,
            game_status: status,
            isAll: isAll
        }
        changeAction(params)
        .then(()=>{
            getGameList(1, pageInfo.perPage);
        })
    }

    const get_gameList = () => {
        dispatch(get_gameListAction());
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getGameList(1, pageInfo.perPage);
            get_gameList()
        }
    }, [loginid, pageInfo.perPage])
    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="">
                    <div id="content" className="app-content">
                        <div className = "main_app_content">
                            <ol className="breadcrumb float-xl-end">
                                
                                <li className="breadcrumb-item"><a>게임 관리</a></li>
                                <li className="breadcrumb-item active">게임 목록</li>
                            </ol>
                            <h1 className="page-header">게임 목록</h1>
                            <div className="row">
                                <div className="col-12">
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">검색</h4>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-horizontal form-bordered">
                                                <div className="form-group row"><label className="col-md-4 col-form-label">게임사</label>
                                                    <div className="col-md-8">
                                                        <div className="
                                                            vtreeselect-dark
                                                            vue-treeselect
                                                            vue-treeselect--single
                                                            vue-treeselect--searchable
                                                            vue-treeselect--has-value
                                                            vue-treeselect--open-below
                                                            ">
                                                            <div className="vue-treeselect__control">
                                                                <div className="vue-treeselect__input-container">
                                                                    <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                        name="vendor_key" value={vendor_key} onChange={onChange}
                                                                        style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                        <option value="" className="d-none">선택하세요</option>
                                                                        {
                                                                            getVendorList.map((item, index) => {
                                                                                return (
                                                                                    <option value={item.code} className="" key={index}>{item.name}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row"><label className="col-md-4 col-form-label">게임이름(영어)/심볼</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="게임이름/심볼"
                                                                name="gameName" value={gameName} 
                                                                onChange={onChange} onKeyDown={onEnter}
                                                                className="form-control fs-13px h-45px" />
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임이름(영어)/심볼</label></div>
                                                    </div>
                                                </div>
                                                <div className="form-group row"><label className="col-md-4 col-form-label">점검여부</label>
                                                    <div className="col-md-8">
                                                        <div className="mb-3 mb-md-0">
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" id="sub_type1" className="form-check-input"  value = {1} onChange = {onCheckType} onKeyDown={onEnter}/>
                                                                <label htmlFor="sub_type1" className="form-check-label"> 정상</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input type="checkbox" id="sub_type2" className="form-check-input" value = {0} onChange = {onCheckType} onKeyDown={onEnter}/>
                                                                <label htmlFor="sub_type2" className="form-check-label">점검</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="btn-group" style = {{width:"150px", marginRight:"15px"}}> 
                                                            <button type="button" className="btn btn-success btn-lg" onClick={() => getGameList(1, pageInfo.perPage)}> 검색 </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">게임 목록 (총 : {rowsAffected[0]}개)</h4>
                                            <div>
                                                <button type="button" className="btn btn-primary btn-sm mx-2" onClick={()=>onChangeFunc("", 1, false)}> 전체 게임 사용 ON</button>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={()=>onChangeFunc("", 0, false)}> 전체 게임 사용 OFF </button>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="vgt-wrap">
                                                <div className="vgt-inner-wrap">
                                                    <div className="vgt-fixed-header"></div>
                                                    <div className="vgt-responsive agentList">
                                                        <div className="el-table el-table--fit el-table--enable-row-hover" style={{ width: "100%" }}>
                                                            
                                                            <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                                <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "1384px" }}>

                                                                    {/* <col name="el-table_33_column_256" width="10%" />
                                                                    <col name="el-table_33_column_257" width="10%" />
                                                                    <col name="el-table_33_column_258" width="15%" />
                                                                    <col name="el-table_33_column_259" width="15%" />
                                                                    <col name="el-table_33_column_260" width="15%" />
                                                                    <col name="el-table_33_column_261" width="10%" />
                                                                    <col name="el-table_33_column_262" width="10%" />
                                                                    <col name="el-table_33_column_264" width="15%" />
                                                                    <col name="gutter" width="0" /> */}

                                                                    <thead className="has-gutter">
                                                                        <tr className="">
                                                                            <th colSpan="1" rowSpan="1" className="el-table_33_column_256  is-center   is-leaf" style={{width:"5%"}}>
                                                                                <div className="cell">번호</div>
                                                                            </th>
                                                                            <th colSpan="1" rowSpan="1" className="el-table_33_column_257  is-center   is-leaf">
                                                                                <div className="cell">LOGO</div>
                                                                            </th>
                                                                            {
                                                                                header.map((item,index)=>{
                                                                                    return(
                                                                                        <th colSpan="1" rowSpan="1" className={`${item.className}  is-center is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                            <div className="cell" onClick={()=>getGameList(1, pageInfo.perPage, item.property)}>{item.name}
                                                                                                <span className="caret-wrapper">
                                                                                                    <i className="sort-caret ascending"></i>
                                                                                                    <i className="sort-caret descending"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                    )
                                                                                })
                                                                            }                                                                            
                                                                            <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            recordset.map((item, index) => {
                                                                                return (
                                                                                    <tr className="" key={index}>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_256  is-center   is-leaf">
                                                                                            <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_257  is-center   is-leaf">
                                                                                            <div className="cell"><img src={item.image_url} /></div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_258  is-center   is-leaf is-sortable">
                                                                                            <div className="cell">{item.provider_name}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_259  is-center   is-leaf is-sortable">
                                                                                            <div className="cell">{item.game_name}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_260  is-center   is-leaf is-sortable">
                                                                                            <div className="cell">{item.game_code}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_261  is-center   is-leaf">
                                                                                            <div className="cell">{item.game_type}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_262  is-center   is-leaf is-sortable">
                                                                                            <div className={`cell ${getStatusClassName(item.status)}`} style={{ width: "50%", borderRadius: "5px" }} onClick={()=>onChangeFunc(item.id, item.status, true)}>{getCheckStatus(item.status)}</div>
                                                                                        </td>
                                                                                        <td colSpan="1" rowSpan="1" className="el-table_33_column_264  is-center descending  is-leaf is-sortable">
                                                                                            <div className="cell">{dateFormation(item.created_at, 3)}<br/>{dateFormation(item.created_at, 10)}</div>
                                                                                        </td>
                                                                                        <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}><span className="el-table__empty-text"></span></div>
                                                            </div>                                                            
                                                            <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                        </div>
                                                    </div>
                                                    <Pagenation
                                                        getList={getGameList}
                                                        pageInfo={pageInfo}
                                                        setPageInfo={setPageInfo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}


export default GameListDashboard;