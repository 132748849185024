import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import SettingDashboard from "../../components/common/setting/Setting";

const Setting = () => {
 return(
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
        <Header/>
        <SettingDashboard/>
        <Sidebar/>
    </div>
 )   
}

export default Setting;