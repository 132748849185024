import React from "react";

const NameChange = (props) => {
    const { onNameActive, nameActive, onChangeUserInfo } = props;
  
  return (
    <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: nameActive ? "block" : "none", zIndex: "11111" }}>
        <div className = "modal-backgroud" onClick={onNameActive}></div>
            <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: nameActive ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
                <div className="el-dialog__header">
                    <span className="el-dialog__title">변경하시겠습니까?</span>
                    <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                        <i className="fa fa-close" style={{ color: "white" }} onClick={onNameActive}></i>
                    </button>
                </div>
                <div className="el-dialog__footer">
                    <div data-v-12fb228d="" className="dialog-footer">
                        <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onChangeUserInfo}>
                            <span> 확인 </span>
                        </button>
                        <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onNameActive}>
                            <span> 취소 </span>
                        </button>
                    </div>
                </div>
            </div>
    </div>
    );

}

export default NameChange;