import React from "react";
import { NavLink } from "react-router-dom";

const SideMenuItem = (props) => {
  const { menu, active } = props;

  return (
    <div className={`menu-item sub-menu ${active && "show"}`}>
      <div className="sidebar-item">
        <NavLink to={menu.value} className={({ isActive }) => `menu-link ` + (isActive && "menu-active-link")}>
          <div className="menu-text"> {menu.name} </div>
        </NavLink>
      </div>
    </div>
  );
};

export default SideMenuItem;
