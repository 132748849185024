import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import PointTranHistoryDashboard from "../../components/common/agentManage/PointTranHistory";
const PointTranHistory = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <PointTranHistoryDashboard/>
      <Sidebar pageValue="agent"/>
    </div>
  )
}

export default PointTranHistory;