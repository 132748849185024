import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getAgentLevel, getAgentLevelClass, getStatus, getStatusClassName, getUserStatus, getUserStatusClassName, toastr } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import Dropdown from "../Dropdown";
import { getAgentListAction, getUserListAction, deleteUserAction, getUserTransListAction } from "../../../redux/action/userManageAction"
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { history } from "../../../utils/history";
import { SET_TRANSACTION_MOVE } from "../../../redux/action/types";

const header = [
    {
        name: "아이디",
        className: "el-table_28_column_203",
        property: "loginid"
    },
    {
        name: "상위업체",
        className: "el-table_28_column_204",
        property: "parent_id"
    },
    {
        name: "닉네임",
        className: "el-table_28_column_205",
        property: "username"
    },
    {
        name: "금액",
        className: "el-table_28_column_206",
        property: "money"
    },   
    {
        name: "계정상태",
        className: "el-table_28_column_208",
        property: "status"
    },
    {
        name: "가입일자",
        className: "el-table_28_column_209",
        property: "created_at"
    }
]
function UserListdashiboard() {

    const dispatch = useDispatch()
    const list = useSelector((state) => state.user.userData)
    const userData = useSelector((state) => state.userManage.userList);
    const agentList = useSelector((state) => state.userManage.getAgentList);
    const { loginid } = list;
    const userList = !isEmpty(userData)? (!isEmpty(userData.data)? userData.data : []) : []
    const userCount = !isEmpty(userData)? userData.totalCount : 0    

    const [deleteLoginId, setDeleteLoginId] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const onDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const onClickDelete = (loginId) => {
        setDeleteLoginId(loginId);
        onDeleteModal();
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getUserList(1, pageInfo.perPage)
        }
      }

    const defaultSort = {
        loginid: 0,
        parent_id : 0,
        username: 0,
        status: 0,
        money: 0,
        created_at: 0
    }
    const [sortClass, setSortClass] = useState({
        loginid: 0,
        parent_id : 0,
        username: 0,
        status: 0,
        money: 0,
        created_at: 0
    })

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);

    const [Input, setInput] = useState({
        name: "",
        userId: "",
        startDate: "",
        endDate: "",
        agentId: ""
    })

    const { name, userId, startDate, endDate, agentId } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }
    //--------------트랜잭션 이행함수----------------//
        const getUserTrans = (page, perPage, name, startDate, agentId) => {
            if (page > pageInfo.totalPage) {
                page = pageInfo.totalPage;
            }
            if (page < 1) {
                page = 1;
            }

            const params = {            
                    userId: name,
                    username:"",
                    startDate: startDate,
                    endDate: "",
                    agentId: agentId           
            }
            dispatch({
                type: SET_TRANSACTION_MOVE,
                payload: params
            });
            history.navigate("/TransactionHistory")
        }   


    //----------------유저목록 얻는 함수-------------//
    const getUserList = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                name: name,
                userId: userId,
                loginid: loginid,
                startDate: startDate,
                endDate: endDate,
                agentId: agentId
            }
        }
        dispatch(getUserListAction(params, setPageInfo, setCurPage));
    }
    const onDeleteUser = (e) => {
        e.preventDefault();
        const params = {
            loginid: deleteLoginId

        }
        deleteUserAction(params, onDeleteModal)
            .then(() => {
                getUserList(pageInfo.curPage, pageInfo.perPage);
            });
    }
    const get_userAgentList = () => {
        dispatch(getAgentListAction());
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getUserList(1, pageInfo.perPage);
            get_userAgentList()
        }
    }, [loginid, pageInfo.perPage])

    return (
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="" id="content" className="app-content">
                <div className = "main_app_content">
                    <ol className="breadcrumb float-xl-end">
                        
                        <li className="breadcrumb-item"><a>유저 관리</a></li>
                        <li className="breadcrumb-item active">유저 목록</li>
                    </ol>
                    <h1 className="page-header">유저 목록</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">검색</h4>
                                </div>
                                <div className="panel-body">
                                    <form className="form-horizontal form-bordered">
                                        <div className="form-group row">
                                            <label
                                                className="col-md-4 col-form-label">상위 업체</label>
                                            <div className="col-md-8">
                                                <div className="
                                                    vtreeselect-dark
                                                    vue-treeselect
                                                    vue-treeselect--single
                                                    vue-treeselect--searchable
                                                    vue-treeselect--has-value
                                                    vue-treeselect--open-below">
                                                    <div className="vue-treeselect__control">
                                                        <div className="vue-treeselect__input-container">
                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                name="agentId" value={agentId} onChange={onChange}
                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                <option value="" className="d-none">선택하세요</option>
                                                                {
                                                                    agentList.map((item, index) => {
                                                                        return (
                                                                            <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                </div>
                                                {/* <div className="mt-3 mb-3 mb-md-0">
                                                    <div className="form-check form-check-inline">
                                                        <input id="low_agent_allow" type="checkbox" className="form-check-input" />
                                                        <label htmlFor="low_agent_allow" className="form-check-label">하부 업체 포함 검색 여부</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group row period">
                                            <label className="col-md-4 col-form-label">가입기간(시작 ~ 종료)</label>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" onChange={onChange}/>
                                                        {
                                                            !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" onChange={onChange}/>
                                                        {
                                                            !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">로그인 아이디</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="로그인 아이디" className="form-control fs-13px h-45px" value={userId} name="userId" onChange={onChange} onKeyDown = {onEnter} />
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">로그인 아이디</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">이름</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="이름" className="form-control fs-13px h-45px" value={name} name="name" onChange={onChange} onKeyDown = {onEnter} />
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">이름</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="btn-group" style={{ width: "150px", marginRight:"15px" }}>
                                                    <button type="button" className="btn btn-success btn-lg" onClick={() => getUserList(1, pageInfo.perPage)}> 검색 </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">유저 목록 (총 {userCount} 개 )</h4>
                                </div>

                                <div className="panel-body">
                                    <div className="vgt-wrap">
                                        <div className="vgt-inner-wrap">
                                            <div className="vgt-fixed-header"></div>
                                            <div className="vgt-responsive agentList">
                                                <div className="el-table el-table--fit el-table--enable-row-hover"
                                                    style={{ width: "100%" }}>                                                    
                                                    <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                        <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "1384px" }}>

                                                            {/* <tr name="el-table_28_column_202" width="10%" />
                                                            <tr name="el-table_28_column_203" width="15%" />
                                                            <tr name="el-table_28_column_204" width="15%" />
                                                            <tr name="el-table_28_column_205" width="15%" />
                                                            <tr name="el-table_28_column_206" width="10%" />                                                            
                                                            <tr name="el-table_28_column_208" width="10%" />
                                                            <tr name="el-table_28_column_209" width="15%" />
                                                            <tr name="el-table_28_column_210" width="10%" />
                                                            <tr name="gutter" width="0" /> */}

                                                            <thead className="has-gutter">
                                                                <tr className="">
                                                                    <th colSpan="1" rowSpan="1" className="el-table_28_column_202  is-center   is-leaf" style={{width:"7%"}}>
                                                                        <div className="cell">번호</div>
                                                                    </th>
                                                                    {
                                                                        header.map((item, index) => {
                                                                            return (
                                                                                <th colSpan="1" rowSpan="1" className={`${item.className} is-center is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                    <div className="cell" onClick={() => getUserList(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                        <span className="caret-wrapper">
                                                                                            <i className="sort-caret ascending"></i>
                                                                                            <i className="sort-caret descending"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            )
                                                                        })
                                                                    }
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_28_column_210  is-center   is-leaf">
                                                                        <div className="cell">설정</div>
                                                                    </th>
                                                                    <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    userList.map((user, index) => {
                                                                        return (
                                                                            <tr className="" key={index}>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_202  is-center   is-leaf">
                                                                                    <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_203  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {user.loginid}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_204  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {user.parent_loginid}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_205  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {user.username}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_206  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" style = {{marginRight: "10px"}} >
                                                                                        <span className="caret-wrapper-down" style = {{alignItems:"end"}}>
                                                                                            {Number(user.money).toLocaleString()}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_208  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className={`caret-wrapper-status ${getUserStatusClassName(user.status)} `} style={{ borderRadius: "6px" }}>
                                                                                            {getUserStatus(user.status)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1"
                                                                                    className="el-table_28_column_209  is-center descending  is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {dateFormation(user.created_at, 3)}<br />{dateFormation(user.created_at, 10)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_210  is-center   is-leaf" >
                                                                                    <div className="btn-group">
                                                                                    <button data-v-5e3e41f0=""
                                                                                                type="button"
                                                                                                onClick = {() =>getUserTrans( pageInfo.curPage, pageInfo.perPage, user.loginid, user.created_at, user.parent_id )}
                                                                                                className="el-button el-button--primary el-button--mini"
                                                                                                style={{ marginBottom: "3px" }}>
                                                                                            <span>트랜잭션</span>
                                                                                    </button>
                                                                                        
                                                                                    </div>
                                                                                    {/* <Dropdown onClickDelete={onClickDelete} loginId={user.loginid} /> */}
                                                                                </td>
                                                                                <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                        <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                            <span className="el-table__empty-text" style={{display: isEmpty(userList)? "flex":"none"}}>데이터가 없습니다.</span>
                                                        </div>
                                                    </div>
                                                    <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                </div>
                                            </div>
                                            <Pagenation
                                                getList={getUserList}
                                                pageInfo={pageInfo}
                                                setPageInfo={setPageInfo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ----------------삭제확인----------------- */}
                <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: deleteModal ? "block" : "none", zIndex: "11111" }}>
                    <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ marginTop: "15vh" }}>
                        <div className="el-dialog__header">
                            <span className="el-dialog__title">삭제하시겠습니까?</span>
                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                <i className="fa fa-close" style={{ color: "white" }} onClick={onDeleteModal}></i>
                            </button>
                        </div>
                        <div className="el-dialog__footer">
                            <div data-v-12fb228d="" className="dialog-footer">
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--default" onClick={onDeleteModal}>
                                    <span> 취소 </span>
                                </button>
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--default" onClick={onDeleteUser}>
                                    <span> 확인 </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
            </div>            
        </section>
    );

}

export default UserListdashiboard;