import {
  SET_AUTH,
  SET_CURRENT_USER,
  SET_DASHIBOARD_INFO,
  SET_LOADING,
  SET_SMART_SOCKET_DATA,
  SET_SOCKET_DATA,
} from "../action/types";

const initialState = {
  isAuthenticated: false,
  userData: {},
  Loading: false,
  error: null,
  dashData:[],
  socketData:{},
  smartSocketData:{} 
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case SET_LOADING:
      return {
        ...state,
        Loading: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        Authenticated: action.payload,
        userData: action.payload
      };
    case SET_DASHIBOARD_INFO:
    return {
      ...state,      
      dashData: action.payload
    };
    case SET_SOCKET_DATA: {
      return {
          ...state,
          socketData: action.payload
        };
    }
    case SET_SMART_SOCKET_DATA: {
      return {
          ...state,
          smartSocketData: action.payload
        };
    }  
    default:
      return state;
  }
}
