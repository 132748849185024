import React,{ useState }from "react";
import { useDispatch } from 'react-redux'
import { DoLogin } from "../../redux/action/userAction";
import { checkLoginId, toastr } from "../../utils";
import isEmpty from "../../validation/is-empty";

const Login = (props) => {
const dispatch = useDispatch();
const [Input, setInput] = useState({
  userloginid: "",
  userpassword: ""
})

const { userloginid, userpassword } = Input

const onChange = (e) => {
    const { name, value } = e.target;
    setInput({
        ...Input,
        [name]: value
    })
}

const onEnter = (e) => {
  if (e.key === 'Enter') {
    onSubmit(e)
  }
}

const onSubmit = (e) => {
  if(isEmpty(userloginid)){
    return toastr.warning("아이디를 입력해주세요.");
  }
  if(isEmpty(userpassword)){
    return toastr.warning("비밀번호를 입력해주세요.");
  }
  if(checkLoginId(userloginid)){
    return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
  }
    e.preventDefault()


    dispatch(DoLogin({
      loginid: userloginid,
      password: userpassword
    }));
}
  return (
    <div className="app">
      <div className="login-container" style={{backgroundImage: "url('./assets/img/background.2f10c794.png')"}}>
        <div className="login-layout">
            <div className="title-container d-flex" style={{width: "100%"}}>
                <img src="./assets/img/1.png" style={{height: "70px", margin: "20px auto"}}/>
            </div>
            <form className="el-form login-form el-form--label-left" autoComplete="on">
              <div className="el-form-item is-success is-required">
                <div className="el-form-item__content">
                  <span className="svg-container">
                      <svg data-v-f9f7fefc="" aria-hidden="true" className="svg-icon">
                          <use data-v-f9f7fefc=""></use>
                      </svg>
                  </span>
                  <div className="login-input el-input">
                      <input tabIndex="1" 
                        type="text" autoComplete="on" 
                        placeholder="아이디" name="userloginid" 
                        className="el-input__inner" 
                        onChange={onChange} 
                        value={userloginid}
                        onKeyDown={onEnter}/>
                  </div>
                </div>
              </div>
              <div className="el-form-item el-tooltip is-success is-required" aria-describedby="el-tooltip-8206" tabIndex="0">
                  <div className="el-form-item__content">
                    <span className="svg-container">
                        <svg data-v-f9f7fefc="" aria-hidden="true" className="svg-icon">
                            <use data-v-f9f7fefc=""></use>
                        </svg>
                    </span>
                    <div className="login-input el-input">
                        <input tabIndex="2" 
                          type="password" autoComplete="on" 
                          placeholder="비밀번호" name="userpassword" 
                          className="el-input__inner" 
                          onChange={onChange} 
                          value={userpassword}
                          onKeyDown={onEnter}/>
                    </div>

                    <span className="show-pwd">
                        <svg data-v-f9f7fefc="" aria-hidden="true" className="svg-icon">
                            <use data-v-f9f7fefc=""></use>
                        </svg>
                    </span>
                    
                  </div>
              </div>

                <button type="button" className="el-button login-btn el-button--primary" onClick={onSubmit}>
                  <span>로 그 인</span>
                </button>              
            </form>
            
        </div>
        <div style={{position: "absolute", top: "10px", right: "10px"}}>  </div>
      </div>
    </div>
  );
};

export default Login;
