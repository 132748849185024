import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { changeProviderAction, getGamecompanyAction } from "../../../redux/action/gameManageAction";
import isEmpty from "../../../validation/is-empty";
import { getPayStatusClassName, getCheckStatus, getStatusClassName } from "../../../utils";

function GamecomListDashboard(){
    const gameCompanyList = useSelector((state) => state.gameManage.gameCompanyList);    
    const list = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const { loginid } = list;
    const getGamecompanyList = () => {        
        dispatch(getGamecompanyAction());
    }

    const onChangeFunc = (status) => {
        const params = {
            code: "TOPGAME",
            provider_status: status            
        }
        changeProviderAction(params)
        .then(()=>{
            getGamecompanyList();
        })
    }

    useEffect(()=>{        
        if(!isEmpty(loginid)){
            getGamecompanyList();
        }
    }, [loginid])
    return(
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="" id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol className="breadcrumb float-xl-end">
                            
                            <li className="breadcrumb-item"><a>게임 관리</a></li>
                            <li className="breadcrumb-item active">게임사 목록</li>
                        </ol>
                        <h1 className="page-header">게임사 목록</h1>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">게임사 목록 (총 1개)</h4>
                                    </div>
                                    <div role="alert" aria-live="polite" aria-atomic="true" className="alert rounded-0 alert-black mb-0">
                                        <div className="d-flex"><i className="fa fa-check fa-2x me-1"></i>
                                            <div className="mb-0 ps-2"> API를 통한 게임사 정보는 "API &gt; 게임사 리스트 조회" API를 통해 조회 하실 수 있습니다. </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <table className="bg-gray-700 table table-bordered widget-table widget-table-rounded">
                                            <thead>
                                                <tr>
                                                    <th width="5%" className="align-middle text-center">번호</th>
                                                    <th width="2%" className="align-middle text-center">LOGO</th>
                                                    <th className="align-middle text-center">게임사</th>
                                                    <th className="align-middle text-center">점검여부( ON/OFF)</th>
                                        
                                                </tr>
                                            </thead>
                                            {
                                                gameCompanyList.map((item, index)=>{
                                                    return(
                                                        <tbody key={index}>
                                                            <tr>
                                                                <td className="align-middle text-center">{index+1}</td>
                                                                <td className="align-middle text-center">
                                                                    <div className="game-buttons-popup">
                                                                        <div className="thumb"><img src={item.image_url}/></div>
                                                                        <div className="game-buttons-name">
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                        <div className="hover-lay">
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    <div className="gameCom-name" style = {{fontSize:"revert"}}>{item.name}</div>
                                                                </td>
                                                                <td className="align-middle text-center"><span className={`badge  status ${getStatusClassName(item.status)}`} style = {{fontSize:"revert", cursor:"pointer"}} onClick={()=>onChangeFunc(item.status)}> {getCheckStatus(item.status)} </span></td>
                                                    
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }

                                                )
                                            }
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </section>
        </Fragment>
    );
}

export default GamecomListDashboard;