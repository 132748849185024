import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SideMenuGroup from "./SideMenuGroup";
import { useSelector } from 'react-redux'

const sideMenus = [
  {
    name: "업체관리",
    value: "agent",
    className: "fa fa-sitemap",
    subMenus: [
      {
        name: "업체 트리뷰",
        value: "/agentTreeView"
      },
      {
        name: "업체 목록",
        value: "/agentList"
      },
      {
        name: "가입승인 요청",
        value: "/joinConsentRequest"
      },
      {
        name: "포인트 트랜잭션 내역",
        value: "/PointTranHistory"
      },
    ]
  },
  {
    name: "유저관리",
    value: "user",
    subMenus: [
      {
        name: "유저 목록",
        value: "/UserList"
      },
      {
        name: "트랜잭션 내역",
        value: "/TransactionHistory"
      },
      {
        name: "게임 내역",
        value: "/GameHistory"
      },
      {
        name: "로그인 내역",
        value: "/LoginHistory"
      },
      {
        name: "진행중 게임",
        value: "/DoingGame"
      },
      {
        name: "보너스 콜내역",
        value: "/BonusCallHistory"
      }
    ]
  },
  {
    name: "게임관리",
    value: "gameManage",
    subMenus: [
      {
        name: "게임사목록",
        value: "/GamecomList"
      },
      {
        name: "게임목록",
        value: "/GameList"
      },
 //     {
 //       name: "베팅한도설정",
//      value: "/BettingLimit"
 //     },
      {
        name: "업체에 게임사 배당",
        value: "/OddGamecompany"
      },
      {
        name: "업체에 게임 배당",
        value: "/OddGame"
      }
    ]
  },
  {
    name: "정산관리",
    value: "figure",
    subMenus: [
      {
        name: "유저별정산",
        value: "/UserFigure"
      },
      {
        name: "일별정산",
        value: "/DailyFigure"
      },
      {
        name: "월별정산",
        value: "/MonthlyFigure"
      }      
    ]
  },
  {
    name: "API",
    value: "API",
    subMenus: [
      {
        name: "API 매뉴얼",
        value: "/apiguide"
      }
      // {
      //   name: "콜백 API(심리스)",
      //   value: "/CallbackApi1"
      // },
      // {
      //   name: "콜백 API테스트",
      //   value: "/CallbackApi"
      // },
      // {
      //   name: "콜백 API테스트 로그",
      //   value: "/CallbackApi2"
      // },
      // {
      //   name: "API에로 로그",
      //   value: "/ApiError"
      // }
    ]
  },
]


const Sidebar = (props) => {

  const { pageValue, sidebarActive } = props;  
  
  const [ activeValue, setActiveValue ] = useState(pageValue);

  const onValue = (value) => {
    setActiveValue(value);
  }

  const list = useSelector((state) => state.user.userData);
  const { username, loginid } = list

  return (
    <div id="sidebar" className="app-sidebar" style = {{display: sidebarActive? "none":"block"}}>
      <section className="scrollbar-wrapper" className=" ps-container app-sidebar-content h-100 ps ps--active-y ps--scrolling-y" style={{zIndex: "1", overflow:"scroll"}}>
        <div className="menu">
            <div className="menu-profile">
              <NavLink to="/dashboard" className="menu-profile-link d-flex">
                <div className="menu-profile-image">
                  <img src="./assets/img/2.png"/>
                </div>
                <div className="menu-profile-info">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">{list.username}</div>
                  </div>
                  <small>({list.loginid})</small>
                </div>
              </NavLink>
            </div>
            <div id="appSidebarProfileMenu" className="collapse d-block">
              <div className="menu-item pt-5px">
                <NavLink to="/setting" className={`menu-link`} style={{cursor:"pointer"}}>
                  <div className="menu-icon"><i className="fa fa-cog"></i></div>
                  <div className="menu-text">설정 및 정보</div>
                </NavLink>
              </div>
              <div className="menu-divider m-0"></div>
            </div>
        </div>
        <div className="menu">
          <div className="menu-header">TOPGAME</div>
          <div id="0" className="menu-item has-sub">
            <NavLink data-v-2d2bbdc2="" to="/dashboard" 
                className={({isActive}) => `menu-link ` + ((isActive && (activeValue === "/dashboard")) && "menu-active-link")} 
                onClick={() => onValue("/dashboard")}>
              <div data-v-2d2bbdc2="" className="menu-icon"><i data-v-2d2bbdc2="" className="fa fa-chart-bar"></i></div>
              <div data-v-2d2bbdc2="" className="menu-text">대시보드</div>
            </NavLink>
          </div>
          {
            sideMenus.map((menuGroup, index) => {
              return <SideMenuGroup 
                        menuGroup={menuGroup}  
                        active={activeValue === menuGroup.value} 
                        onValue={onValue}
                        key={index}/>
            })
          }          
        </div>
      </section>      
      <div className="app-sidebar-mobile-backdrop" style={{zIndex: "1"}}>
        <a href="https://sc4-admin.dreamgates.net/#" className="stretched-link"></a>
      </div>
    </div>
  );
};

export default Sidebar;
