import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getGameListAction } from '../../../redux/action/gameManageAction';
import { getAgentAction } from '../../../redux/action/userAction';
import { get_gameListAction } from '../../../redux/action/userManageAction';
import { dateFormation, getCheckStatus, getStatusClassName, toastr, getOddGameStatus, getOddGameStatusClassName } from '../../../utils';
import isEmpty from '../../../validation/is-empty';
import axios from "axios";
import GameTreeNode from "../gameManage/GameTreeNode"
import { api_url, image_url } from "../../../utils/keys";


const header = [
    {
        name: "게임사",
        className: "el-table_37_column_275",
        property: "vendor_key"
    },
    {
        name: "게임이름",
        className: "el-table_37_column_276",
        property: "game_name"
    },
    {
        name: "심볼",
        className: "el-table_37_column_277",
        property: "rule_type"
    },
    {
        name: "카테고리",
        className: "el-table_37_column_278",
        property: "game_code"
    },
    {
        name: "점검여부",
        className: "el-table_37_column_279",
        property: "status"
    },
    {
        name: "등록일",
        className: "el-table_37_column_280",
        property: "created_at"
    }
]


function OddGameDashboard() {

    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const agentList = useSelector((state) => state.figureManage.getAgentList);
    const getVendorList = useSelector((state) => state.userManage.getGameList);
    const gameList = useSelector((state) => state.gameManage.gameList);
    const { recordset, rowsAffected } = gameList
    const { loginid } = list;

    const defaultSort = {
        vendor_key: 0,
        game_name : 0,
        rule_type: 0,
        game_code: 0,
        status: 0,
        created_at: 0
    }
    const [sortClass, setSortClass] = useState({
        vendor_key: 0,
        game_name : 0,
        rule_type: 0,
        game_code: 0,
        status: 0,
        created_at: 0
    })

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const [ checkBox, setCheckBox ] = useState(false)
    const [bonsas, setBonsas] = useState([]);
    const [activeAgent, setActiveAgent] = useState({});
    const [gameIds, setGameIds] = useState([]);
    const [findType, setFindType] = useState([]);

    const onCheckBox = () => {
        setCheckBox(!checkBox)
        if(checkBox){
            setGameIds(
                ...gameIds,
                ["1", "53", "54", "55", "56", "57", "58", "59"]
                )
            }else {
                setGameIds([])
            }
            
        }

    
    const onCheckType = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setFindType([...findType, value]);
        }
        else {
            setFindType(findType.filter(id => id !== value));
        }
    }

    const [Input, setInput] = useState({
        parentId: "",
        agentId: "",
        vendor_key: "",
        gameName: ""
    })

    const { parentId, agentId, vendor_key, gameName } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            searchAgent(loginid, "true")
        }
      }

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);

    const onCheckGame = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setGameIds([...gameIds, value]);
        }
        else {
            setGameIds(gameIds.filter(id => id !== value));
        }
    }
    const getChildren = (loginid, isSearch) => {
        let children = [];
        return new Promise(resolve => {
            axios.post(api_url + "/api/game/tree_list", {
                loginid: loginid,
                isSearch: isSearch
            }).then(res => {
                if (res.data.success) {
                    resolve(res.data.data);
                }
                else {
                    toastr.warning(res.data.message)
                }
            }).catch((err) => {
                console.log('err: ', err);
                if (err.response && (err.response.Status === 401)) {
                    // logout
                }
            })
        });
    }

    const getGameLister = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }
        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                gameName: gameName,
                vendor_key: vendor_key,
                searchType: findType 
            }
        }
        dispatch(getGameListAction(params, setPageInfo, setCurPage));
    }

    const searchAgent = (loginid, isSearch) => {
        let children = [];
        return new Promise(resolve => {
            axios.post(api_url + "/api/game/tree_list", {
                loginid: loginid,
                isSearch: isSearch,
                parentId: parentId,
                agentId: agentId
            }).then(res => {
                if (res.data.success) {
                    resolve(res.data.data);
                    setBonsas(res.data.data);
                    setActiveAgent(res.data.data[0])
                }
                else {
                    toastr.warning(res.data.message)
                }
            }).catch((err) => {
                console.log('err: ', err);
                if (err.response && (err.response.Status === 401)) {
                    // logout
                }
            })
        });
    }

    const get_gameAgentList = () => {
        dispatch(getAgentAction());
    }

    const get_gameCompanyList = () => {
        dispatch(get_gameListAction());
    }



    const onSetGame = () => {
        if( isEmpty(activeAgent) ){
            return toastr.warning("업체를 선택해주세요.")
        }
        axios.post(api_url + "/api/game/game_setting", {
            enterprise_id: activeAgent.loginid,
            game_ids: gameIds
        }).then(res => {
            if (res.data.success) {
                toastr.success(res.data.message)
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            console.log('err: ', err);
            if (err.response && (err.response.Status === 401)) {
                // logout
            }
        })
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            get_gameAgentList()
            get_gameCompanyList()
            getGameLister(1, pageInfo.perPage)
            getChildren(loginid, "false")
                .then(myChildren => {
                    setBonsas(myChildren);
                    setActiveAgent(myChildren[0])
                });
        }
    }, [loginid]);

    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="">
                    <div id="content" className="app-content">
                        <div className="main_app_content">
                            <ol className="breadcrumb float-xl-end">
                                
                                <li className="breadcrumb-item"><a>게임 관리</a></li>
                                <li className="breadcrumb-item active">업체에 게임 배당</li>
                            </ol>
                            <h1 className="page-header">업체에 게임 배당</h1>
                            <div className="row">
                                <div className="col-5">
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">업체를 선택하세요.</h4>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-horizontal form-bordered">
                                                <div className="form-group row"><label className="col-md-4 col-form-label">업체</label>
                                                    <div className="col-md-8">
                                                        <div className="
                                                            vtreeselect-dark
                                                            vue-treeselect
                                                            vue-treeselect--single
                                                            vue-treeselect--searchable
                                                            vue-treeselect--has-value
                                                            vue-treeselect--open-below
                                                            ">
                                                            <div className="vue-treeselect__control">
                                                                <div className="vue-treeselect__input-container" style={{backgroundColor:"rgb(7 19 29)"}}>
                                                                    <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                        value={parentId} name="parentId" onChange={onChange}
                                                                        style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%", height: "100%" }}>
                                                                        <option value="" className="d-none">선택하세요</option>
                                                                        {
                                                                            agentList.map((item, index) => {
                                                                                return (
                                                                                    <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row"><label className="col-md-4 col-form-label">업체 이름/아이디</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-1">
                                                            <input type="text" placeholder="업체 이름/아이디"
                                                                value={agentId} name="agentId" 
                                                                onChange={onChange} onKeyDown={onEnter}
                                                                className="form-control fs-13px h-20px" />
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">업체 이름/아이디</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="btn-group" style={{ width: "110px", marginRight: "15px" }}>
                                                            <button type="button" className="btn btn-success btn-lg" onClick={() => searchAgent(loginid, "true")}> 검색 </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">업체 트리뷰</h4>
                                            {/* <button type="button" className="btn btn-primary btn-sm"> 전체 선택 / 해지 </button> */}
                                        </div>
                                        <div className="el-table tree_view el-table--fit el-table--enable-row-hover el-table--enable-row-transition" style={{ width: "100%" }}>
                                            <div className="hidden-columns">
                                                <div></div>
                                            </div>
                                            
                                            <div className="el-table__body-wrapper is-scrolling-none">
                                                <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{marginTop:"-10px"}}>

                                                    {/* <tr name="el-table_36_column_271" width="574" /> */}

                                                    <tbody>
                                                        {
                                                            bonsas.map((bonsaItem, index) => {
                                                                return <GameTreeNode
                                                                    data={bonsaItem}
                                                                    level={1}
                                                                    activeAgent={activeAgent}
                                                                    setActiveAgent={setActiveAgent}
                                                                    getChildren={getChildren}
                                                                    setGameIds={setGameIds}
                                                                    key={index} />
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                        </div>
                                    </div>
                                    <div className="btn-group w-100 mt-2">
                                        <button type="button" className="el-button btn btn-primary btn-lg py-3 el-button--primary" onClick={onSetGame} style={{margin:"unset"}}>
                                            <span>  게임 배당 및 해지 </span>
                                        </button>
                                    </div>
                                    {/* <div className="btn-group w-100 mt-2">
                                        <button type="button" className="el-button btn btn-danger btn-lg py-3 el-button--danger" onClick={onSetGame} style={{margin:"unset"}}>
                                            <span> 업체의 게임 해지 </span>
                                        </button>
                                    </div> */}
                                </div>
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="panel panel-inverse">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">게임 검색</h4>
                                                </div>
                                                <div className="panel-body">
                                                    <form className="form-horizontal form-bordered">
                                                        <div className="form-group row"><label className="col-md-4 col-form-label">게임사</label>
                                                            <div className="col-md-8">
                                                                <div className="
                                                                    vtreeselect-dark
                                                                    vue-treeselect
                                                                    vue-treeselect--single
                                                                    vue-treeselect--searchable
                                                                    vue-treeselect--has-value
                                                                    vue-treeselect--open-below
                                                                    ">
                                                                    <div className="vue-treeselect__control">
                                                                        <div className="vue-treeselect__input-container" style={{backgroundColor:"rgb(7 19 29)"}}>
                                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                                value={vendor_key} name="vendor_key" onChange={onChange}
                                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                                <option value="" className="d-none">선택하세요</option>
                                                                                {
                                                                                    getVendorList.map((item, index) => {
                                                                                        return (
                                                                                            <option value={item.code} className="" key={index}>{item.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row"><label className="col-md-4 col-form-label">게임이름(영어)/심볼</label>
                                                            <div className="col-md-8">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input type="text" placeholder="게임이름(영어)/심볼"
                                                                        value={gameName} name="gameName" onChange={onChange}
                                                                        className="form-control fs-13px h-45px" />
                                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임이름(영어)/심볼</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row"><label className="col-md-4 col-form-label">점검여부</label>
                                                            <div className="col-md-8">
                                                                <div className="mb-3 mb-md-0">
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="checkbox" id="sub_type1" className="form-check-input" value = {1} onChange = {onCheckType} readOnly/>
                                                                        <label htmlFor="sub_type1" className="form-check-label"> 정상</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input type="checkbox" id="sub_type2" className="form-check-input" value = {0} onChange = {onCheckType} readOnly/>
                                                                        <label htmlFor="sub_type2" className="form-check-label">점검</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="btn-group" style={{ width: "110px", marginRight: "15px" }}>
                                                                    <button type="button" className="btn btn-success btn-lg" onClick={() => getGameLister(1, pageInfo.perPage)}> 검색 </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="panel panel-inverse">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">게임 목록 (총 {rowsAffected[0]}개)</h4>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="vgt-wrap">
                                                        <div className="vgt-inner-wrap">
                                                            <div className="vgt-fixed-header"></div>
                                                            <div className="vgt-responsive agentList">
                                                                <div className="el-table el-table--fit el-table--scrollable-x el-table--enable-row-hover" style={{ width: "100%" }}>
                                                                    
                                                                    <div className="el-table__header-wrapper">
                                                                        <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "898px" }}>

                                                                            {/* <tr name="el-table_37_column_272" width="5%" />
                                                                            <tr name="el-table_37_column_273" width="10%" />
                                                                            <tr name="el-table_37_column_274" width="10%" />
                                                                            <tr name="el-table_37_column_275" width="10%" />
                                                                            <tr name="el-table_37_column_276" width="10%" />
                                                                            <tr name="el-table_37_column_277" width="10%" />
                                                                            <tr name="el-table_37_column_278" width="10%" />
                                                                            <tr name="el-table_37_column_279" width="15%" />
                                                                            <tr name="el-table_37_column_280" width="10%" />
                                                                            <tr name="gutter" width="0" /> */}

                                                                            <thead className="has-gutter">
                                                                                <tr className="">
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_37_column_272  is-center el-table-column--selection  is-leaf" style={{width:"5%"}}>
                                                                                        <div className="cell">
                                                                                            <label className="el-checkbox is-disabled">
                                                                                                <span className="el-checkbox__input ">
                                                                                                    <span className="el-checkbox__inner"></span>
                                                                                                    <input type="checkbox" aria-hidden="false" onClick={onCheckBox}  checked={false} className="el-checkbox__original"  readOnly/>
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_37_column_273  is-center   is-leaf">
                                                                                        <div className="cell">LOGO</div>
                                                                                    </th>
                                                                                    {
                                                                                        header.map((item,index)=>{
                                                                                            return(
                                                                                                <th colSpan="1" rowSpan="1" className={`${item.className}  is-center   is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                                    <div className="cell" onClick={()=>getGameLister(1, pageInfo.perPage, item.property)}>{item.name}
                                                                                                        <span className="caret-wrapper">
                                                                                                            <i className="sort-caret ascending"></i>
                                                                                                            <i className="sort-caret descending"></i>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </th>
                                                                                            )
                                                                                        })
                                                                                    } 
                                                                                    
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_37_column_280  is-center  is-leaf">
                                                                                        <div className="cell">상태</div>
                                                                                    </th>
                                                                                    <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    recordset.map((item, index) => {
                                                                                        let flag = false
                                                                                        gameIds.forEach(num => {
                                                                                            if (num == item.id) {
                                                                                                flag = true
                                                                                            }
                                                                                        })
                                                                                        return (
                                                                                            <tr className="" key={index}>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_37_column_272 is-center el-table-column--selection">
                                                                                                    <div className="cell">
                                                                                                        <label className="el-checkbox">
                                                                                                            <span className="el-checkbox__input">
                                                                                                                <input type="checkbox"
                                                                                                                    aria-hidden="false"
                                                                                                                    className="el-checkbox__original"
                                                                                                                    checked={flag}
                                                                                                                    value={item.id}
                                                                                                                    onChange={onCheckGame} readOnly/>
                                                                                                                <span className="el-checkbox__inner fa"></span>
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_273  is-center   is-leaf">
                                                                                                    <div className="cell"><img src= {item.image_url} /></div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_274  is-center   is-leaf is-sortable">
                                                                                                    <div className="cell">{item.provider_name}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_275  is-center   is-leaf is-sortable">
                                                                                                    <div className="cell">{item.game_name}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_276  is-center   is-leaf is-sortable">
                                                                                                    <div className="cell">{item.game_code}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_277  is-center   is-leaf">
                                                                                                    <div className="cell">{item.game_type}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_278  is-center   is-leaf is-sortable">
                                                                                                    <div className={`cell ${getStatusClassName(item.status)}`} style={{ width: "50%", borderRadius: "5px" }} >{getCheckStatus(item.status)}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_279  is-center descending  is-leaf is-sortable">
                                                                                                    <div className="cell">{dateFormation(item.created_at, 3)}<br />{dateFormation(item.created_at, 10)}</div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_280  is-center   is-leaf is-sortable">
                                                                                                    <label className="switch">
                                                                                                        <input type="checkbox" checked = {flag} value={item.id} onChange={onCheckGame} readOnly/>
                                                                                                        <span className="slider round"></span>
                                                                                                    </label>
                                                                                                </td>
                                                                                                    <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                                            </tr>
                                                                                        )
                                                                                    }

                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    
                                                                </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>

                            </div>
                        </div>
            </section>
        </Fragment>

    );
}

export default OddGameDashboard;