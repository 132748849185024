import React, {useEffect, useState} from "react";
import { history } from "../../utils/history";
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";
import { dateFormation, getHeaderClassName, toastr } from "../../utils";
import { getHeaderInfoAction } from "../../redux/action/agentAction";
import isEmpty from "../../validation/is-empty";
import { DoLogout } from "../../redux/action/userAction";
import { socket } from "../../commons/cores/socket";
import { SET_POINT_TRANSACTION_MOVE } from "../../redux/action/types";

const Header = () => {
  const dispatch = useDispatch();
  const [ logoutActive, setLogoutActive ] = useState(false);
  const [ sidebarActive, setSidebarActive ] = useState(false);
  const [ logoutModal, setLogoutModal ] = useState(false);

  const onLogoutModal = () =>{
    setLogoutModal(!logoutModal)    
  }

  const delLogoutModal = () =>{
    setLogoutModal(!logoutModal)
    onLogoutActive()
  }

  const onLogoutActive = () => {
    setLogoutActive(!logoutActive);
  }
  const onSidebarActive = () => {
    setSidebarActive(!sidebarActive);
  }

  const onSubmit = () => {   
    // DoLogout()
    const param = {
      loginid: loginid  
    }
    dispatch(DoLogout(param))    
    toastr.success("로그아웃되였습니다.")     
    history.navigate("/")               
    
  } 
  const socketData = useSelector((state) => state.user.socketData);
  const { userCount } = socketData; 
  const list = useSelector((state) => state.user.userData);
  const headerData = useSelector((state) => state.agent.headerInfoList);  
  const { join_req_num, point_add_num, point_del_money, point_del_num } = headerData;
  const { loginid, id } = list; 
  const token = localStorage.getItem("jwtToken")
  const startDate = dateFormation(new Date(),11);
  const headerInfo = () => {
    const param = {
      loginid : loginid,
      startDate: startDate
    }
    dispatch(getHeaderInfoAction(param))
  }

  const addNumTran = () => {
    const param = {
        checkType: ["1"],
        startDate: startDate
      }
      dispatch({
        type: SET_POINT_TRANSACTION_MOVE,
        payload: param
    });
    history.navigate("/PointTranHistory")
  }

  const delNumTran = () => {
    const param = {
        checkType: ["2"],
        startDate: startDate
      }
      dispatch({
        type: SET_POINT_TRANSACTION_MOVE,
        payload: param
    });
    history.navigate("/PointTranHistory")
  }

  const onJoinRequest = () => {    
    history.navigate("/joinConsentRequest")
  }
  

  
  useEffect(() =>{
    if (!isEmpty(loginid)) {
      socket.emit("me", {token,id})
      headerInfo();      
    }
  }, [loginid] )
  
  
  return (
    <div data-v-17ef9dce="" data-v-c97c2a3e="" id="header"  className= {`app-header ${sidebarActive && "active"}`}>
      <div className = "left-main-navbar">
      <div data-v-17ef9dce="" className="navbar-header">
        <button data-v-17ef9dce="" type="button" className="navbar-mobile-toggler " onClick={onSidebarActive}>
          <span data-v-17ef9dce="" className="icon-bar"></span>
          <span data-v-17ef9dce="" className="icon-bar"></span>
          <span data-v-17ef9dce="" className="icon-bar"></span>
        </button>
        <div data-v-17ef9dce=""  className="navbar-brand router-link-active">
          <span data-v-17ef9dce="" className="fs-24px">
            <b data-v-17ef9dce="">TOP GAME </b> Admin
          </span>
        </div>
                
      </div>
      {
         list.agent_type === 3 &&
        <div className = "navbar-body">
          <div className = "navbar-body-first">
            <div className = "navbar-body-title" onClick={addNumTran}>
              <span>금일포인트지급</span> 
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_add_num)}`}>{!isEmpty(headerData[0]) && headerData[0].point_add_num}</span>
                <span className="navbar-body-title-between">건</span>  
              </span> 
              {/* {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_add_num)}`} */}
            </div>            
            <div className = "navbar-body-title" onClick={delNumTran}>
              <span>금일포인트회수</span>  
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_del_num)}`}>{!isEmpty(headerData[0]) && headerData[0].point_del_num}</span>
                <span className="navbar-body-title-between">건</span>  
              </span> 
            </div>
          </div>

          <div className = "navbar-body-first">
            <div className = "navbar-body-title" onClick={addNumTran}>
              <span>금일포인트지급액</span> 
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_add_money)}`}>{Number(!isEmpty(headerData[0]) && headerData[0].point_add_money).toLocaleString()}</span>
                <span className="navbar-body-title-between">(₩)</span>  
              </span> 
              {/* {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_add_num)}`} */}
            </div>            
            <div className = "navbar-body-title" onClick={delNumTran}>
              <span>금일포인트회수액</span>  
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].point_del_money)}`}>{Number(!isEmpty(headerData[0]) && headerData[0].point_del_money).toLocaleString() }</span>
                <span className="navbar-body-title-between">(₩)</span>  
              </span> 
            </div>
          </div>

          <div className = "navbar-body-second">
            <div className = "navbar-body-title" onClick={onJoinRequest}>
              <span>업체등록신청</span> 
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(!isEmpty(headerData[0]) && headerData[0].join_req_num)}`}>{ !isEmpty(headerData[0]) && headerData[0].join_req_num}</span>
                <span className="navbar-body-title-between">건</span>  
              </span>
            </div>
            <div className = "navbar-body-title">
              <span>현재접속자</span>  
              <span className="navbar-body-title-between">
                <span className = {` ${getHeaderClassName(userCount)}`}>{userCount}</span>
                <span className="navbar-body-title-between">명</span>  
              </span>
            </div>            
            <div className = "navbar-body-title">
              <span>
                
              </span>
            </div>
          </div>
        </div>
      }
      </div>
      
      <div data-v-17ef9dce="" className="navbar-nav">
        <div data-v-17ef9dce="" title="업체 포인트" className="input-group w-auto me-1">
          <span data-v-17ef9dce="" className="input-group-text">KRW</span>
          <span data-v-17ef9dce="" className="form-control input-background">{Number(list.money).toLocaleString()}</span>
          <span data-v-17ef9dce="" className="input-group-text">₩</span>
        </div>        
        <div data-v-17ef9dce="" className="nav-item b-nav-dropdown dropdown navbar-item navbar-user dropdown el-dropdown">
          <div data-v-17ef9dce="" role="button" aria-haspopup="list" aria-expanded="false"  target="_self" id="__BVID__21__BV_toggle_" className="nav-link dropdown-toggle navbar-link dropdown-toggle d-flex align-items-center dropdown-toggle-no-caret el-dropdown-selfdefine" aria-controls="dropdown-menu-6280" tabIndex="0" >
            <img data-v-17ef9dce="" src="./assets/img/1.png" alt="프로필" style={{width:"150px"}}/>
              <span data-v-17ef9dce="" className="dropdownBtn d-none d-md-inline" style={{fontSize: "13px"}} onClick = {onLogoutActive}>{list.username}({list.loginid})</span>
              <b data-v-17ef9dce="" className="caret" onClick = {onLogoutActive}></b>
          </div>
          <div style = {{display: logoutActive?"block":"none"}}>
            <div className = "modal-backgroud" onClick={onLogoutActive}></div>
            <ul data-v-17ef9dce="" className="el-dropdown-menu el-popper router-link-exact-active router-link-active" style={{display: logoutActive? "block": "none", backgroundColor: "rgb(44, 50, 57)", border: "none", marginTop: "45px", marginLeft: "75px"}}>
              <NavLink to="/setting">
                <li data-v-17ef9dce="" tabIndex="-1" className="el-dropdown-menu__item" style={{color: "white", fontSize: "12px"}}> 설정 및 정보</li>
              </NavLink>
              <div data-v-17ef9dce="" className="dropdown-item text-center border-top" style={{color: "rgb(255, 53, 2)", fontWeight: "bold", cursor: "pointer"}} onClick={onLogoutModal}> LOGOUT </div>
            </ul>
          </div>
        </div>
      </div>
      {/* ----------------로그아웃 확인----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: logoutModal ? "block" : "none", zIndex: "11111" }}>
                <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ marginTop: "15vh" }}>
                    <div className="el-dialog__header">
                        <span className="el-dialog__title">로그아웃 하시겠습니까?</span>
                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                            <i className="fa fa-close" style={{ color: "white" }} onClick={delLogoutModal}></i>
                        </button>
                    </div>
                    <div className="el-dialog__footer">
                        <div data-v-12fb228d="" className="dialog-footer">
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onSubmit}>
                                <span> 확인 </span>
                            </button>
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={delLogoutModal}>
                                <span> 취소 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  );
};

export default Header;
