import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { userFigureAction } from '../../../redux/action/figureAction';
import { dateFormation, getAgentLevel, getAgentLevelClass, toastr } from "../../../utils";
import axios from "axios";
import { api_url } from "../../../utils/keys";
import UserFigureTreeNode from "./UserFigureTreeNode";
import { getAgentAction } from "../../../redux/action/userAction";
import { SET_LOADING } from "../../../redux/action/types";

import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';

function UsersFigureDashboard() {

    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const agentList = useSelector((state) => state.figureManage.getAgentList);    
    // const userFigList = useSelector((state) => state.figureManage.userFigureList);
    const [userFigList, setUserFigList] = useState([])
    
    const [Input, setInput] = useState({
        startDate: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 11),
        endDate: dateFormation(Date.now(), 11),
        agentId: "",
        username: ""
    });
    const { loginid } = list;

    

    const { username, startDate, endDate, agentId } = Input
    
    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }

    

    const getCalcUserFigure = (loginid, isSearch) => {

        const params = {
            loginid: loginid,
            isSearch: isSearch,
            startDate: startDate,
            endDate: endDate,
            username: username
        }
        // dispatch(userFigureAction( params ));
        return new Promise(resolve => {
            dispatch({
                type: SET_LOADING,
                payload: true
              })
            axios.post(api_url + "/api/calc/calc_user/userList", params)
                .then(res => {
                    dispatch({
                        type: SET_LOADING,
                        payload: false
                      })
                    if (res.data.success) {
                        resolve(res.data.data);
                    }
                    else {
                        toastr.warning(res.data.message)
                    }
                }).catch((err) => {
                    dispatch({
                        type: SET_LOADING,
                        payload: false
                      })
                    console.log("err", err)
                });
        })
    }

    const get_figureAgentList = () => {
        dispatch(getAgentAction());
    }

    const searchUserFig = (e) => {
        e.preventDefault();
        
        if(!isEmpty(loginid)){
        
            getCalcUserFigure(agentId, true)
            .then(res => {
                setUserFigList(res);
            });
        }
        
    }
    useEffect(() => {
        if (!isEmpty(loginid)) {           
            get_figureAgentList()
            getCalcUserFigure(loginid, true)
                .then(res => {
                    setUserFigList(res);
                });
        }
    }, [loginid]);

    const onOk = (data, datastr) => {
        console.log('data, datastr: ', data, datastr);
    }

    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-1dfa7bef="" data-v-e072c2c8="" id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol data-v-1dfa7bef="" className="breadcrumb float-xl-end">
                            
                            <li data-v-1dfa7bef="" className="breadcrumb-item"><a data-v-1dfa7bef="">정산관리</a></li>
                            <li data-v-1dfa7bef="" className="breadcrumb-item active">유저별 정산</li>
                        </ol>
                        <h1 data-v-1dfa7bef="" className="page-header">유저별 정산</h1>
                        <div data-v-1dfa7bef="" className="row">
                            <div data-v-1dfa7bef="" className="col-12">
                                <div data-v-1dfa7bef="" className="panel panel-inverse">
                                    <div data-v-1dfa7bef="" className="panel-heading">
                                        <h4 data-v-1dfa7bef="" className="panel-title">검색</h4>
                                    </div>
                                    <div data-v-1dfa7bef="" className="panel-body">
                                        <form data-v-1dfa7bef="" className="form-horizontal form-bordered">
                                            <div data-v-1dfa7bef="" className="form-group row"><label data-v-1dfa7bef="" className="col-md-4 col-form-label">상위 업체</label>
                                                <div data-v-1dfa7bef="" className="col-md-8">
                                                    <div data-v-1dfa7bef="" className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div data-v-1dfa7bef="" className="vue-treeselect__control">
                                                            <div data-v-1dfa7bef="" className="vue-treeselect__input-container">
                                                                <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                        name= "agentId" value={agentId} onChange={onChange}
                                                                        style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                    <option value=""  className="" > 선택해주세요 </option>
                                                                    {
                                                                        agentList.map((item, index) => {
                                                                            return (
                                                                                <option value={item.loginid} className="" key={index}> {item.loginid} </option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div data-v-1dfa7bef="" className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                    <div data-v-1dfa7bef="" className="mt-3 mb-3 mb-md-0">
                                                        {/* <div data-v-1dfa7bef="" className="form-check form-check-inline">
                                                            <input data-v-1dfa7bef="" id="low_agent_allow" type="checkbox" className="form-check-input" />
                                                            <label data-v-1dfa7bef="" htmlFor="low_agent_allow" className="form-check-label">하부 업체 포함 검색 여부</label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-1dfa7bef="" className="form-group row period"><label data-v-1dfa7bef="" className="col-md-4 col-form-label">기간(시작 ~ 종료)</label>
                                                <div data-v-1dfa7bef="" className="col-md-4">
                                                    <div data-v-1dfa7bef="" className="form-floating mb-3 mb-md-0">
                                                        <div data-v-1dfa7bef="" className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                            <input type="text" autoComplete="off" name="" value={startDate}  placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                            {
                                                                !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian/>
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix">
                                                                <span className="el-input__suffix-inner">
                                                                    <i className="el-input__icon"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-v-1dfa7bef="" className="col-md-4">
                                                    <div data-v-1dfa7bef="" className="form-floating mb-3 mb-md-0">
                                                        <div data-v-1dfa7bef="" className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                            <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                            {
                                                                !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian/>
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix">
                                                                <span className="el-input__suffix-inner">
                                                                    <i className="el-input__icon"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-1dfa7bef="" className="form-group row"><label data-v-1dfa7bef="" className="col-md-4 col-form-label">이름</label>
                                                <div data-v-1dfa7bef="" className="col-md-8">
                                                    <div data-v-1dfa7bef="" className="form-floating mb-3 mb-md-0"></div>
                                                    <input data-v-1dfa7bef="" type="text" name="username" value={username} onChange={onChange} placeholder="이름" className="form-control fs-13px h-45px" />                                                    
                                                </div>
                                            </div>
                                            <div data-v-1dfa7bef="" className="row">
                                                <div data-v-1dfa7bef="" className="col-md-12">
                                                    <div data-v-1dfa7bef="" className="btn-group" style = {{width:"150px", marginRight:"15px"}}>
                                                        <button data-v-1dfa7bef="" type="button" className="btn btn-success btn-lg" onClick={searchUserFig}> 검색 </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-1dfa7bef="" className="row">
                            <div data-v-1dfa7bef="" className="col-12">
                                <div data-v-1dfa7bef="" className="panel panel-inverse">
                                    <div data-v-1dfa7bef="" className="panel-heading">
                                        <h4 data-v-1dfa7bef="" className="panel-title">유저별 통계 </h4>
                                    </div>
                                    <div data-v-1dfa7bef="" className="panel-body">
                                        <div data-v-1dfa7bef="" className="vgt-wrap">
                                            <div data-v-1dfa7bef="" className="vgt-inner-wrap">
                                                <div data-v-1dfa7bef="" className="vgt-fixed-header"></div>
                                                <div data-v-1dfa7bef="" className="vgt-responsive agentList">
                                                    <div data-v-1dfa7bef="" className="el-table el-table--fit el-table--enable-row-hover" style={{ width: "100%" }}>
                                                        <div className="hidden-columns">
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                            <div data-v-1dfa7bef=""></div>
                                                        </div>                                                        
                                                            
                                                        <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                            <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{ minWidth: "1407px" }}>
                                                                
                                                                    {/* <col name="el-table_40_column_298" width="248" />
                                                                    <col name="el-table_40_column_299" width="248" />
                                                                    <col name="el-table_40_column_300" width="248" />
                                                                    <col name="el-table_40_column_301" width="165" />
                                                                    <col name="el-table_40_column_302" width="165" />
                                                                    <col name="el-table_40_column_303" width="165" />
                                                                    <col name="el-table_40_column_304" width="165" /> */}
                                                                <thead className="has-gutter">
                                                                    <tr className="">
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_298  is-center is-leaf is-sortable" style={{width:"20%"}}>
                                                                            <div className="cell" >유저아이디</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_299  is-center   is-leaf is-sortable">
                                                                            <div className="cell">회원등급</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_300  is-center   is-leaf">
                                                                            <div className="cell">이름</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_301  is-center   is-leaf is-sortable">
                                                                            <div className="cell">베팅금 </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_302  is-center   is-leaf is-sortable">
                                                                            <div className="cell">당첨금</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_303  is-center   is-leaf is-sortable">
                                                                            <div className="cell">수익금</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_304  is-center   is-leaf is-sortable">
                                                                            <div className="cell">당첨후 금액</div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>
                                                                    {
                                                                        userFigList.map((item, index) => {
                                                                            return (
                                                                                <UserFigureTreeNode
                                                                                    data={item}
                                                                                    getCalcUserFigure={getCalcUserFigure}
                                                                                    level={0}
                                                                                    key={item.loginid}
                                                                                />
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                            <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}><span className="el-table__empty-text"></span></div>
                                                        </div>



                                                        <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-v-1dfa7bef="" id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </section>
        </Fragment>
    );

}

export default UsersFigureDashboard;