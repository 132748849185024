import { useState } from "react";

function Dropdown(props){

    const { onClickDelete, loginId } = props
    const [ active, setActive ] = useState(false);

    const onActive = () => {
        setActive(!active);
    }

    const onClickMenu = () => {
        onActive();
    }
    const onDeleteClick = ()=> {
        onClickDelete(loginId)
        onActive()
    }
    return( 
        <div className="cell w-50 bg-primary-900 cursor-pointer position-relative" style={{overflow: "unset", borderRadius:"7px"}}>
            <div onClick={onActive} >관리<i className="fa fa-angle-down m-2"></i></div>
            <div className="custom-select" style={{ display: active ? "block" : "none" }}>
                <span className={`caret-wrapper-status bg-blue w-100`} onClick={onClickMenu}>
                    수정
                </span>
                <span className={`caret-wrapper-status bg-red w-100`}  onClick={onDeleteClick} >
                    삭제
                </span>
            </div>
        </div>
);

}

export default Dropdown;