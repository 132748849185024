import { api_url } from "../../utils/keys";
import axios from "axios";
import {  SET_BONUS_HIST, SET_GETAGENTLIST, SET_GETGAMELIST, SET_LOADING, SET_LOGIN_HIST, SET_USERGAMELIST, SET_USERLIST, SET_USERTRANSLIST } from "./types";
import { toastr } from "../../utils";

export const getUserListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/user/userlist", params)
    .then(res => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        if(res.data.success){
            dispatch({
                type: SET_USERLIST,
                payload: res.data.users
            });
            setPageInfo({
                perPage: params.perPage,
                curPage: res.data.users.curPage, 
                totalPage: res.data.users.totalPage 
            });
            setCurPage(params.page);
        }
        else{
        toastr.warning(res.data.message)
        }
    }).catch((err) => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        console.log("err", err)
    });
}

//-----------로그인이력 얻는 함수-------//

export const getLoginHistAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/user/login_hist", params)
    .then(res => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        if(res.data.success){
            dispatch({
                type: SET_LOGIN_HIST,
                payload: res.data.users
            });
            setPageInfo({
                perPage: params.perPage,
                curPage: res.data.users.curPage, 
                totalPage: res.data.users.totalPage 
            });
            setCurPage(params.page);
        }
        else{
        toastr.warning(res.data.message)
        }
    }).catch((err) => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        console.log("err", err)
    });
}


//-----------보너스 콜 내역 얻는 함수-------//

export const getBonusHistAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/user/bonus_hist", params)
    .then(res => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        if(res.data.success){
            dispatch({
                type: SET_BONUS_HIST,
                payload: res.data.users
            });
            setPageInfo({
                perPage: params.perPage,
                curPage: res.data.users.curPage, 
                totalPage: res.data.users.totalPage 
            });
            setCurPage(params.page);
        }
        else{
        toastr.warning(res.data.message)
        }
    }).catch((err) => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        console.log("err", err)
    });
}


//---------------유저트랜잭션 구하는 함수----------------//
export const getUserTransListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/user/transaction_hist", params)
    .then(res => {
        setTimeout(() => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })            
        }, 500);
        if(res.data.success){
            dispatch({
                type: SET_USERTRANSLIST,
                payload: res.data.data
            });
            setPageInfo({
                perPage: params.perPage,
                curPage: res.data.data.curPage, 
                totalPage: res.data.data.totalPage 
            });
            setCurPage(params.page);
        }
        else{
        toastr.warning(res.data.message)
        }
    }).catch((err) => {
        setTimeout(() => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })            
        }, 500);
        console.log("err", err)
    });
}

//-------------------게임베팅내역 얻는 함수--------------//
export const getGameListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/user/game_hist", params)
    .then(res => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        if(res.data.success){
            dispatch({
                type: SET_USERGAMELIST,
                payload: res.data.data
            });
            setPageInfo({
                perPage: params.perPage,
                curPage: res.data.data.curPage, 
                totalPage: res.data.data.totalPage 
            });
            setCurPage(params.page);
        }
        else{
        toastr.warning(res.data.message)
        }
    }).catch((err) => {
        dispatch({
            type: SET_LOADING,
            payload: false
          })
        console.log("err", err)
    });
}


//---------------------트랜잭션내역에서 업체목록 얻는 함수---------------------//
export const getAgentListAction = () => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/treelist/agentlist")
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_GETAGENTLIST,
                    payload: res.data.data
                });
            }
                
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}


//---------------게임사목록 얻는 함수--------------------//
export const get_gameListAction = () => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/game/providerlist")
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_GETGAMELIST,
                    payload: res.data.data.recordset
                });
            }
                
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}




//------------------------유저삭제하는 함수---------------------//
export const deleteUserAction = (params, onDeleteModal) => {
    return new Promise(resolve => { 
        axios.post(api_url + "/api/user/userlist/delete", params)
        .then(res => {
            if (res.data.success) {
                toastr.success(res.data.message);
                onDeleteModal();
                resolve();
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
}



//---------------사용상태 바꾸는 함수--------------------//
export const changeAction = (params) => {
    return new Promise(resolve => {
        axios.post(api_url + "/api/game/usechange", params)
        .then(res => {
            if (res.data.success) {
                toastr.success(res.data.message);
                resolve();            
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
        
}

// export const getAgentListAction = (params, setPageInfo, setCurPage) => dispatch => {
//     axios.post(api_url + "/api/user/agentList/userList", params)
//         .then(res => {
//             if (res.data.success) {
//                 dispatch({
//                     type: SET_AGENTLIST,
//                     payload: res.data.data.data
//                 });
//                 setPageInfo({
//                     perPage: params.perPage,
//                     curPage: res.data.data.curPage,
//                     totalPage: res.data.data.totalPage
//                 });
//                 setCurPage(params.page);
//             }
//             else {
//                 toastr.warning(res.data.message)
//             }
//         }).catch((err) => {
//             console.log("err", err)
//         });
// }