import React from "react";
import ApiGuideDashboard from "../../components/common/apiGuide/ApiGuide";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";

const ApiGuide = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <ApiGuideDashboard/>
      <Sidebar pageValue = "API"/>
    </div>
  )
}

export default ApiGuide;