import React from "react";
import { Navigate } from "react-router-dom";
// import { useSelector } from 'react-redux'
function Protected({ children }) {
  // const Authenticated = useSelector((state) => state.user.Authenticated)
  if (!localStorage.jwtToken) {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default Protected;
