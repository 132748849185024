import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getAgentLevel, getAgentLevelClass, getStatus, getStatusClassName, getUserStatus, getUserStatusClassName, toastr } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import Dropdown from "../Dropdown";
import { getAgentListAction, getUserListAction, deleteUserAction, getUserTransListAction, getLoginHistAction } from "../../../redux/action/userManageAction"
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { history } from "../../../utils/history";
import { SET_TRANSACTION_MOVE } from "../../../redux/action/types";

const header = [
    {
        name: "아이디",
        className: "el-table_28_column_203",
        property: "player_id"
    },
    {
        name: "닉네임",
        className: "el-table_28_column_205",
        property: "username"
    },
    {
        name: "소속업체",
        className: "el-table_28_column_204",
        property: "parent_loginid"
    },
    {
        name: "IP",
        className: "el-table_28_column_206",
        property: "ipaddr"
    },   
    {
        name: "로그인시간",
        className: "el-table_28_column_208",
        property: "starttime"
    },
    {
        name: "로그아웃시간",
        className: "el-table_28_column_209",
        property: "endtime"
    }
]
function LoginHistoryDashboard() {

    const dispatch = useDispatch()
    const list = useSelector((state) => state.user.userData)
    const loginHistory = useSelector((state) => state.userManage.loginHist);
    const agentList = useSelector((state) => state.userManage.getAgentList);
    const { loginid } = list;
    const totalCount = !isEmpty(loginHistory)? loginHistory.totalCount : 0
    const data =!isEmpty(loginHistory)? loginHistory.data : []
   
    const defaultSort = {
        player_id: 0,
        username : 0,
        parent_loginid: 0,
        ipaddr: 0,
        starttime: 0,
        endtime: 0
    }
    const [sortClass, setSortClass] = useState({
        player_id: 0,
        username : 0,
        parent_loginid: 0,
        ipaddr: 0,
        starttime: 0,
        endtime: 0
    })
    
    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getLoginHist(1, pageInfo.perPage)
        }
      }

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);

    const [Input, setInput] = useState({
        name: "",
        userId: "",
        startDate: "",
        endDate: "",
        agentId: ""
    })

    const { name, userId, startDate, endDate, agentId } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }
  


    //----------------유저로그인이력 얻는 함수-------------//
    const getLoginHist = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                name: name,
                userId: userId,
                loginId: loginid,
                startDate: startDate,
                endDate: endDate,
                agentId: agentId
            }
        }
        dispatch(getLoginHistAction(params, setPageInfo, setCurPage))
    }

    const get_userAgentList = () => {
        dispatch(getAgentListAction());
    }
    

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getLoginHist(1, pageInfo.perPage);
            get_userAgentList()
        }
    }, [loginid, pageInfo.perPage])

    return (
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="" id="content" className="app-content">
                <div className = "main_app_content">
                    <ol className="breadcrumb float-xl-end">
                        
                        <li className="breadcrumb-item"><a>유저 관리</a></li>
                        <li className="breadcrumb-item active">로그인 내역</li>
                    </ol>
                    <h1 className="page-header">로그인 내역</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">검색</h4>
                                </div>
                                <div className="panel-body">
                                    <form className="form-horizontal form-bordered">
                                        <div className="form-group row">
                                            <label
                                                className="col-md-4 col-form-label">상위 업체</label>
                                            <div className="col-md-8">
                                                <div className="
                                                    vtreeselect-dark
                                                    vue-treeselect
                                                    vue-treeselect--single
                                                    vue-treeselect--searchable
                                                    vue-treeselect--has-value
                                                    vue-treeselect--open-below">
                                                    <div className="vue-treeselect__control">
                                                        <div className="vue-treeselect__input-container">
                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                name="agentId" value={agentId} onChange={onChange}
                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                <option value="" className="d-none">선택하세요</option>
                                                                {
                                                                    agentList.map((item, index) => {
                                                                        return (
                                                                            <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row period">
                                            <label className="col-md-4 col-form-label">가입기간(시작 ~ 종료)</label>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">로그인 아이디</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="로그인 아이디" 
                                                            className="form-control fs-13px h-45px" 
                                                            value={userId} name="userId" 
                                                            onChange={onChange} onKeyDown={onEnter}/>
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">로그인 아이디</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-4 col-form-label">이름</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="이름" 
                                                            className="form-control fs-13px h-45px" 
                                                            value={name} name="name" 
                                                            onChange={onChange} onKeyDown={onEnter}/>
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">이름</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="btn-group" style={{ width: "150px", marginRight:"15px" }}>
                                                    <button type="button" className="btn btn-success btn-lg" onClick={() => getLoginHist(1, pageInfo.perPage)}> 검색 </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">로그인 내역 ( 총 {totalCount} 개) </h4>
                                </div>

                                <div className="panel-body">
                                    <div className="vgt-wrap">
                                        <div className="vgt-inner-wrap">
                                            <div className="vgt-fixed-header"></div>
                                            <div className="vgt-responsive agentList">
                                                <div className="el-table el-table--fit el-table--enable-row-hover"
                                                    style={{ width: "100%" }}>
                                                    
                                                    <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                        <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "1384px" }}>

                                                            

                                                            <thead className="has-gutter">
                                                                <tr className="">
                                                                    <th colSpan="1" rowSpan="1" className="el-table_28_column_202  is-center   is-leaf" style={{width:"5%"}}>
                                                                        <div className="cell">번호</div>
                                                                    </th>
                                                                    {
                                                                        header.map((item, index) => {
                                                                            return (
                                                                                <th colSpan="1" rowSpan="1" className={`${item.className}  is-center   is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                    <div className="cell" onClick={() => getLoginHist(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                        <span className="caret-wrapper">
                                                                                            <i className="sort-caret ascending"></i>
                                                                                            <i className="sort-caret descending"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            )
                                                                        })
                                                                    }                                                                    
                                                                    <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                     data.map((item, index) => {
                                                                        return (
                                                                            <tr className="" key={index}>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_202  is-center   is-leaf">
                                                                                    <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_203  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.player_id}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>                                                                                
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_205  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.username}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_204  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.parent_loginid}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_28_column_206  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down" >
                                                                                            {item.ipaddr}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1"
                                                                                    className="el-table_28_column_208  is-center descending  is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {dateFormation(item.starttime, 3)}<br />{dateFormation(item.starttime, 10)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1"
                                                                                    className="el-table_28_column_209  is-center descending  is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {!isEmpty(item.endtime)&& dateFormation(item.endtime, 3)}<br />{!isEmpty(item.endtime)&& dateFormation(item.endtime, 10)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>                                                                                
                                                                                <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                        <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                            <span className="el-table__empty-text" style={{display:isEmpty(data)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                        </div>
                                                    </div>
                                                    <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                </div>
                                            </div>
                                            <Pagenation
                                                getList={getLoginHist}
                                                pageInfo={pageInfo}
                                                setPageInfo={setPageInfo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
            </div>            
        </section>
    );

}

export default LoginHistoryDashboard;