import { SET_CALC_USER_FIGURE, SET_GET_AGENT } from "../action/types";



const initialState = {
    userFigureList: [],
    getAgentList: []    
  };
  
  export default function figure(state = initialState, action) {
    switch (action.type) {
        case SET_CALC_USER_FIGURE: {
            return {
                ...state,
                userFigureList: action.payload
              };
        }
        case SET_GET_AGENT: {
          return {
              ...state,
              getAgentList: action.payload
            };
        }                   
        default:
            return state;
    }
  }