import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { SET_TRANSACTION_MOVE } from "../../../redux/action/types";
import { dateFormation } from "../../../utils";
import { history } from "../../../utils/history";
import isEmpty from "../../../validation/is-empty";


const header = [
    {
        name: "유저",
        className: "el-table_31_column_233",
        property: "loginid"
    },
    {
        name: "소속",
        className: "el-table_31_column_235",
        property: "id"
    },
    {
        name: "게임사",
        className: "el-table_31_column_236",
        property: "username"
    },
    {
        name: "게임이름",
        className: "el-table_31_column_237",
        property: "agent_type"
    },
    {
        name: "심볼",
        className: "el-table_31_column_238",
        property: "status"
    },
    {
        name: "베팅액",
        className: "el-table_31_column_239",
        property: "api_endpoint"
    },
    {
        name: "당첨금액",
        className: "el-table_31_column_240",
        property: "created_at"
    },
    {
        name: "수익",
        className: "el-table_31_column_241",
        property: "created_at"
    },
    {
        name: "시작시간",
        className: "el-table_31_column_242",
        property: "created_at"
    },
    {
        name: "콜당첨액",
        className: "el-table_31_column_243",
        property: "created_at"
    },
    {
        name: "콜상태",
        className: "el-table_31_column_244",
        property: "created_at"
    }
]

const DoingGameDashboard = () => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData)
    const smartSocketData = useSelector((state) => state.user.smartSocketData);
    const {userData} = !isEmpty(smartSocketData) && !isEmpty(smartSocketData.userData) && smartSocketData.userData;
    const success = !isEmpty(smartSocketData) && !isEmpty(smartSocketData.success) && smartSocketData.success;  
    
    const { loginid, path } = list;
    
    const [Input, setInput] = useState({
        username: "",
        parent_name: "",
        providerName: "",
        gameName: "",
        symbol: ""
    })

    const { username, parent_name, providerName, gameName, symbol } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    //--------------트랜잭션 이행함수----------------//
    const getUserTrans = ( loginId, startDate, agentId) => {
        

        const params = {            
                userId: loginId,
                username:"",
                startDate: new Date(startDate),
                endDate: "",
                agentId: agentId           
        }
        dispatch({
            type: SET_TRANSACTION_MOVE,
            payload: params
        });
        history.navigate("/TransactionHistory")
    } 
  
  return (
    <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
        <div data-v-e072c2c8="">
            <div id="content" className="app-content">
                <div className = "main_app_content">
                    <ol className="breadcrumb float-xl-end">
                        
                        <li className="breadcrumb-item"><a>유저 관리</a></li>
                        <li className="breadcrumb-item active">진행중 게임</li>
                    </ol>
                    <h1 className="page-header">진행중 게임</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">필터</h4>
                                </div>
                                <div className="panel-body filter">
                                    <form className="form-horizontal form-bordered">
                                        <div className="form-group row">
                                            <div className="col-6"
                                                style={{borderRight: "solid 1px var(--app-component-border-color)"}}>
                                                <div className="row"><label className="col-md-4 col-form-label">유저</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="이름" name = "username" value = {username} onChange={onChange} className="form-control fs-13px h-45px"/>
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">유저</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row"><label className="col-md-4 col-form-label">소속</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="이름" name = "parent_name" value = {parent_name} onChange={onChange} className="form-control fs-13px h-45px"/>
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">소속</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-6"
                                                style={{borderRight: "solid 1px var(--app-component-border-color)"}}>
                                                <div className="row"><label className="col-md-4 col-form-label">게임사</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="이름" name = "providerName" value = {providerName} onChange={onChange} className="form-control fs-13px h-45px"/>
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임사</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row"><label className="col-md-4 col-form-label">게임 이름</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="이름" name = "gameName" value = {gameName} onChange={onChange} className="form-control fs-13px h-45px"/>
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임이름</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-6"
                                                style={{borderRight: "solid 1px var(--app-component-border-color)"}}>
                                                <div className="row"><label className="col-md-4 col-form-label">심볼</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input type="text" placeholder="이름" name = "symbol" value = {symbol} onChange={onChange} className="form-control fs-13px h-45px"/>
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">심볼</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">진행중 게임 </h4>
                                </div>
                                <div role="alert" aria-live="polite" aria-atomic="true"
                                    className="alert rounded-0 alert-black mb-0">
                                    <div className="d-flex"><i className="fa fa-check fa-2x me-1"></i>
                                        <div className="mb-0 ps-2"> 10초간격으로 데이터가 자동으로 업데이트됩니다. </div>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <div className="vgt-wrap">
                                        <div className="vgt-inner-wrap">
                                            <div className="vgt-fixed-header"></div>
                                            <div className="vgt-responsive agentList">
                                                <div className="el-table el-table--fit el-table--scrollable-x el-table--enable-row-hover el-table--enable-row-transition"
                                                    style={{width: "100%"}}>                                                    
                                                    <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                        <table cellSpacing="0" cellPadding="0" border="0"
                                                            className="el-table__header" style={{minWidth: "1300px"}}>                                                            
                                                                
                                                            
                                                            <thead className="has-gutter">
                                                                <tr className="">
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_232 is-center is-leaf" style={{width:"7%"}}>
                                                                        <div className="cell">번호</div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_233 is-center is-leaf is-sortable">
                                                                        <div className="cell">유저<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_234 is-center is-leaf is-sortable">
                                                                        <div className="cell">소속<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_235 is-center is-leaf is-sortable">
                                                                        <div className="cell">게임사<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_23 is-center is-leaf is-sortable">
                                                                        <div className="cell">게임 이름<span
                                                                                className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_237 is-center is-leaf is-sortable">
                                                                        <div className="cell">심볼<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_238 is-center is-leaf is-sortable">
                                                                        <div className="cell">베팅액<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_239 is-center is-leaf is-sortable">
                                                                        <div className="cell">당첨금액<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_240  is-center   is-leaf is-sortable">
                                                                        <div className="cell">수익<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_241  is-center descending  is-leaf is-sortable">
                                                                        <div className="cell">시작시간<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_242  is-center   is-leaf is-sortable">
                                                                        <div className="cell">콜당첨액<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_243  is-center   is-leaf is-sortable">
                                                                        <div className="cell">콜상태<span className="caret-wrapper"></span>
                                                                        </div>
                                                                    </th>
                                                                    <th colSpan="1" rowSpan="1"
                                                                        className="el-table_31_column_244  is-center option_btn  is-leaf">
                                                                        <div className="cell">기능</div>
                                                                    </th>
                                                                    <th className="gutter" style={{width: "0px", display: "none"}}>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !isEmpty(userData) &&
                                                                    userData.filter(item => (item.username.indexOf(username) > -1) && (item.gameName.indexOf(gameName) > -1) && 
                                                                                            (item.providerName.indexOf(providerName) > -1) && (item.symbol.indexOf(symbol) > -1) && 
                                                                                            (item.parentLoginId.indexOf(parent_name))  > -1 )
                                                                                            // && (item.path.indexOf(path)) > -1
                                                                    .map((item, index)=>{
                                                                        return(
                                                                            <tr className="" key={index}>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_232  is-center   is-leaf" >
                                                                                    <div className="cell" >{index + 1}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_233  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.username}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_234  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.parentLoginId}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_235  is-center   is-leaf is-sortable">
                                                                                    <div className="cell">
                                                                                        <span className="caret-wrapper-down">
                                                                                            {item.providerName}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_236  is-center  is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >
                                                                                            {item.gameName}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_237  is-center  is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >
                                                                                            {item.symbol}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_238  is-center  is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" style={{alignItems:"end", width:"90%"}}>
                                                                                            {Number(item.betMoney).toLocaleString()}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_239  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" style={{alignItems:"end", width:"90%"}}>
                                                                                            {Number(item.prizeMoney).toLocaleString()}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_240  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" style={{alignItems:"end", width:"90%"}}>
                                                                                            { (Number(item.betMoney) - Number(item.prizeMoney)).toLocaleString()}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_241  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >
                                                                                            {dateFormation(new Date(item.startTime), 3)}<br/>{dateFormation(new Date(item.startTime), 10)}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_242  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >
                                                                                            
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_243  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_31_column_244  is-center   is-leaf is-sortable">
                                                                                    <div className="cell" >
                                                                                        <span className="caret-wrapper-down" >                                                                                            
                                                                                        <button data-v-5e3e41f0=""
                                                                                                type="button"
                                                                                                onClick = {() =>getUserTrans(item.username, item.created_at, item.parent_id )}
                                                                                                className="el-button el-button--primary el-button--mini"
                                                                                                style={{ marginBottom: "3px" }}>
                                                                                            <span>트랜잭션</span>
                                                                                    </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody> 
                                                        </table>
                                                        <div className="el-table__empty-block" style={{ height: "100%", width: "1300px" }}>
                                                            <span className="el-table__empty-text" style = {{display: isEmpty(userData)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="el-table__column-resize-proxy" style={{display: "none"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
            </div>
            <div className="el-dialog__wrapper" style={{display: "none"}}>
                <div role="dialog" aria-modal="true" aria-label="보너스 콜" className="el-dialog" style={{marginTop: "15vh"}}>
                    <div className="el-dialog__header"><span className="el-dialog__title">보너스 콜</span><button type="button"
                            aria-label="Close" className="el-dialog__headerbtn">
                                <i className="el-dialog__close el-icon el-icon-close"></i></button>
                    </div>
                    
                    <div className="el-dialog__footer">
                        <div className="dialog-footer">
                            <button type="button" className="el-button el-button--default">                                
                                <span> 취소 </span>
                            </button>
                            <button type="button" className="el-button el-button--primary">                                
                                <span> 확인 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>
  );
};

export default DoingGameDashboard;
