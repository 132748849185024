import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import Pagenation from "../../pagenation/pagenation";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { dateFormation } from "../../../utils";
import { getBonusHistAction } from "../../../redux/action/userManageAction";

const header = [
    {
        name: "유저이름",
        className: "el-table_28_column_203",
        property: "username"
    },
    {
        name: "콜업체",
        className: "el-table_28_column_205",
        property: "parent_name"
    },
    {
        name: "게임사",
        className: "el-table_28_column_204",
        property: "provider_name"
    },
    {
        name: "게임이름",
        className: "el-table_28_column_206",
        property: "game_name"
    },   
    {
        name: "시작시간",
        className: "el-table_28_column_208",
        property: "created_at"
    },
    {
        name: "콜금액",
        className: "el-table_28_column_209",
        property: "bonus_money"
    },
    {
        name: "당첨금액",
        className: "el-table_28_column_209",
        property: "win_money"
    },
    {
        name: "메모",
        className: "el-table_28_column_209",
        property: "memo"
    }
]

const BonusCallHistoryDashboard = () => {

    const dispatch = useDispatch()
    const list = useSelector((state) => state.user.userData)
    const bonusData = useSelector((state) => state.userManage.bonusHist)
    const {loginid} = list;
    const totalCount = !isEmpty(bonusData)? bonusData.totalCount : 0
    const bonusList =!isEmpty(bonusData)? bonusData.data : []

    const defaultSort = {
        provider_name: 0,
        username : 0,
        parent_name: 0,
        game_name: 0,
        created_at: 0,
        memo: 0,
        bonus_money: 0,
        win_money: 0
    }
    const [sortClass, setSortClass] = useState({
        provider_name: 0,
        username : 0,
        parent_name: 0,
        game_name: 0,
        created_at: 0,
        memo: 0,
        bonus_money: 0,
        win_money: 0
    })
    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);

    const [Input, setInput] = useState({
        username: "",
        game_name: "",
        startDate: "",
        endDate: ""
    })

    const { startDate, endDate, username, game_name } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getBonusHist(1, pageInfo.perPage)
        }
      }

    //----------------보너스 콜 내역 얻는 함수-------------//
    const getBonusHist = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                username: username,
                game_name: game_name,
                startDate: startDate,
                endDate: endDate
            }
        }
        dispatch(getBonusHistAction(params, setPageInfo, setCurPage))
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getBonusHist(1, pageInfo.perPage)
        }
    }, [loginid, pageInfo.perPage])
  
  return (
    <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
    <div data-v-e072c2c8="">
        <div id="content" className="app-content">
            <div  className = "main_app_content">
                <ol className="breadcrumb float-xl-end">
                    <li className="breadcrumb-item"><a>유저 관리</a></li>
                    <li className="breadcrumb-item active">보너스콜 내역</li>
                </ol>
                <h1 className="page-header">보너스콜 내역</h1>
                <div className="row">
                    <div className="col-12">
                        <div className="panel panel-inverse">
                            <div className="panel-heading">
                                <h4 className="panel-title">검색</h4>
                            </div>
                            <div className="panel-body">
                                <form className="form-horizontal form-bordered">
                                    <div className="form-group row"><label className="col-md-4 col-form-label">유저 이름</label>
                                        <div className="col-md-8">
                                            <div className="form-floating mb-3 mb-md-0">
                                                <input type="text" placeholder="유저 이름" 
                                                        value = {username} name = "username" onChange = {onChange} onKeyDown = {onEnter}
                                                        className="form-control fs-13px h-45px"/>
                                                <label htmlFor="nickname" className="d-flex align-items-center fs-13px">유저 이름</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row"><label className="col-md-4 col-form-label">게임 이름</label>
                                        <div className="col-md-8">
                                            <div className="form-floating mb-3 mb-md-0">
                                                <input type="text" placeholder="게임 이름"
                                                        value = {game_name} name = "game_name" onChange={onChange} onKeyDown = {onEnter}
                                                        className="form-control fs-13px h-45px"/>
                                                <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임 이름</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row period"><label className="col-md-4 col-form-label">기간(시작 ~ 종료)</label>
                                        <div className="col-md-4">
                                            <div className="form-floating mb-3 mb-md-0">
                                                <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{width: "100%"}}>
                                                <input type="text" autoComplete="off" name="" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating mb-3 mb-md-0 postInfo-container-item">
                                                <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date"
                                                    style={{width: "100%"}}>
                                                    <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row"><label className="col-md-4 col-form-label">상태</label>
                                        <div className="col-md-8">
                                            <div role="radiogroup" className="el-radio-group"><label role="radio"
                                                    tabIndex="-1" className="el-radio"><span className="el-radio__input"><span
                                                            className="el-radio__inner"></span><input type="radio"
                                                            aria-hidden="true" tabIndex="-1" className="el-radio__original"
                                                            value="false"/></span><span className="el-radio__label"> 전체
                                                        
                                                    </span></label><label role="radio" aria-checked="true" tabIndex="0"
                                                    className="el-radio is-checked"><span
                                                        className="el-radio__input is-checked"><span
                                                            className="el-radio__inner"></span><input type="radio"
                                                            aria-hidden="true" tabIndex="-1" className="el-radio__original"
                                                            value="false"/></span><span className="el-radio__label"> 진행중
                                                        
                                                    </span></label></div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="btn-group" style = {{width:"150px", marginRight:"15px"}}>
                                                <button type="button" className="btn btn-success btn-lg" onClick = {() => getBonusHist(1, pageInfo.perPage)}> 검색 </button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="panel panel-inverse">
                            <div className="panel-heading">
                                <h4 className="panel-title">보너스콜 내역 ( 총 {totalCount} 개 ) </h4>
                            </div>
                            <div className="panel-body">
                                <div className="vgt-wrap">
                                    <div className="vgt-inner-wrap">
                                        <div className="vgt-fixed-header"></div>
                                        <div className="vgt-responsive agentList">
                                            <div className="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
                                                style={{width: "100%"}}>
                                                <div className="el-table__header-wrapper">
                                                    <table cellSpacing="0" cellPadding="0" border="0"
                                                        className="el-table__header" style={{minWidth: "1384px"}}>
                                                        
                                                        <thead className="has-gutter">
                                                            <tr className="">
                                                                <th colSpan="1" rowSpan="1"
                                                                    className="el-table_32_column_245  is-center   is-leaf" style={{width:"5%"}}>
                                                                    <div className="cell">번호</div>
                                                                </th>
                                                                {
                                                                    header.map((item, index)=>{
                                                                        return(
                                                                            <th colSpan="1" rowSpan="1" className={`el-table_32_column_246 is-center is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                <div className="cell" onClick={() => getBonusHist(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                    <span className="caret-wrapper">
                                                                                        <i className="sort-caret ascending"></i>
                                                                                        <i className="sort-caret descending"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                    bonusList.map((item, index) => {
                                                                    return (
                                                                        <tr className="" key={index}>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_32_column_246 is-center is-leaf is-sortable">
                                                                                <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_32_column_247 is-center is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down">
                                                                                        {item.username}
                                                                                    </span>
                                                                                </div>
                                                                            </td>                                                                                
                                                                            <td colSpan="1" rowSpan="1" className="el-table_32_column_248  is-center   is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down">
                                                                                        {item.parent_name}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_32_column_249  is-center   is-leaf is-sortable">
                                                                                <div className="cell" >
                                                                                    <span className="caret-wrapper-down">
                                                                                        {item.provider_name}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1" className="el-table_32_column_250  is-center   is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down" >
                                                                                        {item.game_name}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1"
                                                                                className="el-table_32_column_251  is-center descending  is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down">
                                                                                        {dateFormation(item.created_at, 3)}<br />{dateFormation(item.created_at, 10)}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1"
                                                                                className="el-table_32_column_252  is-center descending  is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down" style = {{alignItems:"end", width: "90%"}}>
                                                                                        {Number(item.bonus_money).toLocaleString()}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1"
                                                                                className="el-table_32_column_253  is-center descending  is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down" style = {{alignItems:"end", width: "90%"}}>
                                                                                        {Number(item.win_money).toLocaleString()}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="1" rowSpan="1"
                                                                                className="el-table_32_column_254  is-center descending  is-leaf is-sortable">
                                                                                <div className="cell">
                                                                                    <span className="caret-wrapper-down">
                                                                                        {item.memo}
                                                                                    </span>
                                                                                </div>
                                                                            </td>                                                                                
                                                                            <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                        <span className="el-table__empty-text" style={{display:isEmpty(bonusList)? "flex" : "none"}}>데이터가 없습니다</span>
                                                    </div>
                                                </div>                                                
                                                <div className="el-table__column-resize-proxy" style={{display: "none"}}></div>
                                                <div className="el-loading-mask" style={{display: "none"}}></div>
                                            </div>
                                        </div>
                                        <Pagenation
                                                getList={getBonusHist}
                                                pageInfo={pageInfo}
                                                setPageInfo={setPageInfo} />                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
        </div>
    </div>
</section>
  );
};

export default BonusCallHistoryDashboard;
