import React, { useEffect, useState, Fragment } from "react";
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getAgentLevel, getAgentLevelClass, getAgentLevelColClass, getAgentLevelEng } from "../../../utils";

function MonthlyFigureTreeNode(props) {
    const { getCalcMonthlyFigure, data, level, startDate } = props
    const [userDate, setuserDate] = useState(data);
    const [active, setActive] = useState(false);

    const getUserChildren = () => {
        setActive(!active);
        if (!active && (isEmpty(userDate.loginid) || (Number(userDate.user_count) > 0))) {
            let date = isEmpty(userDate.created_month_at)
            getCalcMonthlyFigure(dateFormation(userDate.created_month_at, 3), userDate.loginid)
                .then((children) => {
                    setuserDate({
                        ...userDate,
                        children: children
                    });
                });
        }
    }


    return (
        <Fragment>
            <tr onClick={getUserChildren}>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_298  is-center descending  is-leaf is-sortable">
                    <div className="cell" style={{ paddingLeft: 16 * (4 - ( isNaN(data.agent_type) ? 4 : Number(data.agent_type))), display: "flex", justifyContent: "start", flexDirection: "row", borderRadius: "0", width:"90%" }}>
                        {((Number(userDate.user_count) > 0) || isEmpty(userDate.loginid)) ?
                            <i className={` fa ${active ? "fa-chevron-down" : "fa-chevron-right"}`} style={{ marginRight: "10px" }}></i>
                            :
                            <i className={` fa`} style={{ marginRight: "10px", width: "10px" }}></i>
                        }
                        <span data-v-12fb228d="" className={`badge rounded-0 ${getAgentLevelClass(userDate.agent_type)} `} style={{ marginRight: "5px", padding: "0.15em 0.85em" }}>{getAgentLevelEng(userDate.agent_type)}</span>
                        <span data-v-12fb228d="" className={`column-n0-wrap badge`}> {isEmpty(userDate.username) ? dateFormation(userDate.created_month_at, 6) : userDate.username}</span>

                    </div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_299  is-center   is-leaf is-sortable">
                    <div className={`cell ${getAgentLevelColClass(userDate.agent_type)} `}>{getAgentLevel(userDate.agent_type)}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_300  is-center   is-leaf">
                    <div className="cell">{userDate.username}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_301  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(userDate.betting_money).toLocaleString()} </div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_302  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(userDate.win_money).toLocaleString()}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_303  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(userDate.betting_money - userDate.win_money).toLocaleString()}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_304  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{isEmpty(userDate.money)? null : Number(userDate.money).toLocaleString()}</div>
                </td>
            </tr>
            {
                (!isEmpty(userDate.children) && active) && userDate.children.map((child, index) => {
                    return <MonthlyFigureTreeNode
                        data={child}
                        getCalcMonthlyFigure={getCalcMonthlyFigure}
                        level={level + 1}
                        key={!isEmpty(userDate.loginid) ? userDate.loginid : userDate.id}
                    />
                })

            }
        </Fragment>
    );

}

export default MonthlyFigureTreeNode;