import React,{ useState }from "react";
import { useDispatch } from 'react-redux'
import { DoChangePassword } from "../../redux/action/userAction";



const Passwordconfirm = (props) => {
    const dispatch = useDispatch();
    const { onModalActive, modalActive } = props;
        const [Input, setInput] = useState({
            old_password: "",
            new_password: ""
        })
        
    const { old_password, new_password } = Input
      
    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }
    const onSubmit = (e) => {
        e.preventDefault()    
        dispatch(DoChangePassword(Input))
        onModalActive()
    }
  
  return (
      <div className="pass-modal-back"style = {{ display : modalActive? "block" : "none"}}>
          <div className = "modal-backgroud" onClick={onModalActive}></div>
        <div className = "pass-modal position-relative" > 
        <div className="position-absolute pass_modaClose" onClick={onModalActive} ><i className="fa fa-times" aria-hidden="true"></i></div>       
            <div className= "pass-modal-title" style = {{marginTop:"10px", fontSize:"0.875rem"}}>비밀번호 변경</div>
            <div className = "pass-modal-form" >
                <div>
                    <div className = "pass-modal-input" >
                        <div className ="pass-modal-text" style = {{marginBottom:"5px"}}>이전 비밀번호</div>
                        <div className = "modal-input-style"><input type="text" value={old_password} name="old_password" placeholder="이전 비밀번호" className = "create-input" onChange={onChange}/></div>
                    </div>
                    <div className = "pass-modal-input" >
                        <div className ="pass-modal-text" style = {{marginBottom:"5px"}}>새 비밀번호</div>
                        <div className = "modal-input-style"><input type="text" value={new_password} name="new_password" placeholder="새 비밀번호" className = "create-input" onChange={onChange}/></div>
                    </div>                    
                    <div className = "pass-modal-btn">
                        <div className = "modal-button">
                        <button type="button" className="btn btn-success" onClick={onSubmit}> 비밀번호 변경 </button>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
      </div>
    
    );

}

export default Passwordconfirm;