import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { getGamecompanyAction } from '../../../redux/action/gameManageAction';
import { getAgentAction } from '../../../redux/action/userAction';
import { getCheckStatus, getStatusClassName, toastr } from '../../../utils';
import { api_url } from "../../../utils/keys";
import isEmpty from '../../../validation/is-empty';
import GameTreeNode from "../gameManage/GameTreeNode"

function OddGamecompanyDashboard() {

    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const agentList = useSelector((state) => state.figureManage.getAgentList);
    const gameCompanyList = useSelector((state) => state.gameManage.gameCompanyList);
    const { loginid } = list;

    const [bonsas, setBonsas] = useState([]);
    const [activeAgent, setActiveAgent] = useState({});
    const [gameCompanyIds, setGameCompanyIds] = useState([]);
        
    const [Input, setInput] = useState({
        parentId: "",
        agentId: ""
    })

    const { parentId, agentId } = Input
    
    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            searchAgent(loginid, "true")
        }
      }

    const onCheckCompany = (e) => {
        const { checked, value } = e.target;        
        if(checked){
            setGameCompanyIds([...gameCompanyIds, value])
        }
        else{
            setGameCompanyIds(gameCompanyIds.filter(id => id !== value));
        }
    }

    const getChildren = (loginid, isSearch) => {
        return new Promise(resolve => {
            axios.post(api_url + "/api/game/tree_list", {
                loginid: loginid,
                isSearch: isSearch,
                parentId : parentId,
                agentId: agentId
            }).then(res => {
                if (res.data.success) {
                    resolve(res.data.data);
                }
                else {
                    toastr.warning(res.data.message)
                }
            }).catch((err) => {
                console.log('err: ', err);
                if(err.response && (err.response.Status === 401 )){
                    // logout
                }
            })
        });
    }

  

    const searchAgent = (loginid, isSearch) => {
        let children = [];
        return new Promise(resolve => {
            axios.post(api_url + "/api/game/tree_list", {
                loginid: loginid,
                isSearch: isSearch,
                parentId : parentId,
                agentId: agentId
            }).then(res => {
                if (res.data.success) {
                    resolve(res.data.data);
                    setBonsas(res.data.data);
                    setActiveAgent(res.data.data[0])
                }
                else {
                    toastr.warning(res.data.message)
                }
            }).catch((err) => {
                console.log('err: ', err);
                if(err.response && (err.response.Status === 401 )){
                    // logout
                }
            })
        });
    }

    const get_gameComAgentList = () => {
        dispatch(getAgentAction());
    }
    const getGamecompanyList = () => {        
        dispatch(getGamecompanyAction());
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getGamecompanyList();
            get_gameComAgentList(); 
            getChildren(loginid, "false")
                .then(myChildren => {
                    setBonsas(myChildren);
                    setActiveAgent(myChildren[0])
                });
        }
    }, [loginid])    

    const onSetProvider = () => {        
        if( isEmpty(activeAgent) ){
           return toastr.warning("업체를 선택해주세요.")
        }        
        axios.post(api_url + "/api/game/provider_setting", {
            enterprise_id: activeAgent.loginid,
            provider_ids: gameCompanyIds            
        }).then(res => {
            if (res.data.success) {               
                toastr.success(res.data.message)
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            console.log('err: ', err);
            if(err.response && (err.response.Status === 401 )){
                // logout
            }
        })
    }
    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="">
                    <div id="content" className="app-content">
                        <div className = "main_app_content">
                            <ol className="breadcrumb float-xl-end">
                                
                                <li className="breadcrumb-item"><a>게임 관리</a></li>
                                <li className="breadcrumb-item active">업체에 게임사 배당</li>
                            </ol>
                            <h1 className="page-header">업체에 게임사 배당</h1>
                            <div className="row">
                                <div className="col-5">
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">업체를 선택하세요.</h4>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-horizontal form-bordered">
                                                <div className="form-group row"><label className="col-md-4 col-form-label">업체</label>
                                                    <div className="col-md-8">
                                                        <div className="
                                                            vtreeselect-dark
                                                            vue-treeselect
                                                            vue-treeselect--single
                                                            vue-treeselect--searchable
                                                            vue-treeselect--has-value
                                                            vue-treeselect--open-below
                                                            ">
                                                            <div className="vue-treeselect__control">
                                                                <div className="vue-treeselect__input-container" style={{ backgroundColor: "rgb(7, 19, 29)", padding: "0px" }}>
                                                                    <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                            value={parentId} name = "parentId" onChange = {onChange}
                                                                            style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%", height: "100%" }}>
                                                                        <option value=""  className="d-none">선택하세요</option>
                                                                        {
                                                                            agentList.map((item, index) => {
                                                                                return (
                                                                                    <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row"><label className="col-md-4 col-form-label">업체 이름/아이디</label>
                                                    <div className="col-md-8">
                                                        <div className="form-floating mb-1">
                                                            <input type="text" placeholder="업체 이름/아이디" 
                                                                    value = {agentId} name = "agentId" 
                                                                    onChange = {onChange} onKeyDown={onEnter}
                                                                    className="form-control fs-13px h-20px" />
                                                            <label htmlFor="nickname" className="d-flex align-items-center fs-13px">업체 이름/아이디</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="btn-group" style={{ width: "110px", marginRight: "15px" }}>
                                                            <button type="button" className="btn btn-success btn-lg" onClick = {()=>searchAgent(loginid, "true")}> 검색 </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-inverse">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">업체 트리뷰</h4>
                                            {/* <button type="button" className="btn btn-primary btn-sm"> 전체 선택 / 해지 </button> */}
                                        </div>
                                        <div className="el-table tree_view el-table--fit el-table--enable-row-hover el-table--enable-row-transition" style={{ width: "100%" }}>
                                            <div className="hidden-columns">
                                                <div></div>
                                            </div>
                                            <div className="el-table__body-wrapper is-scrolling-none">
                                                <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{marginTop:"-10px"}}>

                                                    {/* <tr name="el-table_24_column_172" width="693" /> */}

                                                    <tbody>
                                                        {
                                                            bonsas.map((bonsaItem, index) => {
                                                                return <GameTreeNode
                                                                    data={bonsaItem}
                                                                    level={1}
                                                                    activeAgent={activeAgent}
                                                                    setActiveAgent={setActiveAgent}
                                                                    getChildren={getChildren}
                                                                    setGameCompanyIds = {setGameCompanyIds}                                                                    
                                                                    key={bonsaItem.id} />
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                                <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}><span className="el-table__empty-text"></span></div>
                                            </div>
                                            <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                        </div>
                                    </div>
                                    <div className="btn-group w-100 mt-2">
                                        <button type="button" className="el-button btn btn-primary btn-lg py-3 el-button--primary" onClick = {onSetProvider} style={{margin:"unset"}}> 
                                            <span> 게임사 배당 및 해지 </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="panel panel-inverse">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">게임사 목록 (총 1개)</h4>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="vgt-wrap">
                                                        <div className="vgt-inner-wrap">
                                                            <div className="vgt-fixed-header"></div>
                                                            <div className="vgt-responsive agentList">
                                                                <div className="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition" style={{ width: "100%" }}>
                                                                    
                                                                    <div className="el-table__header-wrapper">
                                                                        <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{ minWidth: "812px" }}>


                                                                            <thead className="has-gutter">
                                                                                <tr className="">
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_266  is-center el-table-column--selection  is-leaf" style={{width:"5%"}}>
                                                                                        <div className="cell">
                                                                                            <label className="el-checkbox">
                                                                                                {/* <span className="el-checkbox__input">
                                                                                                    <span className="el-checkbox__inner"></span>
                                                                                                    <input type="checkbox" aria-hidden="false" className="el-checkbox__original" value="" />
                                                                                                </span> */}
                                                                                            </label>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_267  is-center   is-leaf">
                                                                                        <div className="cell">번호</div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_268  is-center   is-leaf">
                                                                                        <div className="cell">LOGO</div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_269  is-center   is-leaf">
                                                                                        <div className="cell">게임사</div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_270  is-center   is-leaf">
                                                                                        <div className="cell">점검여부</div>
                                                                                    </th>
                                                                                    <th colSpan="1" rowSpan="1" className="el-table_35_column_2701  is-center   is-leaf">
                                                                                        <div className="cell">상태</div>
                                                                                    </th>
                                                                                    <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    gameCompanyList.map((item, index) => {
                                                                                        let flag = false
                                                                                        gameCompanyIds.forEach(num => {
                                                                                            if (Number(num) === Number(item.id)) {
                                                                                                flag = true
                                                                                            }
                                                                                        })
                                                                                        
                                                                                        return (
                                                                                            <tr className="el-table__row expanded" key={index}>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_35_column_266 is-center el-table-column--selection">
                                                                                                    <div className="cell">
                                                                                                        <label className="el-checkbox">
                                                                                                            <span className="el-checkbox__input">                                                                                                                
                                                                                                                <input type="checkbox" 
                                                                                                                    aria-hidden="false" 
                                                                                                                    className="el-checkbox__original" 
                                                                                                                    checked={flag} 
                                                                                                                    value = {item.id}
                                                                                                                    onChange={onCheckCompany}/>
                                                                                                                <span className="el-checkbox__inner fa"></span>
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_35_column_267 is-center ">
                                                                                                    <div className="cell"><span>{index + 1}</span></div>
                                                                                                </td>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_35_column_268 is-center ">
                                                                                                    <div className="cell"><img src={item.image_url} /></div>
                                                                                                </td>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_35_column_269 is-center ">
                                                                                                    <div className="cell"><span>{item.name}</span></div>
                                                                                                </td>
                                                                                                <td rowSpan="1" colSpan="1" className="el-table_35_column_270 is-center ">
                                                                                                    <div className="cell"><span className={`badge  status ${getStatusClassName(item.status)}`}> {getCheckStatus(item.status)} </span></div>
                                                                                                </td>
                                                                                                <td colSpan="1" rowSpan="1" className="el-table_37_column_271  is-center   is-leaf is-sortable">
                                                                                                    <label className="switch">
                                                                                                        <input type="checkbox" checked = {flag} readOnly/>
                                                                                                        <span className="slider round"></span>
                                                                                                    </label>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                       
                                                                                    })
                                                                                }
                                                                               
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    
                                                                    <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                        
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default OddGamecompanyDashboard;