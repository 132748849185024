import { combineReducers } from "redux";
import user from "./user";
import gameManage from "./gameManage";
import agent from "./agent";
import userManage from "./userManage";
import figureManage from "./figureManage";
export default combineReducers({
  user, 
  gameManage, 
  agent,
  userManage,
  figureManage
});
