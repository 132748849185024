import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getGameStatus, getGameStatusClassName } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import { getAgentListAction, getGameListAction, get_gameListAction } from "../../../redux/action/userManageAction";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { history } from "../../../utils/history";
import { SET_TRANSACTION_MOVE } from "../../../redux/action/types";

const header = [
    {
        name: "유저이름",
        className: "el-table_30_column_222",
        property: "loginid"
    },
    {
        name: "소속",
        className: "el-table_30_column_223",
        property: "agent_loginid"
    },
    {
        name: "게임사",
        className: "el-table_30_column_224",
        property: "vendor_key"
    },
    {
        name: "게임이름",
        className: "el-table_30_column_225",
        property: "game_name"
    },
    {
        name: "베팅머니",
        className: "el-table_30_column_228",
        property: "bet_money"
    },
    {
        name: "당첨머니",
        className: "el-table_30_column_229",
        property: "win_money"
    },
    {
        name: "보너스 콜",
        className: "el-table_30_column_230",
        property: "bonus_call"
    },
    {
        name: "시작시간",
        className: "el-table_30_column_231",
        property: "start_time"
    },
    {
        name: "종료시간",
        className: "el-table_30_column_232",
        property: "end_time"
    }
]
const GameHistoryDashboard = () => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const gameData = useSelector((state) => state.userManage.userGameList);    
    const agentList = useSelector((state) => state.userManage.getAgentList);
    const getVendorList = useSelector((state) => state.userManage.getGameList);
    const { loginid } = list;
    const gameList = !isEmpty(gameData)? gameData.data : []
    const gameCount = !isEmpty(gameData)? gameData.totalCount : 0

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getGameList(1, pageInfo.perPage)
        }
      }

    const defaultSort = {
        loginid: 0,
        agent_loginid : 0,
        vendor_key: 0,
        game_name: 0,
        bet_money: 0,
        win_money: 0,
        bonus_call: 0,
        start_time: 0,
        end_time: 0
    }
    const [sortClass, setSortClass] = useState({
        loginid: 0,
        agent_loginid : 0,
        vendor_key: 0,
        game_name: 0,
        bet_money: 0,
        win_money: 0,
        bonus_call: 0,
        start_time: 0,
        end_time: 0
    })

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);

    const [Input, setInput] = useState({
        search_loginid: "",
        game_code: "",
        startDate: "",
        endDate: "",
        agentId: "",
        vendor_key: ""
    })

    const { search_loginid, game_code, startDate, endDate, agentId, vendor_key } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }



    //--------------트랜잭션 이행함수----------------//
    const getGameHistTrans = (page, perPage, user_id, start_time, end_time, agentId) => {
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {                   
           
                username: "",
                userId: user_id,
                startDate: start_time,
                endDate: "",                
                agentId: agentId
            
        }
        dispatch({
            type: SET_TRANSACTION_MOVE,
            payload: params
        });
        history.navigate("/TransactionHistory")
    }   

    const getGameList = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
            onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            page: page,
            perPage: perPage,
            isSearch: true,
            sortKey: sortKey,
            sortType: sortType,
            searchKey: {
                game_code: game_code,
                loginid: search_loginid,
                startDate: startDate,
                endDate: endDate,
                agentId: agentId,
                vendor_key: vendor_key
            }
        }
        dispatch(getGameListAction(params, setPageInfo, setCurPage));
    }

    const get_userAgentList = () => {
        dispatch(getAgentListAction());
    }

    const get_gameList = () => {
        dispatch(get_gameListAction());
    }

    useEffect(() => {
        if (!isEmpty(loginid)) {
            getGameList(1, pageInfo.perPage);
            get_userAgentList()
            get_gameList()
        }
    }, [loginid, pageInfo.perPage])
    return (
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="" id="content" className="app-content">
                <div className = "main_app_content">
                    <ol className="breadcrumb float-xl-end">
                        
                        <li className="breadcrumb-item"><a>유저 관리</a></li>
                        <li className="breadcrumb-item active">게임 내역</li>
                    </ol>
                    <h1 className="page-header">게임 내역</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">검색</h4>
                                </div>
                                <div className="panel-body">
                                    <form className="form-horizontal form-bordered">
                                        <div className="form-group row"><label className="col-md-4 col-form-label">해당 업체</label>
                                            <div className="col-md-8">
                                                <div className=" vtreeselect-dark vue-treeselect vue-treeselect--single vue-treeselect--searchable vue-treeselect--has-value vue-treeselect--open-below ">
                                                    <div className="vue-treeselect__control">
                                                        <div className="vue-treeselect__input-container">
                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                name="agentId" value={agentId} onChange={onChange}
                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                <option value="" className="d-none">선택하세요</option>
                                                                {
                                                                    agentList.map((item, index) => {
                                                                        return (
                                                                            <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: 999 }}></div>
                                                </div>
                                                <div className="mt-3 mb-3 mb-md-0">
                                                    {/* <div className="form-check form-check-inline">
                                                        <input id="low_agent_allow" type="checkbox" className="form-check-input" />
                                                        <label htmlFor="low_agent_allow" className="form-check-label">하부 업체 포함 검색 여부</label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row"><label className="col-md-4 col-form-label">게임사</label>
                                            <div className="col-md-8">
                                                <div className="
                                                    vtreeselect-dark
                                                    vue-treeselect
                                                    vue-treeselect--single
                                                    vue-treeselect--searchable
                                                    vue-treeselect--has-value
                                                    vue-treeselect--open-below">
                                                    <div className="vue-treeselect__control">
                                                        <div className="vue-treeselect__input-container">
                                                            <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                name="vendor_key" value={vendor_key} onChange={onChange}
                                                                style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                <option value="" className="d-none">선택하세요</option>
                                                                {
                                                                    getVendorList.map((item, index) => {
                                                                        return (
                                                                            <option value={item.code} className="" key={index}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row period"><label className="col-md-4 col-form-label">기간(시작 ~
                                        종료)</label>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date"
                                                        style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner"><i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date"
                                                        style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                        {
                                                            !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                        }
                                                        <Stack spacing={10} direction="column" alignItems="flex-start">
                                                            <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian />
                                                        </Stack>
                                                        <span className="el-input__prefix">
                                                            <i className="el-input__icon fa fa-calendar-clock"></i>
                                                        </span>
                                                        <span className="el-input__suffix">
                                                            <span className="el-input__suffix-inner">
                                                                <i className="el-input__icon"></i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row"><label className="col-md-4 col-form-label">유저 아이디</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="유저 아이디" 
                                                            className="form-control fs-13px h-45px" 
                                                            value={search_loginid} name="search_loginid" 
                                                            onChange={onChange} onKeyDown={onEnter} />
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">유저 아이디</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row"><label className="col-md-4 col-form-label">게임 이름</label>
                                            <div className="col-md-8">
                                                <div className="form-floating mb-3 mb-md-0">
                                                    <input type="text" placeholder="게임 이름" 
                                                            className="form-control fs-13px h-45px" 
                                                            value={game_code} name="game_code" 
                                                            onChange={onChange} onKeyDown={onEnter}/>
                                                    <label htmlFor="nickname" className="d-flex align-items-center fs-13px">게임 이름</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="btn-group" style = {{width:"150px", marginRight:"15px"}}>
                                                    <button type="button" className="btn btn-success btn-lg" onClick={() => getGameList(1, pageInfo.perPage)}> 검색 </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">게임 내역 (총 {gameCount} 개)</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="vgt-wrap">
                                        <div className="vgt-inner-wrap">
                                            <div className="vgt-fixed-header"></div>
                                            <div className="vgt-responsive agentList">
                                                <div className="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
                                                    style={{ width: "100%" }}>
                                                    
                                                    <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                        <table cellSpacing="0" cellPadding="0" border="0"
                                                            className="el-table__header" style={{ minWidth: "1384px"}}>

                                                            <thead className="has-gutter">
                                                                <tr className="">
                                                                    <th colSpan="1" rowSpan="1" className="el-table_30_column_221  is-center   is-leaf" style={{width:"6%"}}>
                                                                        <div className="cell">번호</div>
                                                                    </th>
                                                                    {
                                                                        header.map((item, index) => {
                                                                            return (
                                                                                <th colSpan="1" rowSpan="1" className={`${item.className}  is-center is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                    <div className="cell" onClick={() => getGameList(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                        <span className="caret-wrapper">
                                                                                            <i className="sort-caret ascending"></i>
                                                                                            <i className="sort-caret descending"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            )
                                                                        })
                                                                    }

                                                                    <th colSpan="1" rowSpan="1" className="el-table_30_column_233 is-center is-leaf is-sortable">
                                                                        <div className="cell">기능
                                                                </div>
                                                                    </th>

                                                                    <th className="gutter" style={{ width: "0px", display: "none" }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    gameList.map((item, index) => {
                                                                        return (
                                                                            <tr className="" key={index}>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_221  is-center   is-leaf">
                                                                                    <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_222 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{item.user_id}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_223 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{item.agent_loginid}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_224 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{item.provider_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_225 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{item.game_name}
                                                                                    </div>
                                                                                </td>                                                                                                                                                                
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_228 is-center is-leaf is-sortable">
                                                                                    <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.bet_money).toLocaleString()}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_229 is-center is-leaf is-sortable">
                                                                                    <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.win_money).toLocaleString()}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_230 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{item.bonus_call}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_231 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{dateFormation(item.start_time, 3)}<br/>{dateFormation(item.start_time, 10)}
                                                                                    </div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_30_column_232 is-center is-leaf is-sortable">
                                                                                    <div className="cell">{dateFormation(item.end_time, 3)}<br/>{dateFormation(item.end_time, 10)}
                                                                                    </div>
                                                                                </td>
                                                                                <td rowSpan="1" colSpan="1"
                                                                                    className="el-table_30_column_233 is-center ">
                                                                                    <div className="cell">
                                                                                        <button data-v-5e3e41f0=""
                                                                                                type="button"
                                                                                                onClick = {() =>getGameHistTrans( 1, pageInfo.perPage, item.user_id, item.start_time, item.end_time, item.agent_id )}
                                                                                                className="el-button el-button--primary el-button--mini"
                                                                                                style={{ marginBottom: "3px" }}><span>트랜잭션</span></button>
                                                                                    </div>
                                                                                </td>

                                                                                <td className="gutter" style={{ width: "0px", display: "none" }}></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                            <span className="el-table__empty-text" style={{display: isEmpty(gameList)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                        </div>
                                                    </div>
                                                    <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                    <div className="el-loading-mask" style={{ display: "none" }}>
                                                        <div className="el-loading-spinner"><svg viewBox="25 25 50 50"
                                                            className="circular">
                                                            <circle cx="50" cy="50" r="20" fill="none" className="path">
                                                            </circle>
                                                        </svg>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagenation
                                                getList={getGameList}
                                                pageInfo={pageInfo}
                                                setPageInfo={setPageInfo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
            </div>
        </section>
    );
};

export default GameHistoryDashboard;
