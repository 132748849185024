import { SET_BONUS_HIST, SET_GETAGENTLIST, SET_GETGAMELIST, SET_LOGIN_HIST, SET_TRANSACTION_MOVE, SET_USERGAMELIST, SET_USERLIST, SET_USERTRANSLIST } from "../action/types";

  
  const initialState = {
    userList: [],
    loginHist: [],
    userManageList: [],
    userTransList:[],
    userGameList:[],
    getAgentList:[],
    getGameList:[],
    getMoveTransaction:{},
    bonusHist:{}
  };
  
  export default function userManage(state = initialState, action) {
    switch (action.type) {
        case SET_USERLIST: {
            return {
                ...state,
                userList: action.payload
              };
        }
        case SET_LOGIN_HIST: {
          return {
              ...state,
              loginHist: action.payload
            };
        }
        case SET_USERTRANSLIST: {
          return {
              ...state,
              userTransList: action.payload
            };
        }
        case SET_USERGAMELIST: {
          return {
              ...state,
              userGameList: action.payload
            };
        }
        case SET_GETAGENTLIST: {
          return {
              ...state,
              getAgentList: action.payload
            };
        }
        case SET_GETGAMELIST: {
          return {
              ...state,
              getGameList: action.payload
            };
        } 
        case SET_TRANSACTION_MOVE: {
          return {
              ...state,
              getMoveTransaction: action.payload
            };
        }
        case SET_BONUS_HIST: {
          return {
              ...state,
              bonusHist: action.payload
            };
        }              
        default:
            return state;
    }
  }