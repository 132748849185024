import React, { useState } from "react";
import { Fragment } from "react";
import {  getAgentLevelClass, getAgentLevelEng } from "../../../utils";
import isEmpty from "../../../validation/is-empty";

const AgentTreeNode = (props) => {
  const { data, getChildren, activeAgent, setActiveAgent, setGameCompanyIds, level, setGameIds } = props;
  const [ agentData, setAgentData ] = useState(data);
  const [ active, setActive ] = useState(false); 
  
  
  const getMyChildren = (parent_loginid) => {
    props.getChildren(parent_loginid, "false")
    .then(subChildren => {
      if(!isEmpty(subChildren)){
        setAgentData({
          ...agentData,
          children: subChildren
        });
      }
    });
  }
  const onClickAgent = () => {
    setActive(!active);
    if(Number(data.agent_type > -1)){
      props.setActiveAgent(data);
    }
    if(Number(data.user_count) > 0){
      getMyChildren(data.loginid);      
    }   
  }
  
  return (
          <Fragment>
            <tr className={`el-table__row current-row el-table__row--level-${level}`}>
              <td rowSpan="1" colSpan="1" className="el-table_24_column_172 is-center " >
                <div className="cell" style={{margin:"unset"}} onClick={onClickAgent}>
                  <span className="el-table__indent" style={{paddingLeft: 16 * (3 - data.agent_type)}}></span>
                    <div className={`el-table__expand-icon `}>
                      { (Number(data.user_count) > 0) && 
                        <i className={` fa ${ active ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                      }
                    </div>
                    <span data-v-12fb228d="" className={`badge rounded-0 ${ getAgentLevelClass(data.agent_type)} `} style={{marginRight: "5px"}}>{getAgentLevelEng(data.agent_type)}</span>
                    <span data-v-12fb228d="" className={`column-n0-wrap badge ${ (activeAgent.loginid === data.loginid) && "bg-gray"}`}> {data.username + "(" + data.loginid + ")"}</span>
                </div>
              </td>
            </tr>
                {
                  (!isEmpty(agentData.children) && active) && agentData.children.map((agentItem, index) => {
                    return (<AgentTreeNode   
                              data={agentItem} 
                              level={level + 1  } 
                              activeAgent={activeAgent}
                              setActiveAgent={setActiveAgent} 
                              getChildren={getChildren}
                              key={index}/>)
                  })
                }
          </Fragment>
  );
};

export default AgentTreeNode;
