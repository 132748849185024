import { api_url } from "../../utils/keys";
import axios from "axios";
import { SET_GAMECOMPANYLIST, SET_GAMELIST, SET_LOADING } from "./types";
import { toastr } from "../../utils";




//------------게임사목록얻는 함수---------------//

export const getGamecompanyAction = () => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })   
    axios.post(api_url + "/api/game/providerlist")
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })   
            if (res.data.success) {
                dispatch({
                    type: SET_GAMECOMPANYLIST,
                    payload: res.data.data.recordset
                });                
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })   
            console.log("err", err)
        });
}


//------------게임목록얻는 함수---------------//

export const getGameListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })   
    axios.post(api_url + "/api/game/gamelist", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })   
            if (res.data.success) {
                dispatch({
                    type: SET_GAMELIST,
                    payload: res.data.data
                });
                setPageInfo({
                    perPage: params.perPage,
                    curPage: res.data.data.curPage,
                    totalPage: res.data.data.totalPage
                });
                setCurPage(params.page);                
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })   
            console.log("err", err)
        });
}

//---------------게임사상태 바꾸는 함수--------------------//
export const changeProviderAction = (params) => {
    return new Promise(resolve => {
        axios.post(api_url + "/api/game/providerchange", params)
        .then(res => {
            if (res.data.success) {
                toastr.success(res.data.message);
                resolve();            
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
        
}