import { api_url } from "../../utils/keys";
import axios from "axios";
import { SET_AGENTLIST, SET_GETAGENTLIST, SET_JOIN_AGENT_LIST, SET_LOADING, SET_POINT_TRANSHISTORY, GET_HEADER_INFO } from "./types";
import { toastr } from "../../utils";
import { GetUserData } from "./userAction";

//---------------------업체목록얻는 함수------------------------//
export const getAgentListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/userlist", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_AGENTLIST,
                    payload: res.data.data
                });
                setPageInfo({
                    perPage: params.perPage,
                    curPage: res.data.data.curPage,
                    totalPage: res.data.data.totalPage
                });
                setCurPage(params.page);
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}


//-----------하부생성함수---------------//


export const CreateDownAction = (params) => {
    
      return new Promise (resolve =>{
          
          axios.post(api_url + "/api/enterprise/sub_register", params)
              .then(res => {            
                  
                  if (res.data.success) {
                      toastr.success(res.data.message);                   
                      resolve();
                  }
                  else {
                      toastr.warning(res.data.message);
                  }
              })
              .catch(err => {                  
                  toastr.warning("잠시후 다시 시도해주세요.")
                  console.log(err);
              });
      })
}


//-----------중복체크함수---------------//

export const checkEnterpriseAction = (params) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/check", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                toastr.success(res.data.message);
                dispatch(GetUserData());
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            toastr.warning("잠시후 다시 시도해주세요.")
            console.log(err);
        });
}


//-----------비밀번호변경함수---------------//

export const changePswAction = (params, onChangePswModal) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.put(api_url + "/api/enterprise/sub_register", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                toastr.success(res.data.message);
                onChangePswModal()
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            toastr.warning("잠시후 다시 시도해주세요.")
            console.log(err);
        });
}


//------------------------포인트지급함수---------------------//
export const payMoneyAction = (params, headerInfo) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/paymoney", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                toastr.success(res.data.message);
                headerInfo();
                dispatch(GetUserData());                
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            toastr.warning("잠시후 다시 시도해주세요.")
            console.log(err);
        });
}


//------------------------포인트차감함수---------------------//
export const substractMoneyAction = (params,headerInfo) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/paymoney", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                toastr.success(res.data.message);
                headerInfo();
                dispatch(GetUserData());                
            }
            else {
                toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            toastr.warning("잠시후 다시 시도해주세요.")
            console.log(err);
        });
}


//------------------------업체삭제하는 함수---------------------//
export const deleteAgentAction = (params, onDeleteModal) =>  {
    
    return new Promise(resolve => {
         
        axios.post(api_url + "/api/enterprise/sub_register/delete", params)
        .then(res => {            
            if (res.data.success) {
                toastr.success(res.data.message);                
                onDeleteModal();
                resolve();
            }
            else {                
                toastr.warning(res.data.message);
                
            }
        })
        .catch(err => {            
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
}


//------------------------업체수정하는 함수---------------------//
export const changeAgentAction = (params, setChangeModal) =>  {
    
    return new Promise(resolve => {         
        axios.post(api_url + "/api/enterprise/sub_register/change", params)
        .then(res => { 
                       
            if (res.data.success) {
                toastr.success(res.data.message);                
                setChangeModal();
                resolve();
            }
            else {                
                toastr.warning(res.data.message);
                
            }
        })
        .catch(err => {  
                     
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
}


//---------------------포인트 트랜잭션내역 얻는 함수------------------------//
export const getPointTransAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/moneyhistory", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_POINT_TRANSHISTORY,
                    payload: res.data.data
                });
                setPageInfo({
                    perPage: params.perPage,
                    curPage: res.data.data.curPage,
                    totalPage: res.data.data.totalPage
                });
                setCurPage(params.page);
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}



//---------------------포인트 트랜잭션내역에서 업체목록 얻는 함수---------------------//
export const get_AgentListAction = () => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/treelist/agentlist")
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_GETAGENTLIST,
                    payload: res.data.data
                });
            }
                
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}


//---------------------가입승인요청목록 얻는 함수------------------------//
export const getJoinAgentListAction = (params, setPageInfo, setCurPage) => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: true
      })
    axios.post(api_url + "/api/enterprise/join_request_list", params)
        .then(res => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            if (res.data.success) {
                dispatch({
                    type: SET_JOIN_AGENT_LIST,
                    payload: res.data.data.data
                });
                setPageInfo({
                    perPage: params.perPage,
                    curPage: res.data.data.curPage,
                    totalPage: res.data.data.totalPage
                });
                setCurPage(params.page);
            }
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {
            dispatch({
                type: SET_LOADING,
                payload: false
              })
            console.log("err", err)
        });
}


//------------------------가입승인요청하는 함수---------------------//
export const changeAgentStatusAction = (params, onJoinModal) =>  {
    
    return new Promise(resolve => {
         
        axios.post(api_url + "/api/enterprise/join_request", params)
        .then(res => {            
            if (res.data.success) {
                toastr.success(res.data.message);                
                onJoinModal();
                resolve();
            }
            else {                
                toastr.warning(res.data.message);
                
            }
        })
        .catch(err => {            
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
}


//------------------------가입승인거절하는 함수---------------------//
export const changeAgentStatus_delAction = (params, onDeleteModal) =>  {
    
    return new Promise(resolve => {
         
        axios.post(api_url + "/api/enterprise/join_request", params)
        .then(res => {            
            if (res.data.success) {
                toastr.success(res.data.message);                
                onDeleteModal();
                resolve();
            }
            else {                
                toastr.warning(res.data.message);
                
            }
        })
        .catch(err => {            
            toastr.warning("잠시후 다시 시도해주세요.");
            console.log(err);
        });
    });
}


//------------------------헤더부정보 얻는 함수---------------------//

export const getHeaderInfoAction = (param) => dispatch => {
    
    axios.post(api_url + "/api/enterprise/header_info", param)
        .then(res => {            
            if (res.data.success) {
                dispatch({
                    type: GET_HEADER_INFO,
                    payload: res.data.data.data
                });
            }
                
            else {
                toastr.warning(res.data.message)
            }
        }).catch((err) => {            
            console.log("err", err)
        });
}