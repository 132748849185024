import React from "react";
import { useSelector } from 'react-redux'

const Spinner = () => {
    const {Loading} = useSelector((state) => state.user); 
    return(
        <div className= {`spinner-back ${Loading == true? "d-block":"d-none"}`}  >
        <div className="spinner">    
        </div>
    </div> 
    )
}



export default Spinner;