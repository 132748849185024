import React, { useEffect, useState, Fragment } from "react";
import isEmpty from "../../../validation/is-empty";
import { getAgentLevel, getAgentLevelClass, getAgentLevelColClass, getAgentLevelEng } from "../../../utils";

function UserFigureTreeNode(props) {
    const { getCalcUserFigure, data, level, } = props
    const [userDate, setuserDate] = useState(data);
    const [active, setActive] = useState(false);

    const getUserChildren = () => {
        setActive(!active);
        if ((Number(data.user_count) > 0)) {
            getCalcUserFigure(data.loginid, "false")
                .then((children) => {
                    setuserDate({
                        ...userDate,
                        children: children
                    });
                });
        }
    }
    

    return (
        <Fragment>
            <tr onClick={getUserChildren}>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_298  is-center descending  is-leaf is-sortable">
                    <div className="cell" style={{ paddingLeft: 16 * (3-data.agent_type), display: "flex", justifyContent: "start", flexDirection: "row", borderRadius: "0", width: "90%" }}>
                        <div className={`el-table__expand-icon `}>
                        {((Number(data.user_count) > 0) || isEmpty(data.loginid)) ?
                            <i className={` fa ${active ? "fa-chevron-down" : "fa-chevron-right"}`} style={{ marginRight: "10px" }}></i>
                            :
                            <i className={` fa`} style={{ marginRight: "10px", width: "10px" }}></i>
                        }
                            {/* {(Number(data.user_count) > 0) ?
                                <i className={` fa ${active ? "fa-minus-square" : "fa-plus-square"}`} style={{ marginRight: "10px" }}></i>
                                :
                                <i className={` fa`} style={{ marginRight: "10px", width: "10px" }}></i>
                            } */}
                        </div>
                        <span data-v-12fb228d="" className={`badge rounded-0 ${getAgentLevelClass(data.agent_type)} `} style={{ marginRight: "5px", padding: "0.15em 0.85em" }}>{getAgentLevelEng(data.agent_type)}</span>
                        <span data-v-12fb228d="" className={`column-n0-wrap badge`}> {data.username + "(" + data.loginid + ")"}</span>
                        {/* {
                            (data.user_count > 0) && <div className="el-table__expand-icon " style={{ marginRight: "15px" }}><i className=" fa fa-plus-square"></i></div>
                        }
                        <div>
                            {data.loginid}
                        </div> */}
                    </div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_299  is-center   is-leaf is-sortable">
                    <div className={`cell ${getAgentLevelColClass(data.agent_type)} `}>{getAgentLevel(data.agent_type)}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_300  is-center   is-leaf">
                    <div className="cell">{data.username}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_301  is-center   is-leaf is-sortable" >
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(data.betting_money).toLocaleString()} </div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_302  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(data.win_money).toLocaleString()}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_303  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(data.betting_money - data.win_money).toLocaleString()}</div>
                </td>
                <td colSpan="1" rowSpan="1" className="el-table_40_column_304  is-center   is-leaf is-sortable">
                    <div className="cell" style={{textAlign:"end", width:"90%"}}>{Number(data.money).toLocaleString()}</div>
                </td>
            </tr>
            {
                (!isEmpty(userDate.children) && active) && userDate.children.map((child, index) => {
                    return <UserFigureTreeNode
                        data={child}
                        getCalcUserFigure={getCalcUserFigure}
                        level={level + 1}
                        key={index}
                        />
                })

            }
        </Fragment>
    );

}

export default UserFigureTreeNode;