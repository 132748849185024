import { api_url } from "../../utils/keys";
import axios from "axios";
import { toastr } from "../../utils";
import { GetUserData } from "./userAction";

export const changeUserInfoAction = (params, onNameActive) => dispatch => {
    axios.put(api_url + "/api/auth/userdata", params)
    .then(res => {
        if(res.data.success){
        toastr.success(res.data.message); 
        dispatch(GetUserData());
        onNameActive()
        }
        else{
        toastr.warning(res.data.message);
        }
    })
    .catch(err => {
        toastr.warning("잠시후 다시 시도해주세요.")
        console.log(err);
    });
}


//-----------------refrechToken 변경--------------------//

export const changeRefreshTokenAction = (onTokenActive) => dispatch => {
    axios.post(api_url + "/api/auth/refresh_api_token", {})
        .then(res => {
            if(res.data.success){
            toastr.success(res.data.message);
            dispatch(GetUserData());
            onTokenActive()
            }
            else{
            toastr.warning(res.data.message);
            }
        })
        .catch(err => {
            toastr.warning("잠시후 다시 시도해주세요.")
            console.log(err);
        });
}

//-----------------api_endpoint 변경--------------------//

export const changeApiEndpointAction = (params, onUrlActive) => dispatch => {
    axios.put(api_url + "/api/auth/update_api_info", params)
    .then(res => {
        if(res.data.success){
        toastr.success(res.data.message);
        dispatch(GetUserData());
        onUrlActive()
        }
        else{
        toastr.warning(res.data.message);
        }
    })
    .catch(err => {
        toastr.warning("잠시후 다시 시도해주세요.")
        console.log(err);
    });
}