import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import OddGamecompanyDashboard from "../../components/common/gameManage/OddGamecompany";
const OddGamecompany = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <OddGamecompanyDashboard/>
      <Sidebar pageValue = "gameManage"/>
    </div>
  )
}

export default OddGamecompany;