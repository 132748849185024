import React, { useEffect, useState, Fragment } from "react"; 
import { useSelector, useDispatch } from 'react-redux'
import { dateFormation, toastr } from "../../utils";
import axios from "axios";
import { api_url } from "../../utils/keys";
import isEmpty from "../../validation/is-empty";
import { SET_DASHIBOARD_INFO } from "../../redux/action/types";
import { socket } from "../../commons/cores/socket";

const MainDashboard = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.user.userData);
  const mainUserInfo = useSelector((state) => state.user.dashData);
  const { username, loginid, money, id } = list  
  const { my_win_money, my_betting_money, my_user_count, my_agent_count, all_agent_count, all_agent_money, all_betting_money, all_win_money } = mainUserInfo;
  
  
  
  const getMainInfo = () => {
    const param = {
      start_time:dateFormation(Date.now(), 3)
    }

    return new Promise(resolve => {
      axios.post(api_url + "/api/auth/main_user_info", param)
      .then(res =>{
        if (res.data.success) {
          resolve(res.data.data);
          dispatch({
            type: SET_DASHIBOARD_INFO,
            payload: res.data.data
          })         
      }
      else {
          toastr.warning(res.data.message)
      }
      }

      ).catch((err) => {        
        console.log("err", err)
    });
    })
  }

  useEffect(() => {
    if (!isEmpty(loginid)) {      
      getMainInfo()        
    }
}, [loginid]);
  
  return (
    <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
      <div data-v-e072c2c8="">
        <div id="content" className="app-content">
            <div className = "main_app_content">
              <ol className="breadcrumb float-xl-end">
                
                <li className="breadcrumb-item active">대시보드</li>
              </ol>
              <h1 className="page-header">대시보드</h1>
              <div className="row">
                <div className=" dashboard_cell col-md-6">
                  <div className="widget widget-stats bg-gradient-blue">
                      <div className="stats-icon"><i className="fa fa-p"></i></div>
                      <div className="stats-info">
                        <h4 className="text-white">나의 보유 포인트</h4>
                        <p> {Number(money).toLocaleString()} ₩ </p>
                      </div>
                      <div className="stats-link"><a>나의 보유 포인트</a></div>
                  </div>
                </div>
                <div className="dashboard_cell col-md-6">
                  <div className="widget widget-stats bg-gradient-purple">
                      <div className="stats-icon"><i className="fa fa-krw"></i></div>
                      <div className="stats-info">
                        <h4 className="text-white">당일 나의 수익 </h4>
                        <p>{(Number(!isEmpty(mainUserInfo[0]) && mainUserInfo[0].my_betting_money) -Number(!isEmpty(mainUserInfo[0]) && mainUserInfo[0].my_win_money)).toLocaleString()} ₩</p>
                      </div>
                      <div className="stats-link"><a>당일 나의 수익 </a></div>
                  </div>
                </div>
                
                <div className="dashboard_cell col-md-6">
                  <div className="widget widget-stats bg-gradient-cyan">
                      <div className="stats-icon"><i className="fa fa-user"></i></div>
                      <div className="stats-info">
                        <h4 className="text-white">나의 유저 수</h4>
                        <p>{!isEmpty(mainUserInfo[0]) && mainUserInfo[0].my_user_count}명</p>
                      </div>
                      <div className="stats-link"><a>나의 유저 수</a></div>
                  </div>
                </div>
                <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-pink">
                        <div className="stats-icon"><i className="fa fa-info-circle"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">게임사 / 게임</h4>
                          <p> 1 / 8</p>
                        </div>
                        <div className="stats-link"><a>게임사 / 게임</a></div>
                    </div>
                </div>
                <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-blue">
                        <div className="stats-icon"><i className="fa fa-p"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">하부(전체) 보유 포인트</h4>
                          <p>{Number(!isEmpty(mainUserInfo[0]) && mainUserInfo[0].all_agent_money).toLocaleString()} ₩</p>
                        </div>
                        <div className="stats-link"><a>하부(전체) 보유 포인트</a></div>
                    </div>
                </div>
                <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-purple">
                        <div className="stats-icon"><i className="fa fa-krw"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">당일 하부(전체) 수익 </h4>
                          <p>{(Number(!isEmpty(mainUserInfo[0]) && mainUserInfo[0].all_betting_money) -Number(!isEmpty(mainUserInfo[0]) && mainUserInfo[0].all_win_money)).toLocaleString()} ₩</p>
                        </div>
                        <div className="stats-link"><a>당일 하부(전체) 수익 </a></div>
                    </div>
                </div>
                {/* <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-orange">
                        <div className="stats-icon"><i className="fa fa-krw"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">당일 하부(전체) 수익 [라이브]</h4>
                          <p>2,458,449 ₩</p>
                        </div>
                        <div className="stats-link"><a>당일 하부(전체) 수익 [라이브]</a></div>
                    </div>
                </div> */}
                <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-cyan">
                        <div className="stats-icon"><i className="fa fa-users"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">하부(전체) 유저 수</h4>
                          <p>{!isEmpty(mainUserInfo[0]) && mainUserInfo[0].agent_user_count} 명</p>
                        </div>
                        <div className="stats-link"><a>하부(전체) 유저 수</a></div>
                    </div>
                </div>
                <div className="dashboard_cell col-md-6">
                    <div className="widget widget-stats bg-gradient-pink">
                        <div className="stats-icon"><i className="fa fa-sitemap"></i></div>
                        <div className="stats-info">
                          <h4 className="text-white">하부(전체) 업체 수</h4>
                          <p>{!isEmpty(mainUserInfo[0]) && mainUserInfo[0].all_agent_count}명</p>
                        </div>
                        <div className="stats-link"><a>하부(전체) 업체 수</a></div>
                    </div>
                </div>
              </div>
            </div>
            <div data-v-631daf08="" className="ins-progress" style={{opacity: "0", display: "none", position: "fixed", height: "3px"}}></div>
            <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOP GAME Admin Corp. All Rights Reserved. </div>
            
        </div>
      </div>
    </section>
  );
};

export default MainDashboard;
