import { SET_GAMECOMPANYLIST, SET_GAMELIST } from "../action/types";
  
  const initialState = {
    gameList: { 
      recordset: [], 
      rowsAffected: []
    },
    gameCompanyList: [],
  };
  
  export default function gameManage(state = initialState, action) {
    switch (action.type) {
      case SET_GAMECOMPANYLIST: {
        return {
            ...state,
            gameCompanyList: action.payload
          };
      }
      case SET_GAMELIST: {
        return {
            ...state,
            gameList: action.payload
          };
      }  
      default:
        return state;
    }
  }
  