import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import MonthlyFigureDashboard from "../../components/common/figure/MonthlyFigure";
const GameFigure = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <MonthlyFigureDashboard/>
      <Sidebar pageValue = "figure"/>
    </div>
  )
}

export default GameFigure;