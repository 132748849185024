import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import DoingGameDashboard from "../../components/common/userManage/DoingGame";
const DoingGame = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <DoingGameDashboard/>
      <Sidebar pageValue = "user"/>
    </div>
  )
}

export default DoingGame;