import store from "../redux/store";
import moment from "moment";
import { NotificationManager } from 'react-notifications';

export const convertObj = value => {
  if (typeof value === "object") return value;
  if (typeof value === "string") return JSON.parse(value);
  return value;
};

// To use toastr in node
export const toastr = {
  info: message => {
    NotificationManager.info(message, "", 5000);
  },
  success: message => {
    NotificationManager.success(message, "", 5000);
  },
  warning: message => {
    NotificationManager.warning(message, "", 5000);
  },
  error: message => {
    NotificationManager.error(message, "", 5000);
  }
};

// Show message after get the response from backend.
export const showMessage = message => {
  if (!message) return;
  if (message.warning) NotificationManager.warning(message.warning, "", 5000);
  if (message.info) NotificationManager.info(message.info, "", 5000);
  if (message.success) NotificationManager.success(message.success, "", 5000);
  if (message.error) NotificationManager.error(message.error, "", 5000);
};

// You need to format the date in different forms.
export const dateFormation = (date, type = 1) => {
  switch (type) {
    case 0:
      return moment(new Date(date)).utc().format("YY-MM-DD HH:mm:ss");
    case 1:
      return moment(new Date(date)).utc().format("YYYY-MM-DD HH:mm:ss");
    case 2:
      return moment(new Date(date)).utc().format("YY-MM-DD");
    case 3:
      return moment(new Date(date)).utc().format("YYYY-MM-DD");
    case 4:
      return moment(new Date(date)).utc().format("MM-DD");
    case 5:
      return moment(new Date(date)).utc().format("HH:mm");
    case 6:
      return moment(new Date(date)).utc().format("YYYY-MM");
    case 7:
      return moment(new Date(date)).utc().format("hh:mm a");
    case 8:
      return moment(new Date(date)).utc().format("YYYY-MM-DD HH:mm");
    case 9:
      return moment(new Date(date)).utc().format("MM/DD HH:mm");
    case 10:
      return moment(new Date(date)).utc().format("HH:mm:ss");
    case 11:
      return moment(new Date(date)).format("YYYY-MM-DD");
    default:
      return "";
  }  
};

export const getAgentLevel = (level) => {
  switch (Number(level)) {
    case 3:
      return "마스터";
    case 2:
      return "오퍼레이터";
    case 1:
      return "에이젠트";
    case 0:
    case -1:
      return "회원";
  
    default:
      break;
  }
}

export const getAgentLevelClass = (level) => {
  switch (Number(level)) {
    case 3:
      return "bg-blue";
    case 2:
      return "bg-purple";
    case 1:
      return "bg-green";
    case 0:
    case -1:
      return "bg-yellow";
  
    default:
      break;
  }
}

export const getAgentLevelColClass = (level) => {
  switch (Number(level)) {
    case 3:
      return "col-blue";
    case 2:
      return "col-purple";
    case 1:
      return "col-green";
    case 0:
    case -1:
      return "col-yellow";
  
    default:
      break;
  }
}

export const getStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "정상"
    case 0:
      return "대기"
    default:
      break;
  }
}

export const getCheckStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "정상"
    case 0:
      return "점검"
    default:
      break;
  }
}

export const getStatusClassName = (status) => {
  switch (Number(status)) {
    case 1:
      return "col-blue"
    case 0:
      return "col-red";
    default:
      break;
  }
}

export const getAgentLevelEng = (data) => {
  switch (Number(data)) {
    case 3:
      return "A";
    case 2:
      return "B";
    case 1:
      return "C";
    case -1:
      return "D";
  
    default:
    return "";
  }
}

export const getPayStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "소속에이젠트에 머니지급"
    case 2:
      return "소속에이젠트의 머니회수"
    case 3:
      return "상위에이젠트가 머니지급"
    case 4:
      return "상위에이젠트가 머니회수"
    case 5:
      return "유저베팅"
    case 6:
      return "유저당첨"
    case 7:
      return "유저베팅취소"
    case 8:
      return "유저보너스지급"
    case 9:
      return "유저보너스지급취소"
    case 10:
      return "업체삭제"
    case 11:
      return "알생성"
    case 12:
      return "알소멸"  
    default:
      break;
  }
}


export const getPayStatusClassName = (status) => {
  switch (Number(status)) {
    case 1:
      return "col-purple"
    case 2:
      return "col-yellow"
    case 3:
      return "col-purple"
    case 4:
      return "col-yellow"
    case 5:
      return "col-blue"
    case 6:
      return "col-green"
    case 7:
      return "col-red" 
    case 8:
      return "col-green" 
    case 9:
      return "col-red" 
    case 10:
      return "col-red"
    case 11:
      return "col-purple"
    case 12:
      return "col-yellow"  
    default:
      break;
  }
}

export const getResStatus = (status) => {
  if( Number(status)==200) 
    return "성공"
  else
    return "실패"
  
}

export const getResStatusClassName = (status) => {
  if( Number(status)==200) 
    return "col-blue"
  else
    return "col-red"
  
}

export const getHeaderClassName = (status) => {
  if( Number(status)==0) 
    return "col-white"
  else
    return "col-green"
  
}




export const getGameStatus = (status) => {
  if( Number(status)==0) 
    return "당첨"
  else
    return "낙첨"
  
}

export const getGameStatusClassName = (status) => {
  if( Number(status)==0) 
    return "col-blue"
  else
    return "col-red"
}

export const checkLoginId = (value) => {
  var re = /[^a-z^A-Z^0-9]/g;
  if (re.test(value))
  {
    return true;
  }
  else {
   return false;
  }
}


export const getTranStatus = (status) => {
  switch (Number(status)) {
    case 30:
      return "베팅"
    case 31:
      return "당첨"
    case 32:
      return "지급"
    case 33:
      return "회수"
    case 34:
      return "취소"
    default:
      break;
  }
}


export const getTranStatusClassName = (status) => {
  switch (Number(status)) {
    case 30:
      return "col-blue"
    case 31:
      return "col-green"
    case 32:
      return "col-green"
    case 33:
      return "col-pink"
    case 34:
      return "col-red"    
    default:
      break;
  }
}


export const getUserStatus = (status) => {
  switch (Number(status)) {
    case 0:
      return "대기"
    case 1:
      return "승인"
    case 2:
      return "차단"
    default:
      break;
  }
}


export const getUserStatusClassName = (status) => {
  switch (Number(status)) {
    case 1:
      return "col-green"
    case 2:
      return "col-blue"
    case 0:
      return "col-red"     
    default:
      break;
  }
}


export const getOddGameStatus = (status) => {
  switch (status) {
    case true:
      return "배당됨"
    case false:
      return "해지됨"
    default:
      break;
  }
}

export const getOddGameStatusClassName = (status) => {
  switch (status) {
    case true:
      return "col-blue"
    case false:
      return "col-red";
    default:
      break;
  }
}

export const no_comma = data => {
  var tmp = "";
  var comma = ",";
  var i;

  for (i = 0; i < data.length; i++) {
    if (data.charAt(i) != comma) tmp += data.charAt(i);
  }
  return tmp;
};

export const getLocalStringNumber = (value) => {
  if (isNaN(Number(value))) {
    return 0;
  }
  else {
    return Number(value).toLocaleString();
  }
}

