import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import AgentTreeViewDashboard from "../../components/common/agentManage/AgentTreeView";
const AgentTreeView = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <AgentTreeViewDashboard/>
      <Sidebar pageValue="agent"/>
    </div>
  )
}

export default AgentTreeView;