import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { dateFormation, getTranStatusClassName, getTranStatus } from "../../../utils";
import Pagenation from "../../pagenation/pagenation";
import { getAgentListAction, getUserTransListAction } from "../../../redux/action/userManageAction";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';

const header = [
    {
        name: "유저아이디",
        className: "el-table_29_column_209",
        property: "id"
    },
    {
        name: "유저이름",
        className: "el-table_29_column_210",
        property: "username"
    },
    {
        name: "라운드",
        className: "el-table_29_column_211",
        property: "round"
    },
    {
        name: "게임사",
        className: "el-table_29_column_212",
        property: "vendor_key"
    },
    {
        name: "게임이름",
        className: "el-table_29_column_213",
        property: "game_name"
    },
    {
        name: "카테고리",
        className: "el-table_29_column_214",
        property: "game_type"
    },
    {
        name: "타입",
        className: "el-table_29_column_215",
        property: "type"
    },
    {
        name: "처리금액",
        className: "el-table_29_column_216",
        property: "amount"
    },
    {
        name: "이전금액",
        className: "el-table_29_column_217",
        property: "prev_money"
    },
    {
        name: "현재금액",
        className: "el-table_29_column_218",
        property: "money"
    },
    {
        name: "처리일",
        className: "el-table_29_column_219",
        property: "created_at"
    }
]

const searchType = [
    {
        name: "베팅",
        value: 30
    },
    {
        name: "당첨",
        value: 31
    },
    {
        name: "지급",
        value: 32
    },
    {
        name: "회수",
        value: 33
    },
    {
        name: "취소",
        value: 34
    }
]
const TransactionHistoryDashboard = () => {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const userTransData = useSelector((state) => state.userManage.userTransList);
    const agentList = useSelector((state) => state.userManage.getAgentList);
    const getParamByUserList = useSelector((state)=> state.userManage.getMoveTransaction)    
    const { loginid } = list;
    const userTransList = !isEmpty(userTransData)? userTransData.data : []
    const transCount = !isEmpty(userTransData)? userTransData.totalCount : 0

    const [ sortType, setSortType ] = useState(false);
    const onSortType = () =>{
        setSortType(!sortType)
    }

    const [findType, setFindType] = useState([]);

    const onCheckType = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setFindType([...findType, value]);
        }
        else {
            setFindType(findType.filter(id => id !== value));
        }
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            getUserTrans(1, pageInfo.perPage)
        }
      }

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const defaultSort = {
        id: 0,
        username : 0,
        round: 0,
        vendor_key: 0,
        game_name: 0,
        type: 0,
        amount: 0,
        prev_money: 0,
        money: 0,
        created_at: 0,
        game_type: 0
    }
    const [sortClass, setSortClass] = useState({
        id: 0,
        username : 0,
        round: 0,
        vendor_key: 0,
        game_name: 0,
        type: 0,
        amount: 0,
        prev_money: 0,
        money: 0,
        created_at: 0,
        game_type: 0
    })

    const [Input, setInput] = useState({
        username: "",
        userId: "",
        startDate: "",
        endDate: "",
        agentId: ""
    })

    const { username, userId, startDate, endDate, agentId } = Input

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }

    const [curPage, setCurPage] = useState(1);
    const getUserTrans = (page, perPage, sortKey) => {
        setSortClass({
            ...defaultSort,
            [sortKey]: sortClass[sortKey] === 0 ? 1 : (sortClass[sortKey] === 1 ? -1 : 1)
        })
        onSortType()
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }
        if( !isEmpty(getParamByUserList) ){
            
          const params = {
              page: page,
              perPage: perPage,
              isSearch: false,
              searchKey:getParamByUserList
            }            
          dispatch(getUserTransListAction(params, setPageInfo, setCurPage));
        } else{
            
            const params = {
                page: page,
                perPage: perPage,
                isSearch: true,
                sortKey: sortKey,
                sortType: sortType,
                searchKey: {
                    username: username,
                    userId: userId,
                    startDate: startDate,
                    endDate: endDate,
                    agentId: agentId,
                    searchType: findType
                }
            }
            dispatch(getUserTransListAction(params, setPageInfo, setCurPage));
        }
        
        
    }

    const getAgentList = () => {
        dispatch(getAgentListAction());
    }

    useEffect(() => {
        if (!isEmpty(loginid)&& !isEmpty(getParamByUserList)) {
            const params = {
                page: pageInfo.totalPage,
                perPage: 10,
                isSearch: false,
                searchKey:getParamByUserList
              }
            dispatch(getUserTransListAction(params, setPageInfo, setCurPage));            
            getAgentList();
            
        }else if( !isEmpty(loginid) ){
            getUserTrans(1, pageInfo.perPage);
            getAgentList();
        }
    }, [loginid, pageInfo.perPage])
    return (
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="">
                <div id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol className="breadcrumb float-xl-end">
                            
                            <li className="breadcrumb-item"><a>유저 관리</a></li>
                            <li className="breadcrumb-item active">트랜잭션 내역</li>
                        </ol>
                        <h1 className="page-header">트랜잭션 내역</h1>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">검색</h4>
                                    </div>
                                    <div className="panel-body">
                                        <form className="form-horizontal form-bordered">
                                            <div className="form-group row"><label className="col-md-4 col-form-label">상위 업체</label>
                                                <div className="col-md-8">
                                                    <div className="vtreeselect-dark vue-treeselect vue-treeselect--single vue-treeselect--searchable vue-treeselect--has-value vue-treeselect--open-below ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container">
                                                                <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                    name="agentId" value={agentId} onChange={onChange}
                                                                    style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                    <option value="" className="d-none">선택하세요</option>
                                                                    {
                                                                        agentList.map((item, index) => {
                                                                            return (
                                                                                <option value={item.id} className="" key={index}>{item.loginid}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row period"><label className="col-md-4 col-form-label">기간(시작 ~ 종료)</label>
                                                <div className="col-md-4">
                                                    <div className="form-floating mb-3 mb-md-0">
                                                        <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" style={{ width: "100%" }}>
                                                            <input type="text" autoComplete="off" name="" value={startDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly />
                                                            {
                                                                !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian />
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix">
                                                                <span className="el-input__suffix-inner"><i className="el-input__icon"></i>
                                                                </span>
                                                            </span>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-floating mb-3 mb-md-0 postInfo-container-item">
                                                        <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" style={{ width: "100%" }}>
                                                            <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                            {
                                                                !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian />
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix">
                                                                <span className="el-input__suffix-inner"><i className="el-input__icon"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">유저 아이디</label>
                                                <div className="col-md-8">
                                                    <div className="form-floating mb-3 mb-md-0">
                                                        <input type="text" placeholder="유저 아이디" 
                                                                className="form-control fs-13px h-45px" 
                                                                value={userId} name="userId" 
                                                                onChange={onChange} onKeyDown={onEnter} />
                                                        <label htmlFor="nickname" className="d-flex align-items-center fs-13px">유저 아이디</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">유저이름</label>
                                                <div className="col-md-8">
                                                    <div className="form-floating mb-3 mb-md-0">
                                                        <input type="text" placeholder="유저이름" 
                                                                className="form-control fs-13px h-45px" value={username} name="username" 
                                                                onChange={onChange} onKeyDown={onEnter}/>
                                                        <label htmlFor="nickname" className="d-flex align-items-center fs-13px">유저이름</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row"><label className="col-md-4 col-form-label">타입</label>
                                                <div className="col-md-8">
                                                    <div className="mb-3 mb-md-0">
                                                        {
                                                            searchType.map((item, index)=>{
                                                                return(
                                                                    <div className="form-check form-check-inline" key = {index}>
                                                                        <input type="checkbox" 
                                                                                value = {item.value}  onChange = {onCheckType} onKeyDown={onEnter} 
                                                                                className="form-check-input" />
                                                                        <label  className="form-check-label"> {item.name} </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="btn-group" style = {{width:"150px", marginRight:"15px"}}><button type="button"
                                                        className="btn btn-success btn-lg" onClick={() => getUserTrans(1, pageInfo.perPage)}> 검색 </button></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">트랜잭션 내역 ( 총 {transCount} 개 ) </h4>
                                    </div>
                                    <div className="panel-body">
                                        <div className="vgt-wrap">
                                            <div className="vgt-inner-wrap">
                                                <div className="vgt-fixed-header"></div>
                                                <div className="vgt-responsive agentList">
                                                    <div className="el-table el-table--fit el-table--scrollable-x el-table--enable-row-hover" style={{ width: "100%" }}>
                                                        
                                                        <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                            <table cellSpacing="0" cellPadding="0" border="0"
                                                                className="el-table__header" style={{ minWidth: "1340px"}}>

                                                                {/* <tr name="el-table_29_column_208" width="5%" />
                                                                <tr name="el-table_29_column_209" width="10%" />
                                                                <tr name="el-table_29_column_210" width="7%" />
                                                                <tr name="el-table_29_column_211" width="10%" />
                                                                <tr name="el-table_29_column_212" width="8%" />
                                                                <tr name="el-table_29_column_213" width="10%" />
                                                                <tr name="el-table_29_column_214" width="8%" />
                                                                <tr name="el-table_29_column_215" width="8%" />
                                                                <tr name="el-table_29_column_216" width="8%" />
                                                                <tr name="el-table_29_column_217" width="8%" />
                                                                <tr name="el-table_29_column_218" width="8%" />
                                                                <tr name="el-table_29_column_219" width="10%" />
                                                                <tr name="el-table_29_column_220" width="6%" /> */}


                                                                <thead className="has-gutter">
                                                                    <tr className="">
                                                                        <th colSpan="1" rowSpan="1"
                                                                            className="el-table_29_column_208  is-center   is-leaf" style={{width:"5%"}}>
                                                                            <div className="cell">번호</div>
                                                                        </th>
                                                                        {
                                                                            header.map((item, index) => {
                                                                                return (
                                                                                    <th colSpan="1" rowSpan="1" className={`${item.className}  is-center   is-leaf is-sortable ${ sortClass[item.property] === 0 ? "" : (sortClass[item.property] === 1 ? "ascending" : "descending") }`} key={index}>
                                                                                        <div className="cell" onClick={() => getUserTrans(pageInfo.curPage, pageInfo.perPage, item.property)}>{item.name}
                                                                                            <span className="caret-wrapper">
                                                                                                <i className="sort-caret ascending"></i>
                                                                                                <i className="sort-caret descending"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="has-gutter">
                                                                    {
                                                                        userTransList.map((item, index) => {
                                                                            return (
                                                                                <tr className="" key={index}>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_208  is-center   is-leaf">
                                                                                        <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_209  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" >{item.user_id}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_210  is-center   is-leaf is-sortable" >
                                                                                        <div className="cell" >{item.username}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_211  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" >{item.round}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_212  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" >{item.provider_name}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_213  is-center   is-leaf is-sortable">
                                                                                        <div className="cell">{item.game_name}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_214  is-center   is-leaf is-sortable">
                                                                                        <div className="cell">{item.game_type}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_215  is-center   is-leaf is-sortable">
                                                                                        <div className={`cell ${getTranStatusClassName(item.type)}`} style={{ width: "50%", borderRadius: "5px" }} >{getTranStatus(item.type)}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_216  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.amount).toLocaleString()}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_217  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.prev_money).toLocaleString()}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_218  is-center   is-leaf is-sortable">
                                                                                        <div className="cell" style = {{textAlign:"end", marginRight: "10px"}}>{Number(item.money).toLocaleString()}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td colSpan="1" rowSpan="1"
                                                                                        className="el-table_29_column_219  is-center   is-leaf is-sortable">
                                                                                        <div className="cell">{dateFormation(item.created_at, 3)}<br/>{dateFormation(item.created_at, 10)}
                                                                                        </div>
                                                                                    </td>
                                                                                    
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                            <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                                <span className="el-table__empty-text" style={{display:isEmpty(userTransList)? "flex" : "none"}}>데이터가 없습니다.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Pagenation
                                                    getList={getUserTrans}
                                                    pageInfo={pageInfo}
                                                    setPageInfo={setPageInfo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
                <div className="el-dialog__wrapper stress" style={{ display: "none" }}>
                    <div role="dialog" aria-modal="true" aria-label="게임 라운드 상세 기록" className="el-dialog" style={{ marginTop: "15vh" }}>
                        <div className="el-dialog__header"><span className="el-dialog__title">게임 라운드 상세 기록</span><button type="button"
                            aria-label="Close" className="el-dialog__headerbtn">
                            <i className="el-dialog__close el-icon el-icon-close"></i>
                        </button></div>


                    </div>
                </div>
            </div>
        </section>
    );
};

export default TransactionHistoryDashboard;
