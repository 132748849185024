import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideMenuItem from "./SideMenuItem";

const SideMenuGroup = (props) => {
    const { menuGroup, onValue, active } = props;

    const onActive = (value) => {
        onValue(value);
    }
  
  return (
          <div>
            <div id="1" index="/agents" className="menu-item has-sub expand">
              <div className={`menu-link ${active && "menu-active-link"}`} onClick={() => onActive(menuGroup.value)}>
                  <div className="menu-icon"><i className="fa fa-sitemap"></i></div>
                  <div className="menu-text" style={{cursor:"pointer"}}> {menuGroup.name} </div>
                  <div className="menu-caret"></div>
              </div>
              <div className="menu-submenu d-block">
                {
                    menuGroup.subMenus.map((menu, index) => {
                        return <SideMenuItem menu={menu} active={active} key={index}/>
                    })
                }
              </div>
            </div>
          </div>
  );
};

export default SideMenuGroup;
