import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getAgentLevel, toastr } from "../../../utils";
import isEmpty from "../../../validation/is-empty";
import Passwordconfirm from "../../modal/PasswordConfirm";
import { changeApiEndpointAction, changeRefreshTokenAction, changeUserInfoAction } from "../../../redux/action/settingAction";
import NameChange from "../../modal/NameChange";



const SettingDashboard = () => {
    const dispatch = useDispatch(); 
    const list = useSelector((state) => state.user.userData);
    const { loginid, username, agent_type, money, api_key, api_endpoint } = list;
    
    const [ modalActive, setModalActive ] = useState(false);
    const [ nameActive, setNameActive ] = useState(false);

    const onNameActive = () =>{
        setNameActive(!nameActive)
    }

    const [ tokenActive, setTokenActive ] = useState(false);

    const onTokenActive = () =>{
        setTokenActive(!tokenActive)
    }

    const [ urlActive, setUrlActive ] = useState(false);

    const onUrlActive = () =>{
        setUrlActive(!urlActive)
    }

    const onModalActive = () => {
        setModalActive(!modalActive);
    }
    
    const onChangeUserInfo = (e) => {
        const params = {
            username: inputUsername
        }
        dispatch(changeUserInfoAction(params, onNameActive));
    }
    

    const onSubmit = (e) => {
        dispatch(changeRefreshTokenAction(onTokenActive));        
    }

    const [Input, setInput] = useState({
        inputApi_endpoint: api_endpoint,
        inputApi_key: api_key,
        inputUsername: username
      })
      
      const { inputApi_endpoint, inputApi_key, inputUsername } = Input

      const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }
    const onChangeApiUrl = (e) => {
        e.preventDefault();
        if(isEmpty(api_endpoint)){
            return toastr.warning("API URL을 입력해주세요.")
        }
        const params = {
            api_key:api_key, 
            api_endpoint:inputApi_endpoint
        }
        dispatch(changeApiEndpointAction(params, onUrlActive));
    }

    useEffect(() => {
        if(!isEmpty(list)){
            setInput({
                inputApi_endpoint: api_endpoint,
                inputApi_key: api_key,
                inputUsername: username
            })
        }
    }, [list])

    const tokenRef = useRef();

    const onCopy = () => {
        tokenRef.current.focus();
        tokenRef.current.setSelectionRange(0, tokenRef.current.value.length);
        const success = document.execCommand("copy");
        if(success){
            toastr.success("복사되엇습니다.")
        }
    }
  
  return (
    <div>
        <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
            <div data-v-e072c2c8="">
                <div id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol className="breadcrumb float-xl-end">
                            {/* <li className="breadcrumb-item"><a>Home</a></li> */}
                            <li className="breadcrumb-item active">설정 및 정보</li>
                        </ol>
                        <h1 className="page-header">설정 및 정보</h1>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">기본 정보</h4>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    <span className="form-control input-background fs-13px h-50px">{loginid}</span>
                                                    <label htmlFor="balance" className="d-flex align-items-center fs-13px">아이디</label>
                                                    {/* <span className="input-group-text">POINT</span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    {/* <span className="form-control input-background fs-13px h-50px">{username}</span> */}
                                                    <input type="text" 
                                                        name="inputUsername" 
                                                        id="username" 
                                                        placeholder="이름" 
                                                        onChange={onChange} 
                                                        value={inputUsername} 
                                                        className="form-control fs-13px h-45px"
                                                        />
                                                    <label htmlFor="balance" className="d-flex align-items-center fs-13px">이름</label>
                                                    {/* <span className="input-group-text">POINT</span> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    <span className="form-control input-background fs-13px h-50px">{getAgentLevel(agent_type)}</span>
                                                    <label htmlFor="balance" className="d-flex align-items-center fs-13px">업체 등급</label>
                                                    {/* <span className="input-group-text">POINT</span> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    <span className="form-control input-background fs-13px h-50px">{Number(money).toLocaleString()}</span>
                                                    <label htmlFor="balance" className="d-flex align-items-center fs-13px">보유포인트</label>
                                                    <span className="input-group-text">POINT</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="set_btn" style={{backgroundColor:"#081c2f"}}>
                                        <div className="set_btn_info">                                            
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-success" onClick = {onModalActive}> 비밀번호 변경 </button>                                                
                                            </div>
                                        </div>
                                        <div className="set_btn_info">                                            
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-success" onClick = {onNameActive}> 이름변경 </button>                                                        
                                            </div>
                                        </div>                                        
                                    </div>
                                    
                                </div>
                                
                                <Passwordconfirm onModalActive={onModalActive} modalActive={modalActive} />
                                <NameChange onNameActive={onNameActive} nameActive={nameActive} onChangeUserInfo={onChangeUserInfo}/>
                               
                                
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">API 정보</h4>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    <input type="text" 
                                                        name="inputApi_key" 
                                                        id="token" 
                                                        placeholder="API TOKEN"  
                                                        value={inputApi_key} 
                                                        ref={tokenRef}
                                                        className="form-control fs-13px h-45px" onChange={onChange}/>
                                                    <label htmlFor="token" className="d-flex align-items-center fs-13px">API TOKEN</label>
                                                    <button type="button" className="btn btn-primary" onClick={onTokenActive}>재발급</button>
                                                    <button type="button" className="btn btn-primary" onClick={onCopy}>복사</button>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="input-group form-floating mb-3 mb-md-0">
                                                    <input type="text" 
                                                        name="inputApi_endpoint" 
                                                        id="api_url" 
                                                        placeholder="API URL" 
                                                        onChange={onChange} 
                                                        value={inputApi_endpoint} 
                                                        className="form-control fs-13px h-45px"/>
                                                    <label htmlFor="api_url" className="d-flex align-items-center fs-14px">API URL</label>
                                                    <button type="button" className="btn btn-primary" onClick={onUrlActive}>변 경</button>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-v-631daf08="" className="ins-progress" style={{opacity: "0", display: "none", position: "fixed", height: "3px"}}></div>
                    <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </div>


            {/* -------------------API Token재발급확인-------------------- */}

            <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: tokenActive ? "block" : "none", zIndex: "11111" }}>
                <div className = "modal-backgroud" onClick={onTokenActive}></div>
                    <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: tokenActive ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
                        <div className="el-dialog__header">
                            <span className="el-dialog__title">변경하시겠습니까?</span>
                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                <i className="fa fa-close" style={{ color: "white" }} onClick={onTokenActive}></i>
                            </button>
                        </div>
                        <div className="el-dialog__footer">
                            <div data-v-12fb228d="" className="dialog-footer">
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onSubmit}>
                                    <span> 확인 </span>
                                </button>
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onTokenActive}>
                                    <span> 취소 </span>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>


            {/* -------------------API URL변경확인-------------------- */}
            
            <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: urlActive ? "block" : "none", zIndex: "11111" }}>
                <div className = "modal-backgroud" onClick={onUrlActive}></div>
                    <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: urlActive ? "block" : "none", zIndex: "11111",marginTop: "15vh" }}>
                        <div className="el-dialog__header">
                            <span className="el-dialog__title">변경하시겠습니까?</span>
                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                <i className="fa fa-close" style={{ color: "white" }} onClick={onUrlActive}></i>
                            </button>
                        </div>
                        <div className="el-dialog__footer">
                            <div data-v-12fb228d="" className="dialog-footer">
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={onChangeApiUrl}>
                                    <span> 확인 </span>
                                </button>
                                <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onUrlActive}>
                                    <span> 취소 </span>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
            
        </section>
    </div>
  );
};

export default SettingDashboard;
