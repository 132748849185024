import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { getAgentAction } from "../../../redux/action/userAction";
import { dateFormation, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import isEmpty from "../../../validation/is-empty";
import axios from "axios";
import MonthlyFigureTreeNode from "./MonthlyFigureTreeNode";
import { SET_LOADING } from "../../../redux/action/types";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';

function MonthlyFigureDashboard() {
    const dispatch = useDispatch();
    const list = useSelector((state) => state.user.userData);
    const agentList = useSelector((state) => state.figureManage.getAgentList);
    const [monthlyFigList, setMonthlyFigList] = useState([])
    const { loginid } = list;

    const [Input, setInput] = useState({
        startDate: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 11),
        endDate: dateFormation(Date.now(), 11), 
        agentId: ""
    });

    const { startDate, endDate, agentId } = Input;

    const onChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...Input,
            [name]: value
        })
    }

    const onPickDate = (name, date) => {
        setInput({
            ...Input,
            [name]: dateFormation(date, 3)
        })
    }

    const clearDate = (name) => {
        setInput({
            ...Input,
            [name]: ""
        })
    }

    const getCalcMonthlyFigure = (startDate, loginid = "", endDate = "") => {

        const params = {
            start_time: startDate,
            end_time: endDate,
            loginid: loginid,
            agentId: agentId
        }
        // dispatch(userFigureAction( params ));
        return new Promise(resolve => {
            dispatch({
                type: SET_LOADING,
                payload: true
              })
            axios.post(api_url + "/api/calc/calc_month/userlist", params)
                .then(res => {
                    dispatch({
                        type: SET_LOADING,
                        payload: false
                      })
                    if (res.data.success) {
                        resolve(res.data.data);
                    }
                    else {
                        toastr.warning(res.data.message)
                    }
                }).catch((err) => {
                    dispatch({
                        type: SET_LOADING,
                        payload: false
                      })
                    console.log("err", err)
                });
        })
    }

    const onSearch = () => {
        getCalcMonthlyFigure(startDate, "", endDate)
            .then(res => {
                setMonthlyFigList(res);
            })
    }
    const get_figureAgentList = () => {
        dispatch(getAgentAction());
    }
    useEffect(() => {
        if (!isEmpty(loginid)) {
            get_figureAgentList()
            onSearch();

        }
    }, [loginid]);
    return (
        <Fragment>
            <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
                <div data-v-e072c2c8="" id="content" className="app-content">
                    <div className = "main_app_content">
                        <ol className="breadcrumb float-xl-end">
                            
                            <li className="breadcrumb-item"><a>정산관리</a></li>
                            <li className="breadcrumb-item active">월별 정산</li>
                        </ol>
                        <h1 className="page-header">월별 정산</h1>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">검색</h4>
                                    </div>
                                    <div className="panel-body">
                                        <form className="form-horizontal form-bordered">
                                            <div className="form-group row"><label className="col-md-4 col-form-label">해당 업체</label>
                                                <div className="col-md-8">
                                                    <div className="
                                                        vtreeselect-dark
                                                        vue-treeselect
                                                        vue-treeselect--single
                                                        vue-treeselect--searchable
                                                        vue-treeselect--has-value
                                                        vue-treeselect--open-below
                                                        ">
                                                        <div className="vue-treeselect__control">
                                                            <div className="vue-treeselect__input-container">
                                                                <select placeholder="선택하세요." className="vue-treeselect__input"
                                                                        value = {agentId} name = "agentId" onChange = {onChange}
                                                                        style={{ backgroundColor: "rgb(7, 19, 29)", width: "100%" }}>
                                                                    <option value="" className="d-none">선택하세요</option>
                                                                    {
                                                                        agentList.map((item, index) => {
                                                                            return (
                                                                                <option value={item.loginid} className="" key={index}>{item.loginid}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="vue-treeselect__menu-container" style={{ zIndex: "999" }}></div>
                                                    </div>
                                                    {/* <div className="mt-3 mb-3 mb-md-0">
                                                        <div className="form-check form-check-inline"><input id="low_agent_allow" type="checkbox" className="form-check-input" /><label htmlFor="low_agent_allow" className="form-check-label">하부 업체 포함 검색 여부</label></div>
                                                    </div> */}
                                                </div>
                                            </div>                                            
                                            <div className="form-group row period"><label className="col-md-4 col-form-label">기간(시작 ~ 종료)</label>
                                                <div className="col-md-4">
                                                    <div className="form-floating mb-3 mb-md-0">
                                                        <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={startDate}  placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                            {
                                                                !isEmpty(startDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("startDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="startDate" onOk={(startDate) => onPickDate("startDate", startDate)} showMeridian/>
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix"><span className="el-input__suffix-inner" /><i className="el-input__icon"></i></span>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-floating mb-3 mb-md-0">
                                                        <div className="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date" style={{ width: "100%" }}>
                                                        <input type="text" autoComplete="off" name="" value={endDate} placeholder="날짜를 입력해주세요." className="el-input__inner" readOnly/>
                                                            {
                                                                !isEmpty(endDate) && <i className="fa fa-close date-cancel" onClick={() => clearDate("endDate")}></i>
                                                            }
                                                            <Stack spacing={10} direction="column" alignItems="flex-start">
                                                                <DatePicker format="yyyy-MM-dd" name="endDate" onOk={(endDate) => onPickDate("endDate", endDate)} showMeridian/>
                                                            </Stack>
                                                            <span className="el-input__prefix">
                                                                <i className="el-input__icon fa fa-calendar-clock"></i>
                                                            </span>
                                                            <span className="el-input__suffix"><span className="el-input__suffix-inner" /><i className="el-input__icon"></i></span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="btn-group" style = {{width:"150px", marginRight:"15px"}}>
                                                        <button type="button" className="btn btn-success btn-lg" onClick = {onSearch}> 검색 </button>
                                                </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="panel panel-inverse">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">월별 정산 </h4>
                                    </div>
                                    <div className="panel-body">
                                        <div className="vgt-wrap">
                                            <div className="vgt-inner-wrap">
                                                <div className="vgt-fixed-header"></div>
                                                <div className="vgt-responsive agentList">
                                                    <div className="el-table el-table--fit el-table--enable-row-hover" style={{ width: "100%" }}>                                                      
                                                            
                                                        <div className="el-table__header-wrapper" style = {{overflow:"auto"}}>
                                                            <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{ minWidth: "1200px"}}>

                                                                    {/* <col name="el-table_40_column_298" width="20%"/>
                                                                    <col name="el-table_40_column_299" width="10%"/>
                                                                    <col name="el-table_40_column_300" width="15%"/>
                                                                    <col name="el-table_40_column_301" width="10%"/>
                                                                    <col name="el-table_40_column_302" width="10%"/>
                                                                    <col name="el-table_40_column_303" width="10%"/>
                                                                    <col name="el-table_40_column_304" width="10%"/>
                                                                    <col name="gutter" width="0" /> */}
                                                                <thead className="has-gutter">
                                                                    <tr className="">
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_298  is-center  is-leaf is-sortable" style={{width:"20%"}}>
                                                                            <div className="cell" style={{right:"15%"}}>날짜
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_299  is-center   is-leaf is-sortable">
                                                                            <div className="cell">회원등급
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_300  is-center   is-leaf">
                                                                            <div className="cell">이름</div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_301  is-center   is-leaf is-sortable">
                                                                            <div className="cell">베팅금 
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_302  is-center   is-leaf is-sortable">
                                                                            <div className="cell">당첨금
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_303  is-center   is-leaf is-sortable">
                                                                            <div className="cell">수익금
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                        <th colSpan="1" rowSpan="1" className="el-table_40_column_304  is-center   is-leaf is-sortable">
                                                                            <div className="cell">당첨후 금액
                                                                            {/* <span className="caret-wrapper"><i className="sort-caret ascending"></i><i className="sort-caret descending"></i></span> */}
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        monthlyFigList.map((item, index) => {
                                                                            return (
                                                                                <MonthlyFigureTreeNode
                                                                                    data={item}
                                                                                    getCalcMonthlyFigure={getCalcMonthlyFigure}
                                                                                    level={0}
                                                                                    startTime={startDate}
                                                                                    key={item.id}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                    <tr></tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="el-table__empty-block" style={{ height: "100%", width: "898px" }}>
                                                                <span className="el-table__empty-text" style = {{display: monthlyFigList.length == 0? "flex" : "none"}}> 데이터가 없습니다.</span>
                                                            </div>

                                                        </div>




                                                        <div className="el-table__column-resize-proxy" style={{ display: "none" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
                </div>
            </section>
        </Fragment>
    );
}

export default MonthlyFigureDashboard;