export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const SET_AUTH = "SET_AUTH";
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const LOGOUT_REQUEST_ERROR = "LOGOUT_REQUEST_ERROR";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const SIGNUP_REQUEST_ERROR = "SIGNUP_REQUEST_ERROR";
export const SIGNUP_REQUEST_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";

export const REQUEST = "REQUEST";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";

export const USER_REQUEST = "USER_REQUEST";
export const USER_REQUEST_ERROR = "USER_REQUEST_ERROR";
export const USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS";


export const SET_GET_AGENT = "SET_GET_AGENT"
export const SET_DASHIBOARD_INFO = "SET_DASHIBOARD_INFO"




// --------------------------- Agent Type ---------------------------- //

export const SET_AGENTLIST = "SET_AGENTLIST";
export const SET_SEARCHAGENTLIST = "SET_SEARCHAGENTLIST";
export const SET_AGENTTREE = "SET_AGENTTREE";
export const SET_AGENT = "SET_AGENT";
export const SET_POINT_TRANSHISTORY = "SET_POINT_TRANSHISTORY";
export const SET_JOIN_AGENT_LIST = "SET_JOIN_AGENT_LIST";
export const GET_HEADER_INFO = "GET_HEADER_INFO";
export const SET_POINT_TRANSACTION_MOVE = "SET_POINT_TRANSACTION_MOVE";






// --------------------------- GameManage Type ---------------------------- //
export const SET_GAMECOMPANYLIST = "SET_GAMECOMPANYLIST";
export const SET_GAMELIST = "SET_GAMELIST";


// ---------------------------- UserManage Type ---------------------------- //

export const SET_USERLIST = "SET_AGENTLIST";
export const SET_LOGIN_HIST = "SET_LOGIN_HIST";
export const SET_BONUS_HIST = "SET_BONUS_HIST";
export const SET_USERTRANSLIST = "SET_USERTRANSLIST";
export const SET_USERGAMELIST = "SET_USERGAMELIST";
export const SET_GETAGENTLIST = "SET_GETAGENTLIST";
export const SET_GETGAMELIST = "SET_GETGAMELIST";
export const SET_TRANSACTION_MOVE = "SET_TRANSACTION_MOVE";
export const SET_SOCKET_DATA = "SET_SOCKET_DATA";
export const SET_SMART_SOCKET_DATA = "SET_SMART_SOCKET_DATA";

// ---------------------------- FigureManage Type ---------------------------- //

export const SET_CALC_USER_FIGURE = "SET_CALC_USER_FIGURE";
