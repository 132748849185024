import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import MainDashboard from "../../components/common/Dashboard";
const Dashboard = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <MainDashboard/>
      <Sidebar pageValue="/dashboard"/>
    </div>
  )
}

export default Dashboard;