import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import axios from "axios";
import AgentTable from "./AgentTable";
import AgentTreeNode from "./AgentTreeNode";
import isEmpty from "../../../validation/is-empty";
import { toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";


const AgentTreeViewDashboard = () => {
  
  const list = useSelector((state) => state.user.userData);

  const { loginid } = list

  const [ bonsas, setBonsas ] = useState([]);

  const [ activeAgent, setActiveAgent ] = useState({});

  

  const getChildren = (loginid, isSearch) => {
    let children = [];
    return new Promise(resolve => {
      axios.post(api_url + "/api/enterprise/treelist", { 
        loginid: loginid, 
        isSearch: isSearch
      }).then(res => {
        if(res.data.success){
          resolve(res.data.data);
        }
        else{
          toastr.warning(res.data.message)
        }
      }).catch((err) => {
        console.log('err: ', err);
        if(err.responsive && (err.responsive.Status === 401)){
          
        }
      })
    });
  }

  useEffect(() => {
    if(!isEmpty(loginid)){
      getChildren(loginid, "true")
      .then(myChildren => {
        setBonsas(myChildren);
        setActiveAgent(myChildren[0])
      });
    }
  }, [loginid])
  
  return (
    <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
      <div data-v-12fb228d="" data-v-e072c2c8="">
        <div data-v-12fb228d="" id="content" className="app-content">
          <div data-v-12fb228d="" className = "main_app_content">
            <ol data-v-12fb228d="" className="breadcrumb float-xl-end">
                
                <li data-v-12fb228d="" className="breadcrumb-item"><a data-v-12fb228d="">업체 관리</a></li>
                <li data-v-12fb228d="" className="breadcrumb-item active">업체 트리뷰</li>
            </ol>
            <h1 data-v-12fb228d="" className="page-header">업체 트리뷰</h1>
            <div data-v-12fb228d="" className="row">
              <div data-v-12fb228d="" className="col-6">
                <div data-v-12fb228d="" className="panel panel-inverse">
                  <div data-v-12fb228d="" className="panel-heading">
                    <h4 data-v-12fb228d="" className="panel-title">업체 트리뷰</h4>
                  </div>
                  <div data-v-12fb228d="" className="panel-body">
                    <div data-v-12fb228d="" className="mb-2">
                      <div data-v-12fb228d="" className="el-table tree_view el-table--fit el-table--enable-row-hover el-table--enable-row-transition" style={{width: "100%"}}>
                        <div className="hidden-columns">
                            <div data-v-12fb228d=""></div>
                        </div>
                        <div className="el-table__header-wrapper">
                            <table cellSpacing="0" cellPadding="0" border="0" className="el-table__header" style={{width: "90%", margin:"auto"}}>
                                
                                  {/* <tr name="el-table_24_column_172" width="693"/>
                                  <tr name="gutter" width="0"/> */}
                                
                                <thead className="has-gutter">
                                  <tr className="">
                                    <th colSpan="1" rowSpan="1" className="el-table_24_column_172  is-center   is-leaf">
                                        <div className="cell"></div>
                                    </th>
                                    <th className="gutter" style={{width: "0px", display: "none"}}></th>
                                  </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="el-table__body-wrapper is-scrolling-none">
                          <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{width: "90%", margin:"auto"}}>
                            
                                {/* <tr name="el-table_24_column_172" width="693"/> */}
                            
                            <tbody>
                              {
                                bonsas.map((bonsaItem, index) => {
                                  return <AgentTreeNode 
                                            data={bonsaItem} 
                                            level={1} 
                                            activeAgent={activeAgent}
                                            setActiveAgent={setActiveAgent} 
                                            getChildren={getChildren}
                                            key={index}/>
                                })
                              }
                              
                              </tbody>
                          </table>
                          <div className="el-table__empty-block" style={{ height: "50%", width: "898px" }}><span className="el-table__empty-text"></span></div>
                        </div>
                        <div className="el-table__column-resize-proxy" style={{display: "none"}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AgentTable data={activeAgent} loginId = {loginid} />
            </div>
          </div>
          <div data-v-12fb228d="" data-v-631daf08="" className="ins-progress" style={{opacity: "0", display: "none", position: "fixed", height: "3px"}}></div>
          <div data-v-12fb228d="" id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
        </div>         
      </div> 
    </section>
  );
};

export default AgentTreeViewDashboard;
