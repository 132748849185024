import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk  from 'redux-thunk';
import combineReducers from '../redux/reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';

const initialState = {}
const store = createStore(
    combineReducers,
    // initialState,
    composeWithDevTools(applyMiddleware(ReduxThunk))
);

export default store;