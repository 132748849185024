import React from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import BettingLimitDashboard from "../../components/common/gameManage/BettingLimit";
const BettingLimit = () => {
  return (
    <div data-v-c97c2a3e="" className="app app-header-fixed app-sidebar-fixed app-gradient-enabled">
      <Header/>
      <BettingLimitDashboard/>
      <Sidebar pageValue = "gameManage"/>
    </div>
  )
}

export default BettingLimit;