import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from "../../../validation/is-empty";
import { changeAgentStatusAction, getJoinAgentListAction, changeAgentStatus_delAction } from "../../../redux/action/agentAction";
import Pagenation from "../../pagenation/pagenation";
import { dateFormation, getAgentLevel, getAgentLevelClass, getAgentLevelColClass } from "../../../utils";

const JoinConsentRequestDashboard = () => {
    const dispatch = useDispatch();
    const [joinLoginId, setJoinLoginId] = useState("");
    const [joinModal, setJoinModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const list = useSelector((state) => state.user.userData);
    const joinList = useSelector((state) => state.agent.joinRequestList);
    
    const onClickJoin = (loginId) => {
        setJoinLoginId(loginId);
        onJoinModal();
    }

    const onClickDel = (loginId) => {
        setJoinLoginId(loginId);
        onDeleteModal();
    }

    const onJoinModal = () => {
        setJoinModal(!joinModal)
    }

    const onDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const { loginid } = list;
    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [curPage, setCurPage] = useState(1);
    const getJoinAgentList = (page, perPage, sortKey) => {
        if (page > pageInfo.totalPage) {
            page = pageInfo.totalPage;
        }
        if (page < 1) {
            page = 1;
        }

        const params = {
            loginid: loginid,
            page: page,
            perPage: perPage,
            sortKey: sortKey           
        }
        dispatch(getJoinAgentListAction(params, setPageInfo, setCurPage));
    }


    const  onChangeAgentState = (type) => {        
        const params = {
            loginid: joinLoginId,
            type: type
        }
        changeAgentStatusAction(params, onJoinModal)
        .then(() => {
            getJoinAgentList(pageInfo.curPage, pageInfo.perPage);
        });
    }

    const  onChangeAgentState_del = (type) => {        
        const params = {
            loginid: joinLoginId,
            type: type
        }
        changeAgentStatus_delAction(params, onDeleteModal)
        .then(() => {
            getJoinAgentList(pageInfo.curPage, pageInfo.perPage);
        });
    }



    useEffect(() => {
        if (!isEmpty(loginid)) {
            getJoinAgentList(1, pageInfo.perPage);
        }
    }, [loginid, pageInfo.perPage])
  
  return (
    <section data-v-e072c2c8="" data-v-c97c2a3e="" className="app-main">
    <div data-v-1df2cd06="" data-v-e072c2c8="">
        <div data-v-1df2cd06="" id="content" className="app-content">
            <div data-v-1df2cd06="" className = "main_app_content">
                <ol data-v-1df2cd06="" className="breadcrumb float-xl-end">
                    
                    <li data-v-1df2cd06="" className="breadcrumb-item">업체 관리</li>
                    <li data-v-1df2cd06="" className="breadcrumb-item active">가입승인 요청</li>
                </ol>
                <h1 data-v-1df2cd06="" className="page-header mb-3">가입승인 요청</h1>
                <div data-v-1df2cd06="" className="row">
                    <div data-v-1df2cd06="" className="col-12">
                        <div data-v-1df2cd06="" className="panel panel-inverse">
                            <div data-v-1df2cd06="" className="panel-heading">
                                <h4 data-v-1df2cd06="" className="panel-title">가입승인 요청 (총 {joinList.length}개)</h4>
                            </div>
                            <div data-v-1df2cd06="" className="panel-body">
                                <div data-v-1df2cd06="" className="vgt-wrap ">
                                    <div data-v-1df2cd06="" className="vgt-inner-wrap">
                                        <div data-v-1df2cd06="" className="vgt-responsive agentList">
                                            <div data-v-1df2cd06="" className="el-table el-table--fit el-table--enable-row-hover" style={{width: "100%"}}>
                                                <div className="hidden-columns">
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                    <div data-v-1df2cd06=""></div>
                                                </div>                                                
                                                <div className="el-table__body-wrapper is-scrolling-none" style = {{overflow:"auto"}}>
                                                    <table cellSpacing="0" cellPadding="0" border="0" className="el-table__body" style={{minWidth: "1307px", width:"90%"}}>
                                                            {/* <tr name="el-table_26_column_184" width="10%"/>
                                                            <tr name="el-table_26_column_185" width="15%"/>
                                                            <tr name="el-table_26_column_186" width="15%"/>
                                                            <tr name="el-table_26_column_187" width="15%"/>
                                                            <tr name="el-table_26_column_188" width="15%"/>
                                                            <tr name="el-table_26_column_189" width="15%"/>
                                                            <tr name="el-table_26_column_190" width="10%"/>                                                            
                                                            <tr name="gutter" width="0"/> */}
                                                        <thead className="has-gutter">
                                                            <tr className="">
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_184  is-center   is-leaf" style={{width:"10%"}}>
                                                                    <div className="cell">번호</div>
                                                                </th>
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_185  is-center   is-leaf">
                                                                    <div className="cell">업체</div>
                                                                </th>
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_186  is-center   is-leaf">
                                                                    <div className="cell">소속</div>
                                                                </th>
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_187  is-center   is-leaf">
                                                                    <div className="cell">등급</div>
                                                                </th>                                                                
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_188  is-center   is-leaf">
                                                                    <div className="cell">포인트</div>
                                                                </th>
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_189  is-center descending  is-leaf">
                                                                    <div className="cell">신청일자</div>
                                                                </th>
                                                                <th colSpan="1" rowSpan="1" className="el-table_26_column_190  is-center option_btn  is-leaf">
                                                                    <div className="cell">기능</div>
                                                                </th>
                                                                <th className="gutter" style={{width: "0px", display: "none"}}>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {
                                                                    joinList.map((item, index)=>{
                                                                        return(
                                                                            <tr className="" key={index}>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_184  is-center   is-leaf">
                                                                                    <div className="cell">{Number(pageInfo.perPage) * (Number(curPage) - 1) + index + 1}</div>
                                                                                </td>                                                                
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_185  is-center   is-leaf">
                                                                                    <div className="cell">{item.loginid}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_186  is-center   is-leaf">
                                                                                    <div className="cell">{item.parent_loginid}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_187  is-center   is-leaf">
                                                                                    <div className={`cell ${getAgentLevelColClass(item.agent_type)} `}>{getAgentLevel(item.agent_type)}</div>
                                                                                </td>                                                                
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_188  is-center   is-leaf">
                                                                                    <div className="cell">{Number(item.money).toLocaleString()}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_189  is-center descending  is-leaf">
                                                                                    <div className="cell">{dateFormation(item.created_at, 3)}<br/>{dateFormation(item.created_at, 10)}</div>
                                                                                </td>
                                                                                <td colSpan="1" rowSpan="1" className="el-table_26_column_190  is-center option_btn  is-leaf">
                                                                                <div className="cell" style={{display:"flex"}}>
                                                                                        <button data-v-5e3e41f0=""
                                                                                                type="button"                                                                                                
                                                                                                className="el-button el-button--primary el-button--mini"
                                                                                                onClick = {()=>onClickJoin(item.loginid)}
                                                                                                style={{ marginBottom: "3px" }}><span>승인</span></button>
                                                                                        <button data-v-5e3e41f0=""
                                                                                                type="button"                                                                                                
                                                                                                className="el-button el-button--danger el-button--mini"
                                                                                                onClick = {()=>onClickDel(item.loginid)}
                                                                                                style={{ marginBottom: "3px" }}><span>거절</span></button>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="gutter" style={{width: "0px", display: "none"}}>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                
                                                            
                                                        </tbody>
                                                    </table>
                                                    <div className="el-table__empty-block" style={{display: joinList.length == 0 ? "flex" : "none", height: "100%", width: "1407px"}}>
                                                      <span className="el-table__empty-text">데이터가 없습니다</span>
                                                    </div>                                                    
                                                </div>
                                                <div className="el-table__column-resize-proxy" style={{display: "none"}}></div>
                                                <div className="el-loading-mask" style={{display: "none"}}>
                                                    <div className="el-loading-spinner">
                                                      <svg viewBox="25 25 50 50" className="circular">
                                                        <circle cx="50" cy="50" r="20" fill="none" className="path"></circle>
                                                      </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Pagenation
                                        getList={getJoinAgentList}
                                        pageInfo={pageInfo}
                                        setPageInfo={setPageInfo} />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-1df2cd06="" data-v-631daf08="" className="ins-progress" style={{opacity: "0", display: "none", position: "fixed", height: "3px"}}></div>
            <div data-v-1df2cd06="" id="footer" className="app-footer mx-0 px-0"> Copyright © TOPGAME Admin Corp. All Rights Reserved. </div>
        </div>
      </div>
      {/* ----------------가입승인요청 확인----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: joinModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onJoinModal}></div>
                <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: joinModal ? "block" : "none", zIndex: "11111", marginTop: "15vh" }}>
                    <div className="el-dialog__header">
                        <span className="el-dialog__title">승인하시겠습니까?</span>
                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                            <i className="fa fa-close" style={{ color: "white" }} onClick={onJoinModal}></i>
                        </button>
                    </div>
                    <div className="el-dialog__footer">
                        <div data-v-12fb228d="" className="dialog-footer">
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={()=>onChangeAgentState(true)}>
                                <span> 확인 </span>
                            </button>
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onJoinModal}>
                                <span> 취소 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    {/* ----------------가입거절요청 확인----------------- */}
      <div data-v-12fb228d="" className="el-dialog__wrapper" style={{ display: deleteModal ? "block" : "none", zIndex: "11111" }}>
      <div className = "modal-backgroud" onClick={onDeleteModal}></div>
                <div role="dialog" aria-modal="true" aria-label="삭제" className="el-dialog" style={{ display: deleteModal ? "block" : "none", zIndex: "11111", marginTop: "15vh"  }}>
                    <div className="el-dialog__header">
                        <span className="el-dialog__title">거절하시겠습니까?</span>
                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                            <i className="fa fa-close" style={{ color: "white" }} onClick={onDeleteModal}></i>
                        </button>
                    </div>
                    <div className="el-dialog__footer">
                        <div data-v-12fb228d="" className="dialog-footer">
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--primary" onClick={()=>onChangeAgentState_del(false)}>
                                <span> 확인 </span>
                            </button>
                            <button data-v-12fb228d="" type="button" className="el-button btn btn-secondary el-button--danger" onClick={onDeleteModal}>
                                <span> 취소 </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </section>
  );
};

export default JoinConsentRequestDashboard;
