import React from "react";
const Pagenation = (props) => {

    const { pageInfo, setPageInfo } = props;

    const onChange = (e) => {
        const { name, value } = e.target;
        if(isNaN(value)){
            return props.setPageInfo({
                ...pageInfo,
                [name]: 1
            })
        }
        setPageInfo({
            ...pageInfo,
            [name]: value
        })
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            props.getList(Number(pageInfo.curPage), pageInfo.perPage);
        }
    }

    const onGetPage = (page) => {
        if( (0 < page) && (page <= pageInfo.totalPage) ){
            props.getList(page, pageInfo.perPage);
        }
    }


    return (
        <div data-v-07abdf0e="" className="vgt-wrap__footer vgt-clearfix row">
            <div data-v-07abdf0e="" className="footer__row-count vgt-pull-left col-sm-9">
                <form data-v-07abdf0e="" className="h-100" style={{ height: "100%" }}>
                    <select data-v-07abdf0e=""
                        id="vgt-select-rpp-139397293116" autoComplete="off"
                        name="perPage" aria-controls="vgt-table"
                        className="footer__row-count__select text-center"
                        value={Number(pageInfo.perPage)}
                        onChange={onChange}>
                        <option data-v-07abdf0e="" value={10}> 10 </option>
                        <option data-v-07abdf0e="" value={50}> 50 </option>
                        <option data-v-07abdf0e="" value={100}>100 </option>
                        <option data-v-07abdf0e="" value={150}> 150 </option>
                        <option data-v-07abdf0e="" value={200}> 200 </option>
                        <option data-v-07abdf0e="" value={500}> 500 </option>
                        <option data-v-07abdf0e="" value={1000}>1,000</option>
                    </select></form>
                <div data-v-07abdf0e="" data-v-347cbcfa=""
                    className="footer__navigation__page-info">
                    <form data-v-07abdf0e="" data-v-347cbcfa="" className="h-100">
                        <label
                        data-v-07abdf0e="" data-v-347cbcfa=""
                        htmlFor="vgt-page-input-556587121160"
                        className="page-info__label">
                            <span data-v-07abdf0e=""
                            data-v-347cbcfa="" style = {{whiteSpace:"nowrap" , margin:"auto 5px auto 5px"}}>페이지</span>
                        
                        <input data-v-07abdf0e=""
                            data-v-347cbcfa="" id="vgt-page-input-556587121160"
                            aria-describedby="change-page-hint"
                            aria-controls="vgb-table" type="text"
                            className="footer__navigation__page-info__current-entry text-center"
                            name="curPage"
                            value={pageInfo.curPage}
                            onChange={onChange}
                            onKeyDown={onEnter} />

                        <span
                            data-v-07abdf0e="" data-v-347cbcfa="" style = {{whiteSpace:"nowrap" , margin:"auto 5px auto 5px"}}>of</span>


                        <input
                            data-v-07abdf0e="" data-v-347cbcfa=""
                            id="vgt-page-input-556587121160"
                            aria-describedby="change-page-hint"
                            aria-controls="vgb-table" type="text"
                            disabled="disabled"
                            className="footer__navigation__page-info__current-entry text-center"
                            value={pageInfo.totalPage} />
                    </label>
                        <span data-v-07abdf0e="" data-v-347cbcfa=""
                            id="change-page-hint" style={{ display: "none" }}> Type a page
                                                            number and press Enter to change the page. </span></form>
                </div>
            </div>
            <div data-v-07abdf0e="" className="footer__navigation vgt-pull-right col-sm-3 d-flex justify-content-end">
                <button data-v-07abdf0e="" type="button" aria-controls="vgt-table"
                    className="footer__navigation__page-btn " onClick={() => onGetPage(Number(pageInfo.curPage) - 1)}>
                    <span
                        data-v-07abdf0e="" aria-hidden="true"
                        className="chevron left"></span>
                    <span data-v-07abdf0e="">이전
                                                    </span>
                </button>


                <button data-v-07abdf0e="" type="button"
                    aria-controls="vgt-table" className="footer__navigation__page-btn" onClick={() => onGetPage(Number(pageInfo.curPage) + 1)}>
                    <span
                        data-v-07abdf0e="">다음</span>
                    <span data-v-07abdf0e=""
                        aria-hidden="true" className="chevron right"></span>
                </button>
            </div>
        </div>
    )
}

export default Pagenation;